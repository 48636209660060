import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, Icons } from 'components';
import { color } from 'themes';
const { RightArrow } = Icons;

export const Wrapper = styled.section`
    width: 100%;
    padding: 0 16px;
    background: ${color.white};
`

export const Container = styled.div``

export const TabList = styled.div``

export const TabListItem = styled.div`
    padding: 16px 0;
`

export const TabListFooter = styled.div`
    padding: 16px 0;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const Description = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin: 0;
`

export const TextButton = styled((props: any) => <LocalizedLink {...props}/>)`
    font-size: 14px;
    line-height: 20px;
    color: ${color.mediumBlue};
    display: inline-flex;
    align-items: center;
`

export const ImageListItem = styled.div`
    margin: 12px 0;
`


export const Arrow = styled((props : any) =>
    <RightArrow width={7} height={14} color={color.mediumBlue} {...props} />
)`
    margin-left: 16px;
`
export const Divider = styled.div`
    height: 0.5px;
    background: ${color.grey};
`
