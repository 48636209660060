import React from "react"
import { Link } from "gatsby"
import { useLanguage } from 'hooks'
import { localizedPath } from 'helpers'

const LocalizedLink = ({ to = '', ...props }: any) => {
    const { language } = useLanguage()
    const locale: string = typeof window !== `undefined`
        ? (window.localStorage.getItem('language') || '')
        : language

    return <Link {...props} to={localizedPath(locale, to)} />
}

export default LocalizedLink
