import React from 'react';
import styled, { css } from 'styled-components';
import { color } from 'themes';
import { LocalizedLink, OutboundLink, Icons } from 'components';
const { DownArrow } = Icons;

export const Wrapper = styled.section`
    background: ${color.mainBlue};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.160784);
`

export const Container = styled.div`
    padding: 40px 16px;
`

export const CategoryWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
`

export const CategoryList = styled.div`
    display: flex;
    flex-direction: column;
`

export const CategoryItem = styled.div<{show?: boolean}>`
    ${props => props.show && css`
        ${CategorySubList}{
            display: flex;
            flex-direction: column;
            margin-bottom: 12px;
        }
        ${CategoryName}{
            ${ArrowIcon}{
                transform: rotate(180deg);
            }
        }
    `}
`

export const CategoryName = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
    color: ${color.white};
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const ArrowIcon = styled((props: any) => <DownArrow width={24} height={24} color={color.white} {...props} />)`
    transition: all .3s;
`

export const CategorySubList = styled.div`
    display: none;
`

export const CategorySub = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: ${color.white};
`

export const SocialNetwork = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

export const SocialNetworkHeadline = styled.h3`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: ${color.white};
    text-transform: uppercase;
`

export const SocialNetworkList = styled.div`
    display: flex;
    justify-content: space-between;
`

export const SocialNetworkItem = styled((props: any) => <OutboundLink target='_blank' {...props} />)`
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 12px;
    &:last-child {
        margin-right: 0;
    }
`

export const Policy = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 0;
    border-top: 0.5px solid ${color.white};
    border-bottom: 0.5px solid ${color.white};
    margin-bottom: 12px;
`

export const PolicyList = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`

export const PolicyItem = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    color: ${color.white};
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border-right: 1px solid ${color.white};
    padding: 0 16px;
    &:first-child {
        padding-right: 16px;
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
`

export const HelpCenter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
`

export const HelpCenterHeadline = styled.h3`
    margin-bottom: 0;
    color: ${color.white};
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    flex: 3;
`

export const HelpCenterButton = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    border: 1px solid ${color.white};
    color: ${color.white};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    padding: 10px;
    text-align: center;
    flex: 2;
    &:hover {
        background: ${color.white};
        color: ${color.mainBlue};
    }
`

export const Copyright = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const CopyrightLogo = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 12px;
`

export const CopyrightContent = styled.div`
    width: 100%;
`

export const CopyrightHeadline = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${color.white};
    margin-bottom: 12px;
    text-align: center;
`

export const CopyrightSubHeadline = styled.div`
    color: ${color.white};
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    p {
        margin: 0;
    }
`

