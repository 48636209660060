import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <path
        d="M17.653 6.816c-.528-.163-.986.3-.986.852V11a1 1 0 01-1 1H15a1 1 0 01-1-1V7.667a1 1 0 00-1-1h-3A3.337 3.337 0 0113.333 10v4.667a.667.667 0 01-.666.667h6.666a.667.667 0 00.667-.667V10a3.338 3.338 0 00-2.347-3.184z"
        fill={props.color || "#0d507a" }
      />
      <path
        d="M16 6c0-.368.298-.666.666-.666a.667.667 0 000-1.334h-1.333a.667.667 0 00-.666.667v6a.666.666 0 101.333 0V6zM12.667 10A3.334 3.334 0 006 10v2.334a1 1 0 001 1h4.667a1 1 0 001-1V10zM12.667 14.667A.667.667 0 0012 14H4.667a.667.667 0 100 1.334H12a.667.667 0 00.667-.667zM13.333 17a1 1 0 011-1h1.334a1 1 0 011 1v2a1 1 0 01-1 1h-1.334a1 1 0 01-1-1v-2z"
        fill={props.color || "#0d507a" }
      />
    </svg>
  )
}

export default SvgComponent
