import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 32} height={props.height || 32} viewBox={`0 0 ${props.width || 32} ${props.height || 32}`} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <rect x="11" y="4" width="2" height="2" rx="0.5" fill={props.color || "#0d507a" }/>
        <rect x="11" y="17" width="2" height="4" rx="0.5" fill={props.color || "#0d507a" }/>
        <path d="M5.85355 7.85355C5.53857 7.53857 5.76165 7 6.20711 7H16.7929C16.9255 7 17.0527 7.05268 17.1464 7.14645L18.6464 8.64645C18.8417 8.84171 18.8417 9.15829 18.6464 9.35355L17.1464 10.8536C17.0527 10.9473 16.9255 11 16.7929 11H6.20711C5.76165 11 5.53857 10.4614 5.85355 10.1464L6.64645 9.35355C6.84171 9.15829 6.84171 8.84171 6.64645 8.64645L5.85355 7.85355Z" fill={props.color || "#0d507a" }/>
        <path d="M18.1464 15.1464C18.4614 15.4614 18.2383 16 17.7929 16L7.20711 16C7.0745 16 6.94732 15.9473 6.85355 15.8536L5.35355 14.3536C5.15829 14.1583 5.15829 13.8417 5.35355 13.6464L6.85355 12.1464C6.94732 12.0527 7.0745 12 7.20711 12L17.7929 12C18.2383 12 18.4614 12.5386 18.1464 12.8536L17.3536 13.6464C17.1583 13.8417 17.1583 14.1583 17.3536 14.3536L18.1464 15.1464Z" fill={props.color || "#0d507a" }/>
    </svg>
  )
}

export default SvgComponent
