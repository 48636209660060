import React from 'react';
import {
    Wrapper,
    Container,
    Icon,
    Headline,
    SubHeadline,
    Button,
    ButtonOutboundLink,
} from './style';
import { Image } from 'components';

const Component = (props: ICard) => {
    switch(props.type){
        case 'normal': {
            return(
                <Wrapper dataType={props.type}>
                    <Container>
                        <Icon>
                            <Image 
                                src={props.data.icon} 
                                alt={props.data.headline}
                                style={{
                                    width: 112,
                                    height: 112,
                                }}
                            />
                        </Icon>
                        <Headline>{props.data.headline}</Headline>
                        <SubHeadline>{props.data.subHeadline}</SubHeadline>
                    </Container>
                </Wrapper>
            )
        }
        case 'button': {
            const { type, data } = props;
            return(
                <Wrapper dataType={type}>
                    <Container>
                        <Headline>{data.headline}</Headline>
                        {
                            data.buttonText && data.buttonExternalLink ? <ButtonOutboundLink to={data.buttonLink}>{data.buttonText}</ButtonOutboundLink>
                            : <Button to={data.buttonLink}>{data.buttonText}</Button>
                        }
                    </Container>
                </Wrapper>
            )
        }
    }
}

export default Component