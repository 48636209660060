import React from 'react'

interface IImage {
    src: string | undefined
    alt: string | undefined
    style?: React.CSSProperties
}

const Image = ({ src, alt, ...rest }: IImage) =>
    <img
        data-src={src}
        alt={alt}
        className='lazyload'
        loading='lazy' // https://addyosmani.com/blog/lazy-loading/
        {...rest}
    />

export default React.memo(Image)
