import React, { useState } from 'react';
import { Markdown } from 'components';
import {
    Wrapper,
    Container,
    Headline,
    SubHeadline,
    Table,
    TableHead,
    TableHeader,
    TableBody,
    TableDropdown,
    DropdownContent,
    TableRow,
    TableData,
    Arrow,
    ColGroup,
    Col
} from './style';

const Component = ({headline, subHeadline, tableHeaders, tableContents}: ITableDropdownList) => {
    const [collapse, setCollapse] = useState(-1);
    const totalWidth = tableHeaders.reduce((total: number, header: any) => total + parseInt(header.width), 0);
    return(
        <Wrapper>
            <Container>
                <Headline>{headline}</Headline>
                <SubHeadline>{subHeadline}</SubHeadline>
                <Table>
                    <ColGroup>
                        {tableHeaders.map(({width}: any, key: number) => (
                            <Col span={1} style={{width: `${width/totalWidth * 100}%`}} key={key}/>
                        ))}
                    </ColGroup>
                    <TableHead>
                        <TableRow>
                            {tableHeaders.map(({name}: any, i: number) => (
                                <TableHeader key={i}>{name}</TableHeader>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableContents.map(({name, contents}: any, i: number) => (
                            <React.Fragment key={i}>
                                <TableRow>
                                    <TableData colSpan={tableHeaders.length}>
                                        <TableDropdown onClick={() => collapse === i ? setCollapse(-1) : setCollapse(i)}>
                                            {name}
                                            <Arrow collapse={collapse === i} />
                                        </TableDropdown>
                                    </TableData>
                                </TableRow>
                                {contents.map(({data}: any, j: number) => (
                                    <TableRow key={j} type="content" collapse={collapse === i}>
                                        {data.map((data: any, k: number) => (
                                            <TableData key={k}>
                                                <DropdownContent>
                                                    <Markdown source={data} />
                                                </DropdownContent>
                                            </TableData>
                                        ))}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </Container>
        </Wrapper>
    )
}

export default Component;