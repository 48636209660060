import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section``

export const Container = styled.div`
    max-width: 1008px;
    margin: 80px auto;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    margin-bottom: 16px;
    color: ${color.mainBlue};
`

export const SubHeadline = styled.p`
    max-width: 592px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const ImageWrapper = styled.div``