import React from 'react';
import { Image, Markdown } from 'components';
import {
    Wrapper,
    Container,
    CategoryWrapper,
    CategoryList,
    CategoryItem,
    CategoryName,
    CategorySubList,
    CategorySub,
    SocialNetwork,
    SocialNetworkHeadline,
    SocialNetworkList,
    SocialNetworkItem,
    Policy,
    PolicyList,
    PolicyItem,
    HelpCenter,
    HelpCenterHeadline,
    HelpCenterButton,
    Copyright,
    CopyrightLogo,
    CopyrightContent,
    CopyrightHeadline,
    CopyrightSubHeadline,
} from './style';

const Desktop = ({ categories, policies, socialNetworksHeadline, socialNetworks, helpCenterHeadline, helpCenterButtonText, helpCenterButtonLink, copyrightLogo, copyrightHeadline, copyrightSubHeadline}: IFooter) => {
    return(
        <Wrapper>
            <Container>
                <CategoryWrapper>
                    <CategoryList>
                        {
                            categories.map(({category, subCategories}: ICategory, index: number) => (
                                <CategoryItem key={index}>
                                <CategoryName>{category}</CategoryName>
                                <CategorySubList>
                                    {
                                        subCategories.map(({subCategoryName, subCategoryLink}: ISubCategory, key: number) => (
                                            <CategorySub to={subCategoryLink} key={key}>{subCategoryName}</CategorySub>
                                        ))
                                    }
                                </CategorySubList>
                                </CategoryItem>
                            ))
                        }
                    </CategoryList>
                    <SocialNetwork>
                        <SocialNetworkHeadline>{socialNetworksHeadline}</SocialNetworkHeadline>
                        <SocialNetworkList>
                            {
                                socialNetworks.map(({icon, name, link}: ISocialNetwork, key: number) => (
                                    <SocialNetworkItem to={link} key={key}>
                                        <Image src={icon} alt={name} style={{width: '100%'}} />
                                    </SocialNetworkItem>
                                ))
                            }
                        </SocialNetworkList>
                    </SocialNetwork>
                </CategoryWrapper>
                <Policy>
                    <PolicyList>
                        {
                            policies.map(({name, link}: IPolicy, key: number) => (
                                <PolicyItem to={link} key={key}>{name}</PolicyItem>
                            ))
                        }
                    </PolicyList>
                    <HelpCenter>
                        <HelpCenterHeadline>{helpCenterHeadline}</HelpCenterHeadline>
                        <HelpCenterButton to={helpCenterButtonLink}>{helpCenterButtonText}</HelpCenterButton>
                    </HelpCenter>
                </Policy>
                <Copyright>
                    <CopyrightLogo to={'/'}>
                        <Image src={copyrightLogo} alt="Edubao" style={{width: '100%'}} />
                    </CopyrightLogo>
                    <CopyrightContent>
                        <CopyrightHeadline>{copyrightHeadline}</CopyrightHeadline>
                        <CopyrightSubHeadline>
                            <Markdown source={copyrightSubHeadline} />
                        </CopyrightSubHeadline>
                    </CopyrightContent>
                </Copyright>
            </Container>
        </Wrapper>
    )
}

export default Desktop;