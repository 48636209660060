import React from 'react'

import {
    Wrapper,
    Title,
    Partners,
    Partner,
    LogoWrapper,
    SubHeadline
} from './style'
import { Image } from 'components'

const Desktop = ({ headline = '', subHeadline = '', partners = [] }: IPartners) => {
    return (
        <Wrapper>
            <Title>{headline}</Title>
            <SubHeadline>{subHeadline}</SubHeadline>
            <Partners>
                {partners.map(({ logo, label, link }: IPartner) => (
                    <Partner key={`${logo}${label}${link}`}>
                        <LogoWrapper target='_blank' href={link}>
                            <Image
                                style={{ width: '100%' }}
                                src={logo}
                                alt={label}
                            />
                        </LogoWrapper>
                    </Partner>
                ))}
            </Partners>
        </Wrapper>
    )
}

export default Desktop
