import React from 'react'
import {
  Wrapper,
  ContentWrapper,
  Content,
  Headline,
  SubHeadline,
  Button,
  ButtonOutboundLink,
  MediaWrapper,
  Container
} from './style'
import { Image } from 'components'

const Desktop = (props: IBanner) => {
  const {headline, subHeadline, image, buttonText, buttonLink, buttonExternalLink} = props;
  return (
      <Wrapper>
        <Container>
            <ContentWrapper>
              <Content>
                  <Headline>{headline}</Headline>
                  <SubHeadline>{subHeadline}</SubHeadline>
                  {
                    buttonText && buttonExternalLink ? <ButtonOutboundLink to={buttonLink} target='_blank'>{buttonText}</ButtonOutboundLink>
                    : <Button to={buttonLink}>{buttonText}</Button>
                  }
              </Content>
            </ContentWrapper>
            <MediaWrapper>
            <Image alt={headline}
                src={image}
                style={{
                    width: '100%',
                    height: 300
                }}
            />
            </MediaWrapper>
        </Container>
      </Wrapper>
  )
}
export default Desktop
