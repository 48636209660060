import React, { Fragment, useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import {
    Markdown,
    Icons
} from 'components'
import {
    Container,
    Section,
    Headline,
    Title,
    Divider,
    Content,
    ImageContainer,
} from './style'

const {
    DownArrow,
} = Icons

const Desktop = (props: IFaqsGroup) => {
    const { headline = '', items = [] } = props
    const [openSections, setOpenSections] = useState<any>({})

    useEffect(() => {
        const initSections = {}
        items.forEach(({
            question,
        }: IFaqItem) => {
            initSections[question] = false
        })

        if (!isEmpty(initSections)) {
            setOpenSections(initSections)
        }
    }, [items])

    const getIconDeg = (value: string) => {
        return openSections[value] === true ? 180 : 0
    }

    const renderCollapseIcon = (str: string) => {
        return (
            <ImageContainer>
                <DownArrow
                    style={{
                        transform: `rotate(${getIconDeg(str)}deg)`,
                        transition: "all .3s"
                    }}
                />
            </ImageContainer>
        )
    }

    const renderQuestion = (question: string, answer: string) => {
        if (!openSections[question]) {
            return null
        }

        return (
            <>
                <Content>
                    <Divider />
                    <Markdown source={answer} />
                </Content>
            </>
        )
    }

    const onClick = (label: string): any => {
        const isOpen = !!openSections[label]
        setOpenSections({
            [label]: !isOpen
        })
    }

    return (
        <Container>
            {
                headline && (
                    <Headline>{headline}</Headline>
                )
            }
            {
                items.map(({ question, answer }: IFaqItem) => {
                    return (
                        <Fragment key={question}>
                            <Section
                                key={question}
                            >
                                <Title onClick={() => onClick(question)}>
                                    <span>{question}</span>
                                    {renderCollapseIcon(question)}
                                </Title>
                                {renderQuestion(question, answer)}
                            </Section>
                        </Fragment>
                    )
                })
            }
        </Container>
    )
}

export default Desktop
