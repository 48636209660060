import React from 'react';
import styled, { css } from 'styled-components';
import { color } from 'themes';
import { OutboundLink } from 'components';

export const Wrapper = styled.section``

export const Container = styled.div`
    padding: 48px 16px;
    display: flex;
    flex-direction: column;
`

export const FormSection = styled.div`
    background: #F9FAFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 32px;
`

export const FormHeadline = styled.h3`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${color.mainBlue};
    margin-bottom: 24px;
`

export const FormGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 -8px;
`

export const FormGroupItem = styled.div<{required?: boolean}>`
    flex: 1;
    padding: 8px;
    ${props => props.required && css`
        ${FormGroupItemLabel}{
            &::after {
                content: '*';
                color: #ff0000;
                margin-left: 6px;
            }
        }
    `}
`

export const FormFooter = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`

export const SubmitButton = styled.button`
    background: ${color.mainBlue};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 16px;
    color: ${color.white};
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 8px 24px;
    outline: none;
    border: none;
    cursor: pointer;
`


export const Tooltip = styled.div`
    color: ${color.mainBlue};
`

export const FormGroupItemLabel = styled.label`
    display: block;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${color.fontBlue};
    margin-bottom: 8px;
    margin-left: 8px;
`

const InputStyles = `
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 16px;
    outline: none;
    border: none;
    padding: 12px 16px;
    &::placeholder {
        font-size: 16px;
        line-height: 20px;
        color: #1E3056;
        mix-blend-mode: normal;
        opacity: 0.5;
    }
`

export const Input = styled.input`
    ${InputStyles}
`

export const Textarea = styled.textarea`
    ${InputStyles}
`

export const ContactSection = styled.div`
`

export const ContactInfo = styled.div`
    background: #F9FAFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 16px;
    padding: 16px;
    margin-bottom: 32px;
`

export const NeedHelpHeadline = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin-bottom: 12px;
`

export const CompanyLogo = styled.div`
    width: 56px;
    height: 56px;
    background: #C4C4C4;
    margin-bottom: 12px;
`

export const CompanyName = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.mainBlue};
    margin-bottom: 12px;
`

export const CompanySubHeadline = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin-bottom: 8px;
`

export const ContactInfoList = styled.ul`
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
`

export const ContactInfoListItem = styled.li`
    display: flex;
`

export const Icon = styled.div`
    margin-right: 8px;
`

export const ContactInfoContent = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const ChatWithUs = styled((props: any) => <OutboundLink target={'_blank'} {...props} />)`
    display: block;
    background: ${color.mainBlue};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0784314);
    border-radius: 8px;
    color: ${color.white};
    padding: 8px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 32px;
    cursor: pointer;
`

export const SocialMediaNetworksHeadline = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin-bottom: 8px;
`

export const SocialMediaNetworksList = styled.div`
    display: flex;
`

export const SocialMediaNetworkItem = styled.a`
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin-right: 16px;
`

export const EmergencyContact = styled.div`
    background: #F9FAFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 16px;
    padding: 16px;
`

export const EmergencyContactHeadline = styled.h3`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.mainBlue};
    margin-bottom: 20px;
`

export const EmergencyContactSubHeadline = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin-bottom: 12px;
`

export const EmergencyContactList = styled.div`
    display: flex;
    flex-direction: column;
`

export const EmergencyContactListItem = styled.div``

export const EmergencyContactInfo = styled.div`
    display: flex;
`

export const EmergencyContactInfoIcon = styled.div`
    margin-right: 8px;
`

export const EmergencyContactInfoContext = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const ErrorMessage = styled.span`
    color: #ff1500;
    margin-top: 12px;
    margin-left: 12px;
    display: block;
`

