import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 32 32`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <g stroke={props.color || "#10527a"}>
          <g
            transform="translate(4 6)"
            fill="none"
            strokeLinejoin="round"
            strokeWidth={3}
          >
            <rect width={24} height={22} rx={3} stroke="none" />
            <rect x={1.5} y={1.5} width={21} height={19} rx={1.5} />
          </g>
          <g fill={props.color || "#10527a"} strokeWidth={0.5}>
            <g transform="translate(9.367 15.5)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(9.367 18.566)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(9.367 21.633)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(12.434 12.433)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(12.434 15.5)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(12.434 18.566)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(12.434 21.633)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(15.5 12.433)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(15.5 15.5)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(15.5 18.566)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(15.5 21.633)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(18.567 12.433)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(18.567 15.5)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(18.567 18.566)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(18.567 21.633)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(21.634 12.433)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(21.634 15.5)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
            <g transform="translate(21.634 18.566)">
              <rect width={1} height={1} rx={0.5} stroke="none" />
              <rect
                x={-0.25}
                y={-0.25}
                width={1.5}
                height={1.5}
                rx={0.75}
                fill="none"
              />
            </g>
          </g>
          <g transform="translate(19.714 4)" fill="#0d507a">
            <rect width={2} height={4} rx={1} stroke="none" />
            <rect x={-0.5} y={-0.5} width={3} height={5} rx={1.5} fill="none" />
          </g>
          <g transform="translate(10.286 4)" fill="#0d507a">
            <rect width={2} height={4} rx={1} stroke="none" />
            <rect x={-0.5} y={-0.5} width={3} height={5} rx={1.5} fill="none" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
