import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section`
    width: 100%;
`

export const Container = styled.div`
    padding: 40px 16px;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 16px;
    color: ${color.mainBlue};
`

export const SubHeadline = styled.p`
    text-align: center;
    margin-bottom: 32px;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${color.fontBlue};
`

export const ImageWrapper = styled.div``