import styled from 'styled-components'

export const MarkdownImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
`

export const Text = styled.span`
    padding-top: 8px;
`

export const TextUnderline = styled.span`
    padding-top: 8px;
    text-decoration: underline;

    font-size: 14px;
`
