import React, {
    createContext,
    useContext,
} from 'react'

const LocationContext = createContext({
    basePath: '',
})

export const LocationProvider = ({ basePath, children }: any) => {
    return (
        <LocationContext.Provider
            value={{
                basePath,
            }}
        >
            {children}
        </LocationContext.Provider>
    )
}

export const useLocation = () => useContext(LocationContext)
