import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 32 32`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g transform="translate(4 4)" fill="#0d507a" stroke="#0d507a">
          <rect width={6} height={24} rx={1.5} />
          <rect width={6} height={18} rx={1.5} transform="translate(9 6)" />
          <rect width={6} height={12} rx={1.5} transform="translate(18 12)" />
        </g>
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  )
}

export default SvgComponent
