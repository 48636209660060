import React from 'react';
import styled, { css } from 'styled-components';
import { color } from 'themes';
import { Icons } from 'components';

const { DownArrow } = Icons;

export const Wrapper = styled.section`
`

export const Container = styled.div`
    max-width: 864px;
    width: 100%;
    margin: 64px auto;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: ${color.mainBlue};
    margin-bottom: 16px;
`

export const SubHeadline = styled.p`
    max-width: 624px;
    width: 100%;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${color.fontBlue};
    margin-bottom: 32px;
`

export const Table = styled.table`
    width: 100%;
    border-radius: 16px;
`

export const TableRow = styled.tr<{type?: string; collapse?: boolean}>`
    ${props => props.type === 'content' && css`
        ${TableData}{
            background: #F6F6F6;
            border-left: none;
            &:first-child {
                border-right: 1px dashed ${color.grey};
                border-left: 1px solid ${color.grey};
            }
        }
    `}
    ${props => (props.type === 'content' && !props.collapse) && css`
        display: none;
    `}
`

export const TableHead = styled.thead``

export const TableHeader = styled.th`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.white};
    padding: 12px;
    background: ${color.mainBlue};
    text-align: center;
    border-right: 0.25px solid ${color.white};
    &:first-child {
        border-top-left-radius: 8px;
        border-right: 0.25px dashed ${color.white};
    }
    &:last-child {
        border-right: none;
        border-top-right-radius: 8px;
    }
`

export const TableBody = styled.tbody`
`

export const TableDropdown = styled.div`
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: ${color.mainBlue};
        background: ${color.white};
        padding: 12px;
        /* border: 0.25px solid ${color.grey};
        border-top: none; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
`

export const TableData = styled.td`
    border: 0.25px solid ${color.grey};
    border-top: none;
`

export const DropdownContent = styled.div`
    padding: 12px;
    font-size: 14px;
    line-height: 20px;
`

export const Arrow = styled((props: any) => <DownArrow {...props} />)`
    transition: all .3s;
    ${props => props.collapse && css`
        transform: rotate(180deg)
    `}
`

export const ColGroup = styled.colgroup``

export const Col = styled.col``