import React from 'react'
import {
    MarkdownImageContainer,
    Text,
} from './style'
import {
    Image,
} from 'components'

const MarkdownImage = ({ path = '', name = '' }: any) => {
    // const imageUrl: string = `${process.env.STATIC_URI}${path}`
    const imageUrl = path
        ? path.substr(0, 4) === 'http'
            ? path
            : `${process.env.STATIC_URI}${path}`
        : ''
    const alt: string = name

    return (
        <MarkdownImageContainer>
            <Image
                src={imageUrl}
                alt={alt}
            />
            <Text>
                {alt}
            </Text>
        </MarkdownImageContainer>
    )
}

export default MarkdownImage
