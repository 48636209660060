import * as React from "react"
import styled from 'styled-components'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {

    const Svg = styled.svg<any>`
        &:hover {
            .cls-1 {
                fill: #1973b1;
            }
        }
    `
    return (
        <Svg width={props.width || "24px" } height={props.height || "24px" } viewBox="0 0 32 32" fill="none" {...props}>
        <path
            className="cls-1"
            d="M28 0H4a4 4 0 00-4 4v24a4 4 0 004 4h24a4 4 0 004-4V4a4 4 0 00-4-4z"
            fill={props.color || "#B1B1B1"}
        />
        <path
            d="M10.4 7.816c0 1.177-.864 2.133-2.299 2.133-1.342 0-2.206-.956-2.206-2.133 0-1.195.9-2.115 2.28-2.115 1.36 0 2.207.92 2.225 2.115zM6.005 25.324V11.623h4.248v13.683H6.005v.018zM12.81 16c0-1.71-.056-3.126-.11-4.358h3.696l.202 1.894h.074c.551-.901 1.93-2.207 4.23-2.207 2.795 0 4.892 1.876 4.892 5.903v8.092h-4.249V17.73c0-1.766-.607-2.961-2.152-2.961-1.177 0-1.875.81-2.188 1.6-.11.276-.147.68-.147 1.067v7.89H12.81V16z"
            fill="#fff"
        />
        </Svg>
    )
}

export default SvgComponent
