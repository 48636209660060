export const LANGUAGE_MENU = {
    label: 'LANGUAGES',
    children: [
        { value: 'en', label: 'English' },
        // { value: 'de', label: 'Deutsch' },
        { value: 'vi', label: 'Tiếng Việt' },
        // { value: 'fr', label: 'Français' },
    ]
}

// http://www.lingoes.net/en/translator/langcode.htm
export const LANGUAGES = [
    { abbreviation: 'EN', value: 'en', label: 'English' },
    // { abbreviation: 'DE', value: 'de', label: 'Deutsch' },
    { abbreviation: 'VI', value: 'vi', label: 'Tiếng Việt' },
    // { abbreviation: 'FR', value: 'fr', label: 'Français' },
]

export const hashMapLanguage = {
    'en': 'EN',
    // 'de': 'DE',
    'vi': 'VI',
    // 'fr': 'FR',
}