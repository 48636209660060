import React from 'react'
import { get, isEmpty } from 'lodash'
import { HeaderBox, BreadCrumb } from 'components'
import {
    Container,
    Wrapper,
    HeaderContainer,
    MarginContainer,
    RadientContainer,
    BreadCrumbContainer
} from './style'

const Desktop = (props: IHeroSection) => (
    <Container
        style={{
            backgroundImage: `url(${get(props, 'backgroundImage', '')})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }}
    >
        {
            !isEmpty(props.breadcrumbs) && (
                <RadientContainer>
                    <BreadCrumbContainer>
                        <BreadCrumb breadcrumbs={props.breadcrumbs} />
                    </BreadCrumbContainer>
                </RadientContainer>
            )
        }
        <Wrapper>
            <MarginContainer>
                <HeaderContainer>
                    <HeaderBox
                        headline={props.headline}
                        description={props.subHeadline}
                    />
                </HeaderContainer>
            </MarginContainer>
        </Wrapper>
    </Container>
)

export default Desktop
