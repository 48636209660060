import React from 'react'
import {LocalizedLink} from 'components'
import styled from 'styled-components'
import {color} from 'themes'

export const Wrapper = styled.div`
    width: 100%;
`
export const Container = styled.div`
    max-width: 696px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
`
export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    color: ${color.mainBlue};
`
export const Description = styled.p`
    color: ${color.fontBlue};
    font-size: 16px;
    line-height: 26px;
`

export const ListWrapper = styled.div`
    margin-top: 40px;
`

export const Company = styled.div`
    display: flex;
    padding: 80px 0;
    border-bottom: 1px solid ${color.lightGrey};
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
`
export const Logo = styled.div`
    flex-basis: 176px;
    margin-right: 32px;
`
export const LogoWrapper = styled.div`
    width: 100%;
    min-height: 120px;
    border-radius: 16px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0784314);
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CompanyDescription = styled.div`
    font-size: 16px;
    line-height: 26px;
    color: ${color.fontBlue};
`

export const CompanyName = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    color: ${color.mainBlue};
    margin-bottom: 16px;
`

export const ExternalLink = styled.a`
`

export const InternalLink = styled((props : any) => <LocalizedLink {...props}/>)`
`
