import {
    Container,
    Content,
    Wrapper
} from './style';

import { Markdown } from 'components';
import React from 'react';

const Component = ({ content }: IMarkdownSection) => {
    let _content = content
    if (_content.includes('Lorenz Heinrich Schneidmadel')) {
        _content = _content.replace(`Lorenz Heinrich Schneidmadel\n\n`, '')
    }

    return (
        <Wrapper>
            <Container>
                <Content>
                    <Markdown source={_content} />
                </Content>
            </Container>
        </Wrapper>
    )
}

export default Component