import * as React from "react"

function Search(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path
          d="M15.6 15.6a6.5 6.5 0 111.9-4.6 6.48 6.48 0 01-1.9 4.6l4.9 4.9z"
          fill="none"
          stroke="#0d507a"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </g>
    </svg>
  )
}

export default Search
