import React from 'react'
import { LocalizedLink, Icons, OutboundLink } from 'components'
import styled, { css } from 'styled-components'
import { color } from 'themes'

const { RightArrow } = Icons

export const Container = styled.section<{type: string}>`
    ${props => props.type === "normal" && css`
        background-color: ${color.iceBlue};
        padding: 24px;
        ${USPItemWrapper}{
            flex-direction: row;
            flex: 1;
        }
    `}
    ${props => props.type === "with-link" && css`
        background-color: ${color.white};
        padding: 36px 64px;
        ${Wrapper}{
            ${USPItemWrapper}{
                flex-direction: column;
                align-items: center;
                justify-content: center;
                ${USPItemRightContent}{
                    align-items: center;
                    ${USPItemHeadline}{
                        color: ${color.fontBlue};
                        margin-top: 12px;
                        text-align: center;
                    }
                    ${USPItemSubHeadline}{
                        max-width: 320px;
                        margin-bottom: 12px;
                        text-align: center;
                    }
                }
            }
        }
    `}
`

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 1280px;
    margin: 0 auto;
`

export const USPItemRightContent = styled.div`
    display: flex;
    flex-direction: column;
`

export const USPItemWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-child {
        margin-right: 0;
    }
`

export const USPItemIcon = styled.div`
    padding-right: 16px;
    > img {
        width: 120px;
        height: 120px;
    }
`

export const USPItemHeadline = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${color.mainBlue};
`

export const USPItemSubHeadline = styled.div`
    margin-top: 8px;
    font-size: 16px;
    line-height: 20px;
    color: ${color.fontBlue};
`

const LinkStyles = `
    color: ${color.mediumBlue};
    font-size: 16px;
    line-height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export const Link = styled((props: any) => <LocalizedLink {...props} />)`
    ${LinkStyles}
`

export const ExternalLink = styled((props: any) => <OutboundLink {...props} />)`
    ${LinkStyles}
`

export const LinkArrow = styled((props: any) => <RightArrow color={color.mediumBlue} width={8} height={16} {...props} />)`
    margin-left: 16px;
`

