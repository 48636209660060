import { isEmpty } from 'lodash';

export const getInvisibleComponents = (data: IInvisibleComponent[]) => {
    if (!data || isEmpty(data)) return [];
    return (data.map((component: IInvisibleComponent) => {
        switch(component.__typename){
            case "MetaTags": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: flatData
                }
            }
        }
    }))
}

export const getComponents = (data: IComponent[]) => {
    if (!data || isEmpty(data)) return [];
    return (data.map((component: IComponent) => {
        switch (component.__typename) {
            case "Heros": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: flatData
                }
            }
            case "UspGroups": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        location: flatData.location,
                        type: flatData.type,
                        uSPsList: flatData.uSPsList.map((usp: IUspItem) => ({
                            type: usp.__typename,
                            ...usp.flatData
                        }))
                    }
                }
            }
            case "TariffAndBenefits": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        location: flatData.location,
                        headline: flatData.headline,
                        subHeadline: flatData.subHeadline,
                        buttonText: flatData.buttonText,
                        buttonLink: flatData.buttonLink,
                        buttonLinkExternal: flatData.buttonLinkExternal,
                        textListHeadline: flatData.textListHeadline,
                        textList: flatData.textList,
                    }
                }
            }
            case "TextWithPictureGroups": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        location: flatData.location,
                        textWithPictureArray: flatData.textWithPictureArray.map(({ textWithPicture, reverse }: ITextWithPictureArrayItemCMS) => ({
                            headline: textWithPicture[0].flatData.headline,
                            subHeadline: textWithPicture[0].flatData.subHeadline,
                            buttonText: textWithPicture[0].flatData.buttonText,
                            buttonLink: textWithPicture[0].flatData.buttonLink,
                            buttonLinkExternal: textWithPicture[0].flatData.buttonLinkExternal,
                            picture: textWithPicture[0].flatData.picture,
                            reverse
                        }))
                    }
                }
            }
            case "FaqsGroup": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        headline: flatData.headline,
                        fAQs: flatData.fAQs.map(({ flatData }) => ({
                            ...flatData
                        }))
                    }
                }
            }
            case "PartnersAndReferences": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        headline: flatData.headline,
                        subHeadline: flatData.subHeadline,
                        partners: flatData.partners.map(({ flatData }) => ({
                            label: flatData.identifier,
                            logo: flatData.logo,
                            link: flatData.link,
                        }))
                    }
                }
            }
            case "Banner": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: flatData
                }
            }
            case "ProductCardListWithText": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        productCardList: flatData.productCardList.map(({ flatData }) => ({
                            ...flatData
                        }))
                    }
                }
            }
            case "PackageTeaserCardList": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        headline: flatData.headline,
                        cards: flatData.packageTeaserCardList.map(({ flatData }) => flatData)
                    }
                }
            }
            case "ImageSwitchingTabs": {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        imageSwitchingTabItems: flatData.imageSwitchingTabItems.map(({ flatData }) => flatData)
                    }
                }
            }
            case "CardItemList": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        headline: flatData.headline,
                        cards: flatData.cards.map((card: ICardCMS) => {
                            switch (card.flatData.type) {
                                case "normal": {
                                    const { flatData } = card;
                                    return ({
                                        type: flatData.type,
                                        data: {
                                            headline: flatData.headline,
                                            subHeadline: flatData.subHeadline,
                                            icon: flatData.icon,
                                        }
                                    })
                                }
                                case "button": {
                                    const { flatData } = card;
                                    return ({
                                        type: flatData.type,
                                        data: {
                                            headline: flatData.headline,
                                            buttonText: flatData.buttonText,
                                            buttonLink: flatData.buttonLink,
                                            buttonExternalLink: flatData.buttonExternalLink,
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            }
            case "BannerLarge": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: flatData,
                }
            }
            case "MdContent": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: flatData,
                }
            }
            case "DownloadAndDocuments": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: flatData,
                }
            }
            case "PartnersList": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        partners: flatData.partners.map(({ flatData }) => ({
                            ...flatData
                        }))
                    },
                }
            }
            case "TableDropdownList": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        tableContents: flatData.tableContents.map(({ flatData }) => ({
                            ...flatData,
                            contents: flatData.contents.map(({ flatData }) => ({
                                data: flatData.dataArray.map(({ data }) => data)
                            }))
                        }))
                    },
                }
            }
            case "Table": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        tooltips: flatData.tooltips.map(({ text }) => text),
                        tableContents: flatData.tableContents.map(({ flatData }) => ({
                            ...flatData,
                            rows: flatData.rows
                                && flatData.rows.length
                                ? flatData.rows.map(({ flatData }) => flatData)
                                : []
                        })),
                    }
                }
            }
            case "ContactForm": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        formGroup: flatData.formGroup.map(({formItems}) => ({formItems: formItems.map(({flatData}) => flatData)})),
                    }
                }
            }
            case "MenuPrimary": {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: {
                        ...flatData,
                        leftMenuList: flatData.leftMenuList.map(({flatData}) => ({
                            ...flatData,
                            children: flatData.children.length ? flatData.children[0].childrenItem.map(({flatData}) => flatData) : [],
                        })),
                        rightMenuList: flatData.rightMenuList.map(({flatData}) => ({
                            ...flatData,
                            children: flatData.children.length ? flatData.children[0].childrenItem.map(({flatData}) => flatData) : [],
                        })),
                        menuSecondary: flatData.menuSecondary.map(({flatData}) => flatData)
                    }
                }
            }
            case 'Jumbotron': {
                const { __typename, flatData } = component;
                return {
                    type: __typename,
                    data: flatData,
                };
            }
            default: {
                const { __typename, flatData } = component
                return {
                    type: __typename,
                    data: flatData
                }
            }
        }
    }))
}