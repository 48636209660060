import { navigate } from 'gatsby'
import { get } from 'lodash'
import { defaultLang, getLocaleAndBasePath } from './i18n'

export const InboundNavigate = (path: string) => {
    const { locale } = getLocaleAndBasePath(get(window, 'location.pathname', ''))
    if (locale === defaultLang || !locale) {
        return navigate(path)
    }

    return navigate(`/${locale}${path}`)
}
