import React from 'react';
import {
    Wrapper,
    Container,
    Headline,
    SubHeadline,
    ImageWrapper
} from './style';
import { Image } from 'components';

const Component = ({headline, subHeadline, backgroundImage}: IBannerLarge) => {
    return(
        <Wrapper>
            <Container>
                <Headline>{headline}</Headline>
                <SubHeadline>{subHeadline}</SubHeadline>
                <ImageWrapper>
                    <Image 
                        src={backgroundImage} 
                        alt={headline} 
                        style={{
                            width: '100%'
                        }}
                    />
                </ImageWrapper>
            </Container>
        </Wrapper>
    )
}

export default Component