import styled from 'styled-components'
import {color} from 'themes'

export const Wrapper = styled.section`
  padding: 80px 0;
  max-width: 1280px;
  margin: auto;
  background-color: ${color.background};
`

export const Title = styled.h3`
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  color: ${color.mainBlue};
  text-align: center;
  margin-bottom: 16px;
`

export const SubHeadline = styled.p`
  margin-bottom: 48px;
  color: ${color.fontBlue};
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
`

export const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
`

export const Partner = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LogoWrapper = styled.a`
  width: 176px;
  min-height: 120px;
  padding: 20px;
  background-color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 16px;
  border-radius: 16px;

`

export const Label = styled.label`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: ${color.mainBlue};
  margin-top: 40px;
`
