import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.833 9h2.5L9 12.333 5.667 9h2.5V5.667h1.666V9zm7.5 0c0 4.6-3.733 8.333-8.333 8.333A8.336 8.336 0 01.667 9C.667 4.4 4.4.667 9 .667S17.333 4.4 17.333 9zm-15 0A6.665 6.665 0 009 15.667 6.665 6.665 0 0015.667 9 6.665 6.665 0 009 2.333 6.665 6.665 0 002.333 9z"
        fill="#0976BB"
      />
    </svg>
  )
}

export default SvgComponent
