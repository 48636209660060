import React, { useState } from 'react'
import { OutboundLink, LocalizedLink } from 'components'
import {
    Wrapper,
    Bar,
    Logo,
    Item,
    MenuItem,
    SubMenu,
    SubItem,
    StyledLocalizedLink,
    ButtonItem,
    LeftMenu,
    TopNav,
    TopNavContainer,
    TopNavItem,
    RightMenu,
    Menu,
    Arrow,
    MenuPopover,
    StyledLink,
    Underline,
    LanguageIcon,
} from './style'
import {
    LANGUAGES,
    hashMapLanguage,
} from '../const'
import { get } from 'lodash'
import { useLocation } from '@reach/router'
import { useLanguage } from 'hooks'

import { isDefaultLang, getLocaleAndBasePath } from 'helpers'

const LanguageChildren = LANGUAGES.map(({ label, value, abbreviation }) => ({
    label,
    value,
    abbreviation
}))

const Desktop = ({logo, leftMenuList, rightMenuList, menuSecondaryList}: IMenu) => {
    const {
        language,
        switchLang,
    } = useLanguage();

    const { pathname } = useLocation();

    const isActiveLocation = (location: string) => {
        const regex = new RegExp(location.replace(/\//g, ''), 'g');
        const result = pathname.match(regex);
        if(result !== null) return true;
        return false;
    }

    const [collapse, setCollapse] = useState('');
    const [visible, setVisible] = useState<boolean>(false);

    const handleVisibleChange = (isShown: boolean) => {
        setVisible(isShown)
    }

    const LanguageMenuComponent = () => {
        if (typeof window === `undefined`) {
            return null
        }

        const { basePath } = getLocaleAndBasePath(window.location.pathname)
        const subMenuContent = (
            <SubMenu>
                {
                LanguageChildren
                    .filter((lang : any) => lang.value !== language)
                    .map(({ value, label }) => {
                    const langValue = isDefaultLang(value) ? '' : value
                    const link = langValue ? `/${value}${basePath}` : basePath
                    return (
                        <SubItem key={value}>
                            <StyledLink
                                to={link}
                                // tslint:disable-next-line: jsx-no-lambda
                                onClick={() => switchLang(value)}
                            >
                                {label}
                            </StyledLink>
                        </SubItem>
                    )
                })}
            </SubMenu>
        )

        return (
            <MenuPopover
                overlayClassName='sub-menu'
                placement='bottom'
                content={subMenuContent}
                arrowPointAtCenter={true}
                onVisibleChange={handleVisibleChange}
            >
                <MenuItem>
                    <LanguageIcon />
                    {
                        visible ? (
                            <Underline>
                                {get(hashMapLanguage, `${language}`)}
                            </Underline>
                        ) : get(hashMapLanguage, `${language}`)
                    }
                    <Arrow collapse={visible} />
                </MenuItem>
            </MenuPopover>
        )
    }

    const renderMenuItem = (menu: IMenuItem) => {
        switch(menu.type){
            case "Text": {
                const { data } = menu
                return <Item active={isActiveLocation(data.url)} to={data.url}>{data.text}</Item>
            }
            case "Button": {
                const { data } = menu
                if(data.externalLink){
                    return(
                        <OutboundLink to={data.url} target='_blank'>
                            <ButtonItem type="primary">{data.text}</ButtonItem>
                        </OutboundLink>
                    )
                }
                return (
                    <LocalizedLink to={data.url}>
                        <ButtonItem type="primary">{data.text}</ButtonItem>
                    </LocalizedLink>
                )
            }
            case "Dropdown": {
                const { data } = menu
                const subMenuContent = (
                    <SubMenu>
                        {data.children.map((childrenItem: any) => {
                            switch(childrenItem.type){
                                case "Text": {
                                    const { data } = childrenItem
                                    return(
                                        <SubItem key={data.url}>
                                            <StyledLocalizedLink to={data.url}>
                                                {data.text}
                                            </StyledLocalizedLink>
                                        </SubItem>
                                    )
                                }
                                case "Button": {
                                    const { data } = childrenItem
                                    if(data.externalLink){
                                        return(
                                            <SubItem key={data.url}>
                                                <OutboundLink to={data.url} target='_blank'>
                                                    <ButtonItem type="primary">{data.text}</ButtonItem>
                                                </OutboundLink>
                                            </SubItem>
                                        )
                                    }
                                    return (
                                        <SubItem key={data.url}>
                                            <LocalizedLink to={data.url}>
                                                <ButtonItem type="primary">{data.text}</ButtonItem>
                                            </LocalizedLink>
                                        </SubItem>
                                    )
                                }
                                default: return null;
                            }
                        })}
                    </SubMenu>
                )

                return (
                    <MenuPopover
                        key={data.text}
                        overlayClassName='sub-menu'
                        placement='bottom'
                        content={subMenuContent}
                        onVisibleChange={(visible: boolean) => visible ? setCollapse(data.text) : setCollapse('')}
                    >
                        <MenuItem>{data.text} <Arrow collapse={collapse === data.text}/></MenuItem>
                    </MenuPopover>
                )
            }
        }
    }

    return (
        <Wrapper>
            <TopNav>
                <TopNavContainer>
                    {
                        menuSecondaryList.map((data, key: number) => {
                            if(data.externalLink){
                                return(
                                    <OutboundLink key={key} to={data.link} target='_blank'>
                                        <TopNavItem>{data.text}</TopNavItem>
                                    </OutboundLink>
                                )
                            }
                            return (
                                <LocalizedLink key={key} to={data.link}>
                                    <TopNavItem>{data.text}</TopNavItem>
                                </LocalizedLink>
                            )
                        })
                    }
                </TopNavContainer>
            </TopNav>
            <Bar>
                <Logo src={logo} to='/' />
                <Menu>
                <LeftMenu>
                    {leftMenuList.map((menu: IMenuItem) => renderMenuItem(menu))}
                </LeftMenu>
                <RightMenu>
                    {rightMenuList.map((menu: IMenuItem) => renderMenuItem(menu))}
                    {LanguageMenuComponent()}
                </RightMenu>
                </Menu>
            </Bar>
        </Wrapper>
    )
}

export default Desktop
