import React, { useState, useRef } from 'react'
import { LocalizedLink, OutboundLink } from 'components'
import { useLanguage } from 'hooks'
import { isDefaultLang, getLocaleAndBasePath } from 'helpers'
import {
    Wrapper,
    Bar,
    LogoWrapper,
    Logo,
    Menu,
    Groups,
    Group,
    GroupTitle,
    Item,
    StyledLink,
    TopNavContainer,
    TopNavItem,
    TopNav,
    MenuIcon,
    MenuIconFirstBar,
    MenuIconSecondBar,
    MenuIconThirdBar,
    Arrow,
    LanguageIcon
} from './style'
import {
    LANGUAGES,
    hashMapLanguage,
} from '../const'
import { useOnClickOutside } from 'hooks'
import { get } from 'lodash'

const LanguageChildren = LANGUAGES.map(({ label, value, abbreviation }) => ({
    label,
    value,
    abbreviation
}))

const Mobile = ({ logo, leftMenuList, rightMenuList, menuSecondaryList }: IMenu) => {
    const {
        language,
        switchLang,
    } = useLanguage()
    const MENU_LIST = [...leftMenuList, ...rightMenuList]
    const [menuVisible, setMenuVisible] = useState<boolean>(false)
    const [menuActive, setMenuActive] = useState(-1)
    const barRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    useOnClickOutside(barRef, () => {
        setMenuVisible(false)
    })

    const hideMenu = () => {
        setMenuVisible(false)
    }

    const showHideMenu = () => {
        setMenuVisible(!menuVisible)
    }

    const onLanguageClicked = (selectedLanguage: string) => {
        if (selectedLanguage !== language) {
            switchLang(selectedLanguage)
        }

        hideMenu()
    }

    const renderLanguages = () => {
        const { basePath } = getLocaleAndBasePath(window.location.pathname)
        return (
            <Group>
                <GroupTitle onClick={() => setMenuActive(menuActive === -2 ? -1 : -2)}>
                    <LanguageIcon /> {get(hashMapLanguage, `${language}`)}
                    <Arrow dataCollapse={menuActive === -2} />
                </GroupTitle>
                {menuActive === -2 && 
                    LanguageChildren
                    .filter((lang : any) => lang.value !== language)
                    .map(({ label: languageLabel, value }) => {
                    const langValue = isDefaultLang(value) ? '' : value
                    const link = langValue ? `/${value}${basePath}` : basePath
                    return (
                        <StyledLink
                            key={value}
                            to={link + (window.location.search || '')}
                            onClick={() => {
                                // tslint:disable-next-line: jsx-no-lambda
                                onLanguageClicked(value)
                            }}
                        >
                            {languageLabel}
                        </StyledLink>
                    )
                })}
            </Group>
        )
    }

    return (
        <Wrapper>
            <TopNav>
                <TopNavContainer>
                    {
                        menuSecondaryList.map((data) => {
                            if(data.externalLink){
                                return(
                                    <OutboundLink to={data.link} target='_blank'>
                                        <TopNavItem>{data.text}</TopNavItem>
                                    </OutboundLink>
                                )
                            }
                            return (
                                <LocalizedLink to={data.link}>
                                    <TopNavItem>{data.text}</TopNavItem>
                                </LocalizedLink>
                            )
                        })
                    }
                </TopNavContainer>
            </TopNav>
            <Bar ref={barRef} className={menuVisible ? 'menuShow' : 'menuHidden'}>
                <LogoWrapper>
                    <Logo src={logo} to='/' />
                    <MenuIcon onClick={showHideMenu}>
                        <MenuIconFirstBar className={menuVisible ? 'change': ''} />
                        <MenuIconSecondBar className={menuVisible ? 'change': ''} />
                        <MenuIconThirdBar className={menuVisible ? 'change': ''} />
                    </MenuIcon>
                </LogoWrapper>
                <Menu className={menuVisible ? 'show' : 'hidden'}>
                    <Groups>
                        {MENU_LIST.map((menu: IMenuItem, index: number) => {
                            switch(menu.type){
                                case "Button":
                                case "Text": {
                                    const { text, url, externalLink } = menu.data;
                                    return (
                                        <Group key={text}>
                                            {
                                                externalLink ? <OutboundLink to={url}><GroupTitle>{text}</GroupTitle></OutboundLink>
                                                : <LocalizedLink to={url}><GroupTitle>{text}</GroupTitle></LocalizedLink>
                                            }
                                        </Group>
                                    )
                                }
                                case "Dropdown": {
                                    const { text, children } = menu.data;
                                    return(
                                        <Group key={text}>
                                            <GroupTitle 
                                                onClick={() => setMenuActive(menuActive === index ? - 1 : index)}
                                            >
                                                {text} <Arrow dataCollapse={menuActive === index}/>
                                            </GroupTitle>
                                            {menuActive === index && children.map((menu: IMenuItem) => {
                                                switch(menu.type){
                                                    case "Button":
                                                    case "Text": {
                                                        const { text, url, externalLink} = menu.data;
                                                        return(
                                                            externalLink ? <OutboundLink to={url}><Item>{text}</Item></OutboundLink>
                                                            : <LocalizedLink to={url}><Item>{text}</Item></LocalizedLink>
                                                        )
                                                    }
                                                }
                                            })}
                                        </Group>
                                    )
                                }
                            }
                        })}
                        {renderLanguages()}
                    </Groups>
                </Menu>
            </Bar>
        </Wrapper>
    )
}

export default Mobile
