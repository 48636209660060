import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 32 32`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <g fill={props.color || "#0d507a"}>
          <path d="M24.536 17.337h-1.857a5.1 5.1 0 01.293 1.712v7.236a2.2 2.2 0 01-.119.715h3.068A2.115 2.115 0 0028 24.855v-3.944a3.524 3.524 0 00-3.464-3.574zM9.028 19.049a5.1 5.1 0 01.293-1.712H7.465A3.524 3.524 0 004 20.911v3.944A2.115 2.115 0 006.079 27h3.068a2.2 2.2 0 01-.119-.715zM18.122 15.479h-4.244a3.525 3.525 0 00-3.464 3.574v7.236a.7.7 0 00.693.715h9.786a.7.7 0 00.693-.715v-7.24a3.525 3.525 0 00-3.464-3.57zM16 6a4.239 4.239 0 00-4.167 4.3 4.325 4.325 0 002.131 3.749 4.05 4.05 0 004.072 0 4.325 4.325 0 002.131-3.749A4.239 4.239 0 0016 6zM8.684 10.006a3.216 3.216 0 000 6.428 3.008 3.008 0 001.228-.261 3.174 3.174 0 001.521-1.443 3.271 3.271 0 00.367-1.51 3.169 3.169 0 00-3.116-3.214zM23.316 10.006A3.169 3.169 0 0020.2 13.22a3.271 3.271 0 00.367 1.51 3.17 3.17 0 001.521 1.443 3.011 3.011 0 001.228.261 3.216 3.216 0 000-6.428z" />
        </g>
        <path fill="none" d="M0 0h32v32H0z" />
      </g>
    </svg>
  )
}

export default SvgComponent
