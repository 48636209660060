import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <path
        d="M17.5 5h-2.8c-1.4 0-2.5 1.1-2.5 2.5 0 1.3.9 2.3 2.1 2.5v1c0 .1.1.3.2.3.1.1.3 0 .4-.1l1.3-1.2h1.3c1.4 0 2.5-1.1 2.5-2.5S18.9 5 17.5 5zm-2.9 2.8c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4zm1.5 0c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4zm1.4 0c-.2 0-.4-.2-.4-.4s.2-.4.4-.4.4.2.4.4-.2.4-.4.4z"
        fill={props.color || "#0d507a" }
      />
      <path
        d="M18.1 20c1 0 1.9-.8 1.9-1.9v-2.5c0-.3-.2-.5-.4-.6l-3.8-1.3c-.2-.1-.4 0-.5.1l-1.6 1.1c-1.7-.8-3.7-2.8-4.5-4.5l1.1-1.6c.1-.2.1-.4.1-.5L9 4.4c0-.2-.3-.4-.6-.4H5.9C4.8 4 4 4.8 4 5.9 4 13.1 10.9 20 18.1 20z"
        fill={props.color || "#0d507a" }
      />
    </svg>
  )
}

export default SvgComponent
