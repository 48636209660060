import { postHubspotForm } from 'helpers';
import { useState } from 'react';

export const useHubspotForm = (portalId: string, formGuid: string) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (data: any) => {
        setLoading(true);
        let fields = [];
        const keys = Object.keys(data);
        for(let i = 0; i < keys.length; i++){
            fields.push({name: keys[i], value: data[keys[i]]})
        }
        postHubspotForm(portalId, formGuid, {fields}).then((result: boolean) => setSuccess(result))
        .finally(() => {
            setLoading(false)
            setIsSubmitted(true)
        });
    }

    return {loading, success, isSubmitted, handleSubmit}
}