import { PackageTeaserCard } from 'components';
import React from 'react';
import {
    Wrapper,
    Container,
    Headline,
    CardListWrapper,
    CardListItem
} from './style';

const Component = ({headline, cards} : IPackageTeaserCardList ) => {
    return(
        <Wrapper>
            <Container>
                <Headline>{headline}</Headline>
                <CardListWrapper>
                    {
                        cards.map((card: IPackageTeaserCard, index: number) => (
                            <CardListItem>
                                <PackageTeaserCard {...card}/>
                            </CardListItem>
                        ))
                    }
                </CardListWrapper>
            </Container>
        </Wrapper>
    )
}

export default Component;
