import React from 'react'
import styled from 'styled-components'
import { Breadcrumb } from 'antd'
import { LocalizedLink } from 'components'
import { color } from 'themes'

export const CustomBreadcrumb = styled(
    (props: any) => <Breadcrumb {...props} />)`
    && {
        .ant-breadcrumb-link, .ant-breadcrumb-separator, .ant-breadcrumb-separator {
            font-size: 16px;
            line-height: 28px;
            color: ${color.white};

            a {
                color: ${color.white};
            }
        }
    }
`

export const CustomLink = styled((props: any) => <LocalizedLink {...props} />)`
    && {
        color: ${color.white};
        font-size: 16px;
        line-height: 28px;
        transition: 0.3s;
        font-weight: normal;
        -webkit-transform: scale(1.0);
        &:hover {
            color: ${color.white};
            font-weight: bold;
            display: inline-block;
            -webkit-transform: scale(1.1);
        }
    }
`
