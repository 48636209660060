import React from 'react'
import styled from 'styled-components'
import { color } from 'themes'
import { LocalizedLink, OutboundLink } from 'components'

export const Wrapper = styled.section`
  background-color: ${color.background};
  padding: 32px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const Text = styled.span`
  width: 100%;
`

export const Title = styled.div`
  font-size: 21px;
  line-height: 32px;
  font-weight: bold;
  color: ${color.mainBlue};
  margin-bottom: 12px;
`

export const Description = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: ${color.fontBlue};
  margin-bottom: 12px;
`

export const ImageWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  justify-content: center;
`

export const Row = styled.div`
  margin: 12px 0;
`

const ButtonStyles = `
  display: inline-flex;
  background: ${color.mediumSpringGreen};
  padding: 8px 12px;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${color.white};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0431373);
  border-radius: 8px;
  min-width: 124px;
  justify-content: center;
`

export const Button = styled((props: any) => <LocalizedLink {...props} />)`
  ${ButtonStyles}
`

export const ButtonOutboundLink = styled((props: any) => <OutboundLink {...props} />)`
  ${ButtonStyles}
`
