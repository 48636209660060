import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section`
    max-width: 904px;
    width: 100%;
    margin: 48px auto;
`

export const Container = styled.div`
`

export const Headline = styled.div`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: ${color.mainBlue};
`

export const CardListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin: 32px -32px 0 -32px;
`

export const CardListItem = styled.div`
    max-width: 312px;
    width: 100%;
    padding: 16px;
`
