import React from 'react';
import styled, { css } from 'styled-components';
import { color } from 'themes';
import { Icons } from 'components';

const { DownArrow, Warning } = Icons;


export const Wrapper = styled.section``

export const Container = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 80px auto;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: ${color.mainBlue};
    text-align: center;
    margin-bottom: 40px;
`

export const Table = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
`

export const TableData = styled.td<{headline?: boolean; colSpan?: number}>`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    ${props => props.headline && css`
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: ${color.mainBlue};
        cursor: pointer;
        ${Arrow}{
            float: right;
        }
    `}
    ${props => props.colSpan &&  props.colSpan > 1 && css`
        text-align: center;
    `}
`

export const TableRow = styled.tr<{type?: string; collapse?: boolean}>`
    ${TableData}{
        background: ${color.white};
    }

    ${props => props.type === "content" && css`
        ${TableData}{
            background: #fafafa;
        }
    `}

    ${props => (props.type === "content" && !props.collapse) && css`
        ${TableData}{
            display: none;
        }
    `}    
`

export const TableHeaderItem = styled.th`
    
`

export const TableHeader = styled.thead`
    background: ${color.white};
    ${TableRow}{
        ${TableHeaderItem}{
            padding: 12px;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: ${color.fontBlue};
            border: 1px solid ${color.grey};
            border-left: none;
            text-align: center;
            &:first-child {
                font-weight: bold;
                font-size: 24px;
                line-height: 48px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-right: 1px dashed ${color.grey};
                border-left: 1px solid ${color.grey};
                color: ${color.mainBlue};
                text-align: left;
            }
            &:last-child {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
            }
        }
    }
`

export const TableDataHeadline = styled.div`
    display: flex;
    align-items: center;
`

export const Arrow = styled((props: any) => <DownArrow width={28} height={28} {...props} />)`
    transition: all .3s;
    ${props => props.collapse && css`
        transform: rotate(180deg)
    `}
`

export const WarningIcon =  styled((props: any) => <Warning {...props} />)`
    margin-left: 12px;
`

export const TableBody = styled.tbody`
    &:before {
        content: "";
        display: block;
        height: 24px;
    }

    ${TableRow}{
        &:first-child {
            ${TableData}{
                border-top: 1px solid ${color.grey};
                &:first-child {
                    border-top-left-radius: 8px;
                }
                &:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        &:last-child {
            ${TableData}{
                &:first-child {
                    border-bottom-left-radius: 8px;
                }
                &:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }

        ${TableData}{
            padding: 12px;
            border: 1px solid ${color.grey};
            border-top: none;
            border-left: none;
            text-align: center;
            > p {
                margin-bottom: 0;
                > img {
                    width: 12px;
                    height: 12px;
                }
            }
            &:first-child {
                border-right: 1px dashed ${color.grey};
                border-left: 1px solid ${color.grey};
                text-align: left;
            }
        }
    }
`

export const TooltipWrapper = styled.div`
    margin: 24px 0;
`

export const IconTooltipsList = styled.div`
    display: flex;
    margin: 24px 0;
`

export const IconTooltipsItem = styled.div`
    display: flex;
    margin-right: 64px;
`

export const IconTooltip = styled.div`
    width: 12px;
    height: 12px;
    margin-right: 12px;
`

export const IconTooltipText = styled.div``

export const TextTooltipsList = styled.div``

export const TextTooltipItem = styled.div`
    margin: 12px 0;
`

export const ColGroup = styled.colgroup``

export const Col = styled.col``

