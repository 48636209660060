import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section`
    width: 100%;
    margin: 24px auto;
`

export const Container = styled.div`
`

export const Headline = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${color.mainBlue};
`

export const CardListWrapper = styled.div`
    display: flex;
    margin-top: 24px;
    overflow-x: auto;
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
        display: none;
        visibility: hidden;
    }
`

export const CardListItem = styled.div`
    width: 100%;
    margin: 0 auto;
    margin-bottom: 12px;
    padding: 0 16px;
`
