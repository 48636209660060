import React from 'react'
import styled, { css } from 'styled-components'
import { LocalizedLink, OutboundLink } from 'components'
import { color } from 'themes'

export const Wrapper = styled.div<{dataType: 'normal' | 'button'}>`
    ${props => props.dataType === 'normal' && css`
        background: ${color.white};
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
        border-radius: 16px;
        ${Container}{
            
        }
    `}

    ${props => props.dataType === 'button' && css`
        ${Container}{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            ${Headline}{
                margin-bottom: 20px;
                text-align: center;
            }
        }
    `}
`

export const Container = styled.div`
    padding: 24px;
`

export const Icon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.mainBlue};
    margin-bottom: 8px;
`

export const SubHeadline = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: ${color.fontBlue};
`

const ButtonStyles = `
    display: inline-flex;
    background: ${color.mainBlue};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0431373);
    border-radius: 8px;
    color: ${color.white};
    padding: 8px 40px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
`
export const Button = styled((props: any) => <LocalizedLink {...props} />)`
    ${ButtonStyles}
`

export const ButtonOutboundLink = styled((props: any) => <OutboundLink {...props} />)`
    ${ButtonStyles}
`