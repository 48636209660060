import React from 'react'
import { 
    Image, 
    Markdown 
} from 'components'
import {
    Wrapper,
    Title,
    Description,
    Content,
    Text,
    ImageWrapper,
    Row,
    Button,
    ButtonOutboundLink
} from './style'

const Mobile = ({ items }: ITextWithPictureGroup) => {
    return (
        <Wrapper>
            <Content>
                {items.map(({headline, subHeadline, buttonText, buttonLink, buttonLinkExternal, picture}: ITextWithPicture, key: number) => {
                    return(
                        <Row key={key}>
                            <Text>
                                <Title>{headline}</Title>
                                <Description>
                                    <Markdown source={subHeadline || ''} />
                                </Description>
                                {
                                    buttonText && (buttonLinkExternal ? <ButtonOutboundLink href={buttonLink} target='_blank'>{buttonText}</ButtonOutboundLink>
                                    : <Button to={buttonLink}>{buttonText}</Button>)
                                }
                            </Text>
                            <ImageWrapper>
                                <Image
                                    src={picture} alt={headline}
                                    style={{
                                        objectFit: 'cover',
                                        objectPosition: 'center',
                                        height: 224,
                                        maxWidth: '100%'
                                    }}
                                />
                            </ImageWrapper>
                        </Row>
                    )
                })}
            </Content>
        </Wrapper>
    )
}

export default Mobile
