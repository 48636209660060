import React from 'react'
import { ResponsiveLayout } from 'components'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Table = (props: ITable) => (
    <ResponsiveLayout
        mobileView={<Mobile {...props} />}
        desktopView={<Desktop {...props} />}
    />
)

export default Table
