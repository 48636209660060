import React from 'react'
import {
    StyledDropdown,
} from './style'

const defaultProps = {
    isClearable: false,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
}

const Component = ({ options = [], ...rest }: any) => (
    <StyledDropdown
        options={options}
        {...defaultProps}
        {...rest}
    />
)

export default Component
