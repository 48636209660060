import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, OutboundLink } from 'components';
import { color } from 'themes';

export const Wrapper = styled.section`
    width: 100%;
    background: ${color.white};
`

export const Container = styled.div`
    display: flex;
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
`

export const TextSection = styled.div`
    flex: 3;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    max-width: 482px;
    margin-bottom: 0;
`

export const Description = styled.p`
    max-width: 482px;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 0;
`

const ButtonStyles = `
    background: ${color.mediumSpringGreen};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0431373);
    border-radius: 16px;
    color: ${color.white};
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    padding: 12px 40px;
    margin-top: 24px;
    display: inline-flex;
    :hover {
        color: ${color.white};
    }
`

export const Button = styled((props : any) => <LocalizedLink {...props}/>)`
    ${ButtonStyles}
`
export const ButtonOutboundLink = styled((props : any) => <OutboundLink {...props}/>)`
    ${ButtonStyles}
`

export const CardList = styled.div`
    flex: 2;
`
