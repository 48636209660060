import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 32 32`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <g fill={props.color || "#0d507a"}>
          <path d="M16 14.274a5.832 5.832 0 001.993-.316l8.768-3.3a1.552 1.552 0 000-3.04l-8.768-3.3a6.459 6.459 0 00-3.985 0l-8.768 3.3a1.552 1.552 0 000 3.04l8.768 3.3a5.825 5.825 0 001.992.316z" />
          <path d="M16 16.039a6.9 6.9 0 01-2.4-.39l-5.085-1.915v2.493c0 .826.428 1.99 2.466 2.843a14.793 14.793 0 0010.03 0c2.039-.853 2.466-2.017 2.466-2.843v-2.493l-5.084 1.915a6.907 6.907 0 01-2.393.39zM26.494 18.816V12.6l-1.406.529v5.687a2.8 2.8 0 00-1.506 2.629v3.782a2.266 2.266 0 104.418 0v-3.782a2.8 2.8 0 00-1.506-2.629z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
