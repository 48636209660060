import { ProductCard } from 'components';
import React from 'react';
import {
    Wrapper,
    Container,
    TextSection,
    CardList,
    Headline,
    Description,
    Button,
    ButtonOutboundLink
} from './style'
const Component = ({headline, subHeadline, productCardList, buttonText, buttonLink, buttonExternalLink} : IProductCardListWithText) => {
    return(
        <Wrapper>
            <Container>
                <TextSection>
                    <Headline>{headline}</Headline>
                    <Description>{subHeadline}</Description>
                    {
                        buttonText && (buttonExternalLink ? <ButtonOutboundLink to={buttonLink} target='_blank'>{buttonText}</ButtonOutboundLink>
                        : <Button to={buttonLink}>{buttonText}</Button>)
                    }
                </TextSection>
                <CardList>
                    {
                        productCardList.map((card : IProductCard, index : number) => (
                            <ProductCard key={index} {...card} />
                        ))
                    }
                </CardList>
            </Container>
        </Wrapper>
    )
}

export default Component
