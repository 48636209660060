import React, {
    createContext,
    useContext,
    useState,
} from 'react'

import { get, isEmpty } from 'lodash'

import {
    useLocation
} from 'hooks'

export const TranslationContext = createContext({
    languageData: [],
    useTranslationBySlug: (
        slug: string,
        // tslint:disable-next-line: no-empty
        i18n?: object[]): any => { },
    getComponent: (
        webComponents: any = {},
        path: string = '',
        defaultValue?: any
        // tslint:disable-next-line: no-empty
    ): any => { }
})

export const TranslationProvider = ({ data, fallbackData, children }: any) => {
    const { basePath } = useLocation()
    const [languageData] = useState<any>(data)
    const [defaultLocaleData] = useState<any>(fallbackData)
    const translateBySlug = (slug: string, i18n: object[] = languageData) => {
        return i18n.find(({ websiteSlug }: any) => {
            return websiteSlug === slug
        })
    }

    // 622: If there is no translation avaible in Squidex --> default English
    const getComponent = (
        webComponents: any = {},
        path: string = '',
        defaultValue?: any
    ) => {
        const result = get(webComponents, path, defaultValue)
        if (isEmpty(result)) {
            // tslint:disable-next-line: max-line-length
            const { websiteComponents = {} }: any = translateBySlug(basePath, defaultLocaleData)
            return get(websiteComponents, path, defaultValue)
        }

        return result
    }

    return (
        <TranslationContext.Provider
            value={{
                languageData,
                useTranslationBySlug: translateBySlug,
                getComponent,
            }}
        >
            {children}
        </TranslationContext.Provider>
    )
}

// used by: const {language, switchLang, languageData} = useTranslation()
export const useTranslation = () => useContext(TranslationContext)
