import React from 'react';
import {
    Wrapper,
    Container,
    Headline,
    CardList,
    CardItem
} from './style';
import { Card } from 'components';

const Component = (props: ICardList) => {
    return(
        <Wrapper>
            <Container>
                <Headline>{props.headline}</Headline>
                <CardList>
                    {props.cards.map((card: ICard, index: number) => (
                        <CardItem key={index}>
                            <Card {...card} />
                        </CardItem>
                    ))}
                </CardList>
            </Container>
        </Wrapper>
    )
}

export default Component