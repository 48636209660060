import React from 'react';
import styled from 'styled-components';
import { LocalizedLink, Icons } from 'components';
import { color } from 'themes';
const { RightArrow } = Icons;

export const Wrapper = styled.section`
    width: 100%;
    padding: 48px 16px;
    background: ${color.white};
`

export const Container = styled.div`
    display: flex;
    max-width: 1280px;
    margin: 0 auto;
`

export const TabWrapper = styled.div`
    flex: 2;
`

export const TabList = styled.div`
    padding-bottom: 12px;
`

export const Divider = styled.div`
    margin: 12px 16px;
    height: 1px;
    background: ${color.grey};
`

export const ImageList = styled.div`
    flex: 3;
    margin: 0 16px;
    min-height: 620px;
    overflow: hidden;
`

export const TabListFooter = styled.div`
    padding: 12px 16px;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const Description = styled.p`
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${color.fontBlue};
`

export const TabListItem = styled.div`
    padding: 12px 16px;
    cursor: pointer;
    transition: .2s all;
    border-radius: 16px;
    &.active {
        background: #F5F5F5;
        ${Headline}{
            color: ${color.mainBlue};
        }
        ${Description}{
            color: ${color.mainBlue};
        }
    }
`

export const TextButton = styled((props: any) => <LocalizedLink {...props}/>)`
    font-size: 16px;
    line-height: 24px;
    color: ${color.mediumBlue};
    display: inline-flex;
    align-items: center;
`

export const Arrow = styled((props : any) =>
    <RightArrow width={7} height={14} color={color.mediumBlue} {...props} />
)`
    margin-left: 16px;
`

export const ImageListItem = styled.div`
    height: 100%;
    width: 100%;
    /* background: ${color.iceBlue}; */
`

export const DotsList = styled.div`
    margin-top: 12px;
    text-align: center;
`

export const Dot = styled.div<any>`
    cursor: pointer;
    height: 12px;
    width: 12px;
    margin: 0 8px;
    background-color: #C4C4C4;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
    &.active, :hover {
        background-color: ${color.fontBlue};
    }
`
