import logo1 from './logos/1.png'
import logo2 from './logos/2.png'
import logo3 from './logos/3.png'
import logo4 from './logos/4.png'

export const INFO = {
  title: 'Approved By',
  partners: [
    {
      logo: logo1,
      label: 'Made in Germany',
      link: '',
    },
    {
      logo: logo2,
      label: 'German Accelerator',
      link: '',
    },
    {
      logo: logo3,
      label: 'Foreign Office',
      link: '',
    },
    {
      logo: logo4,
      label: 'TÜV',
      link: '',
    },
  ],
}
