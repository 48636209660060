import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 20} height={props.height || 20} viewBox={`0 0 ${props.width || 20} ${props.height || 20}`} fill="none" {...props}>
      <path
        d="M13.592 12.406h-.54a.263.263 0 00-.271.276v1.86c0 .378-.133.698-.395.967a1.292 1.292 0 01-.953.402H4.425c-.371 0-.687-.135-.953-.402a1.33 1.33 0 01-.395-.966V7.43c0-.377.133-.698.395-.967.263-.266.582-.401.953-.401H9.45a.263.263 0 00.271-.275v-.545a.263.263 0 00-.271-.275H4.425c-.667 0-1.239.239-1.714.72A2.395 2.395 0 002 7.432v7.109c0 .676.236 1.257.711 1.739A2.32 2.32 0 004.425 17h7.008c.666 0 1.239-.24 1.714-.721.478-.482.714-1.06.714-1.74v-1.857a.27.27 0 00-.077-.198.26.26 0 00-.192-.078z"
        fill="#fff"
        stroke="#fff"
      />
      <path
        d="M17.84 3.162A.516.516 0 0017.46 3h-4.31a.514.514 0 00-.378.162.529.529 0 00-.16.383c0 .146.054.275.16.383l1.48 1.502-5.493 5.577a.275.275 0 00-.085.197c0 .075.03.14.085.198l.959.973a.267.267 0 00.195.086.256.256 0 00.194-.086l5.491-5.57 1.484 1.502a.526.526 0 00.758 0 .529.529 0 00.16-.383V3.548a.53.53 0 00-.16-.386z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
