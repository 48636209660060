import React from 'react'
import { Icons, OutboundLink, LocalizedLink } from 'components'
import styled, { css } from 'styled-components'
import { color } from 'themes'

const { RightArrow } = Icons

const BorderBottom = `
    border-bottom: 0.5px solid ${color.grey};
    margin-bottom: 22px;
    padding-bottom: 22px;
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
`

export const Container = styled.section<{type: string}>`
    padding: 16px;
    ${props => props.type === "normal" && css`
        background-color: ${color.iceBlue};
    `}
    ${props => props.type === "with-link" && css`
        background-color: ${color.white};
    `}
`

export const Wrapper = styled.div`
    display: flex;
    margin: 0 auto;
    flex-direction: column;
`

export const USPItemWrapper = styled.div`
    display: flex;
    align-items: center;
    ${BorderBottom}
`

export const Link = styled((props: any) => <LocalizedLink {...props} />)`
    ${BorderBottom}
`

export const ExternalLink = styled((props: any) => <OutboundLink {...props} />)`
    ${BorderBottom}
`

export const USPItemIcon = styled.div`
    padding-right: 16px;
    > img {
        width: 80px;
        height: 80px;
    }
`

export const USPItemRightContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const USPItemHeadline = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: ${color.mainBlue};
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const USPItemSubHeadline = styled.div`
    margin-top: 8px;
    font-size: 14px;
    line-height: 20px;
    color: ${color.fontBlue};
`

export const LinkArrow = styled((props: any) => <RightArrow color={color.mainBlue} width={8} height={16} {...props} />)`
    margin-left: 8px;
`
