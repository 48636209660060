import React from 'react';
import ResponsiveLayout from '../ResponsiveLayout';
import Desktop from './Desktop';
import Mobile from './Mobile';

const MarkdownSection = (props : IMarkdownSection) => (
    <ResponsiveLayout
        mobileView={<Mobile {...props}/>}
        desktopView={<Desktop {...props}/>}
    />
)

MarkdownSection.defaultProps = {}

export default MarkdownSection
