import React from 'react';
import { Dropdown, Icons, Image, Markdown } from 'components';
import { useForm, Controller } from "react-hook-form";
import { useHubspotForm } from 'hooks';
import { Result, Spin } from 'antd';
import {
    Wrapper,
    Container,
    FormSection,
    ContactSection,
    ContactInfo,
    EmergencyContact,
    FormHeadline,
    FormGroup,
    FormGroupItem,
    Input,
    FormFooter,
    Textarea,
    FormGroupItemLabel,
    SubmitButton,
    Tooltip,
    NeedHelpHeadline,
    CompanyLogo,
    CompanyName,
    CompanySubHeadline,
    ContactInfoList,
    ContactInfoListItem,
    Icon,
    ContactInfoContent,
    ChatWithUs,
    SocialMediaNetworksHeadline,
    SocialMediaNetworksList,
    SocialMediaNetworkItem,
    EmergencyContactHeadline,
    EmergencyContactSubHeadline,
    EmergencyContactInfo,
    EmergencyContactInfoIcon,
    EmergencyContactInfoContext,
    EmergencyContactList,
    EmergencyContactListItem,
    ErrorMessage,
} from './style';

const { Phone, Email, Location } = Icons;

const Component = (props: IContactForm) => {
    const {
        backgroundImage,
        formHeadline,
        needHelpText,
        companyLogo,
        companyAddress,
        companyName,
        companyDescription,
        companyEmail,
        companyPhoneNumber,
        chatWithUs,
        chatWithUsLink,
        submitText,
        tooltip,
        socialMediaNetworksHeadline,
        socialMediaNetworks,
        emergencyContactsHeadline,
        emergencyContacts,
        formGroup,
        hubspotFormGUID,
        hubspotPortalId,
        successMessage,
        failedMessage,
    } = props;

    const { control, register, handleSubmit, errors } = useForm();
    const { loading, success, isSubmitted, handleSubmit: hubspotFromSubmit } = useHubspotForm(hubspotPortalId, hubspotFormGUID);

    const onSubmit = (data: any) => {
        let keys = Object.keys(data);
        let fieldsObject = {};
        for(let i = 0; i < keys.length; i++){
            switch(typeof data[keys[i]]){
                case 'object':
                    fieldsObject[`${keys[i]}`] = data[keys[i]].value;
                    break;
                case 'undefined':
                    fieldsObject[`${keys[i]}`] = '';
                    break;
                default:
                    fieldsObject[`${keys[i]}`] = data[keys[i]];
            }
        }
        hubspotFromSubmit(fieldsObject);
    };

    const renderFormItem = (formItem: IFormField) => {
        const { fieldName, label, pattern, placeholder, options, required } = formItem;
        const regex = pattern ? new RegExp(pattern, 'g') : undefined;
        switch(formItem.type){
            case "Input": {
                return(
                    <FormGroupItem required={required}>
                        <FormGroupItemLabel>{label}</FormGroupItemLabel>
                        <Input name={fieldName} placeholder={placeholder} 
                        ref={register({
                            required: {value: required, message: "This field is required!"}, 
                            pattern: regex
                        })} />
                        <ErrorMessage>
                            {errors[fieldName] && errors[fieldName].message}
                            {errors[fieldName]?.type === "pattern" && "Invalid data"}
                        </ErrorMessage>
                    </FormGroupItem>
                )
            }
            case "Textarea":
                return(
                    <FormGroupItem required={required}>
                        <FormGroupItemLabel>{label}</FormGroupItemLabel>
                        <Textarea name={fieldName} placeholder={placeholder} ref={register({ required: required, pattern: regex })} />
                        <ErrorMessage>
                            {errors[fieldName] && errors[fieldName].message}
                            {errors[fieldName]?.type === "pattern" && "Invalid data"}
                        </ErrorMessage>
                    </FormGroupItem>
                )
            case "Select":
                return(
                    <FormGroupItem required={required}>
                        <FormGroupItemLabel>{label}</FormGroupItemLabel>
                        <Controller
                            name={fieldName}
                            control={control}
                            options={options}
                            placeholder={placeholder}
                            as={Dropdown}
                            rules={{required: {value: required, message: "This field is required!"}}}
                        />
                        <ErrorMessage>
                            {errors[fieldName] && errors[fieldName].message}
                            {errors[fieldName]?.type === "pattern" && "Invalid data"}
                        </ErrorMessage>
                    </FormGroupItem>
                )
        }
    }

    return(
        <Wrapper style={{
            background: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover'
        }}>
            <Container>
                <FormSection>
                    <FormHeadline>{formHeadline}</FormHeadline>
                    {(!loading && isSubmitted) ? 
                    (success ? <Result
                        status="success"
                        title={successMessage}
                      />
                    : <Result
                        status="500"
                        title="500"
                        subTitle={failedMessage}
                    />) 
                    :
                        <>
                            {formGroup.map(({formItems}, key: number) => (
                                <FormGroup key={key}>
                                    {formItems.map((formItem: IFormField) => renderFormItem(formItem))}
                                </FormGroup>
                            ))}
                            <FormFooter>
                                {loading ? <Spin /> : <SubmitButton onClick={handleSubmit(onSubmit)}>{submitText}</SubmitButton>}
                                <Tooltip><Markdown source={tooltip} /></Tooltip>
                            </FormFooter>
                        </>
                    }
                </FormSection>
                <ContactSection>
                    <ContactInfo>
                        <NeedHelpHeadline>
                            {needHelpText}
                        </NeedHelpHeadline>
                        <CompanyLogo>
                            <Image src={companyLogo} 
                                alt={companyName} 
                                style={{width: 64, height: 64}}
                            />
                        </CompanyLogo>
                        <CompanyName>{companyName}</CompanyName>
                        <CompanySubHeadline>{companyDescription}</CompanySubHeadline>
                        <ContactInfoList>
                            <ContactInfoListItem>
                                <Icon><Phone /></Icon>
                                <a href={`tel:${companyPhoneNumber}`}>
                                    <ContactInfoContent>{companyPhoneNumber}</ContactInfoContent>
                                </a>
                            </ContactInfoListItem>
                            <ContactInfoListItem>
                                <Icon><Email /></Icon>
                                <a href={`mailto:${companyEmail}`}>
                                    <ContactInfoContent>{companyEmail}</ContactInfoContent>
                                </a>
                            </ContactInfoListItem>
                            <ContactInfoListItem>
                                <Icon><Location /></Icon>
                                <ContactInfoContent>{companyAddress}</ContactInfoContent>
                            </ContactInfoListItem>
                        </ContactInfoList>
                        <ChatWithUs to={chatWithUsLink}>{chatWithUs}</ChatWithUs>
                        <SocialMediaNetworksHeadline>{socialMediaNetworksHeadline}</SocialMediaNetworksHeadline>
                        <SocialMediaNetworksList>
                            {socialMediaNetworks.map(({name, logo, link}, key: number) => (
                                <SocialMediaNetworkItem key={key} href={link} target={"_blank"}>
                                    <Image src={logo} alt={name} style={{width: 32, height: 32}}/>
                                </SocialMediaNetworkItem>
                            ))}
                        </SocialMediaNetworksList>
                    </ContactInfo>
                    <EmergencyContact>
                        <EmergencyContactHeadline>{emergencyContactsHeadline}</EmergencyContactHeadline>
                        <EmergencyContactList>
                            {emergencyContacts.map(({headline, phoneNumber}, key: number) => (
                                <EmergencyContactListItem key={key}>
                                    <EmergencyContactSubHeadline>{headline}</EmergencyContactSubHeadline>
                                    <EmergencyContactInfo>
                                        <EmergencyContactInfoIcon><Phone /></EmergencyContactInfoIcon>
                                        <a href={`tel:${phoneNumber}`}>
                                            <EmergencyContactInfoContext>{phoneNumber}</EmergencyContactInfoContext>
                                        </a>
                                    </EmergencyContactInfo>
                                </EmergencyContactListItem>
                            ))}
                        </EmergencyContactList>
                    </EmergencyContact>
                </ContactSection>
            </Container>
        </Wrapper>
    )
}

export default Component;