import styled from 'styled-components';

export const Wrapper = styled.section`
    width: 100%;
`

export const Container = styled.div`
    padding: 16px;
`

export const Content = styled.div``