import React from 'react'
import {
    Container,
    USPItemWrapper,
    USPItemIcon,
    USPItemRightContent,
    USPItemHeadline,
    USPItemSubHeadline,
    Wrapper,
    LinkArrow,
    Link,
    ExternalLink
} from './style'
import { Image } from 'components'

const Mobile = ({uSPsList = [], type = "normal"}: any) => {
    const renderUSPsListByType = (type = "normal") => {
        switch (type){
            case "normal":
                return(
                    uSPsList.map(({ icon, headline, subHeadline }: IUSPItem) => {
                        return (
                            <USPItemWrapper key={headline}>
                                <USPItemIcon>
                                    <Image src={icon} alt={headline} />
                                </USPItemIcon>
                                <USPItemRightContent>
                                    <USPItemHeadline>
                                        {headline}
                                    </USPItemHeadline>
                                    <USPItemSubHeadline>
                                        {subHeadline}
                                    </USPItemSubHeadline>
                                </USPItemRightContent>
                            </USPItemWrapper>
                        )
                    })
                )
            case "with-link":
                return(
                    uSPsList.map(({ icon, headline, subHeadline, link = '/', externalLink }: IUSPWithLinkItem) => {
                        if(externalLink){
                            return(
                                <ExternalLink to={link} target='_blank'>
                                    <USPItemWrapper>
                                        <USPItemIcon>
                                            <Image src={icon} alt={headline} />
                                        </USPItemIcon>
                                        <USPItemRightContent>
                                            <USPItemHeadline>
                                                {headline}
                                                <LinkArrow />
                                            </USPItemHeadline>
                                            <USPItemSubHeadline>
                                                {subHeadline}
                                            </USPItemSubHeadline>
                                        </USPItemRightContent>
                                    </USPItemWrapper>
                                </ExternalLink>
                            )
                        }
                        return (
                            <Link to={link}>
                                <USPItemWrapper>
                                    <USPItemIcon>
                                        <Image src={icon} alt={headline} />
                                    </USPItemIcon>
                                    <USPItemRightContent>
                                        <USPItemHeadline>
                                            {headline}
                                            <LinkArrow />
                                        </USPItemHeadline>
                                        <USPItemSubHeadline>
                                            {subHeadline}
                                        </USPItemSubHeadline>
                                    </USPItemRightContent>
                                </USPItemWrapper>
                            </Link>
                        )
                    })
                )
        }
    }
    return (
        <Container type={type}>
            <Wrapper>
                {renderUSPsListByType(type)}
            </Wrapper>
        </Container>
    )
}

export default Mobile
