import * as React from "react"

function DefaultIcon(props: any) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill={props.color}/>
    </svg>
  )
}

export default DefaultIcon
// #5DACA8
