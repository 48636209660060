import styled from 'styled-components'

export const Container = styled.section`
    height: 480px;
    display: flex;
    flex-direction: column;
    position: relative;
`

export const Wrapper = styled.div`
    margin: 0 auto;
    width: 1280px;
`

export const MarginContainer = styled.div`
  padding: 56px 32px;
`

export const HeaderContainer = styled.div`
`

export const RadientContainer = styled.div`
    display: flex;
    width: 100%;
    height: 56px;
    background: transparent linear-gradient(180deg, #040D1F 0%, #040D1F00 100%) 0% 0% no-repeat padding-box;
    opacity: 0.75;
`

export const BreadCrumbContainer = styled.div`
    display: flex;
    flex: 1;
    max-width: 1280px;
    padding-top: 16px;
    margin: 0 auto;
`
