import React from 'react'

function Icon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
        >
            <g fill='none' stroke='#0e517a' strokeWidth='1.5' data-name='Ellipse 540'>
                <circle cx='12' cy='12' r='12' stroke='none' />
                <circle cx='12' cy='12' r='11.25' />
            </g>
        </svg>
    )
}

export default Icon
