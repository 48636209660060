import React, { useState, useEffect, useRef } from 'react';
import { Image } from 'components';
import {
    Wrapper,
    Container,
    TabWrapper,
    TabList,
    TabListItem,
    ImageList,
    TabListFooter,
    Headline,
    Description,
    TextButton,
    ImageListItem,
    Divider,
    Arrow,
    DotsList,
    Dot
} from './style';

const Desktop = ({ tabs, footerHeadline, footerButtonText, footerButtonLink, footerButtonExternalLink }: IImageSwitchingTabs) => {
    const [active, setActive] = useState(0);
    const imageListRef = useRef() as React.MutableRefObject<HTMLInputElement>;
    const [imageListItemWidth, setImageListItemWidth] = useState(0);
    const [translateXPosition, setTranslationXPosition] = useState(0);

    useEffect(() => {
        if (imageListRef && imageListRef.current){
            setImageListItemWidth(imageListRef.current.clientWidth)
        }
    }, [imageListRef])

    useEffect(() => {
        setTranslationXPosition(imageListItemWidth * active)
    }, [active, imageListItemWidth])

    useEffect(() => {
        const autoPlay = setInterval(() => {
            if(active === tabs.length - 1){
                setActive(0)
            } else {
                setActive(active => active + 1)
            }
        }, 3000);

        return () => {
            clearInterval(autoPlay);
        }
    }, [tabs, active])

    return(
        <Wrapper>
            <Container>
                <TabWrapper>
                    <TabList>
                        {
                            tabs.map((tab: IImageSwitchingTab, index: number) => (
                                <TabListItem
                                    key={index}
                                    className={index === active ? 'active' : ''}
                                    onClick={() => setActive(index)}
                                >
                                    <Headline>{tab.headline}</Headline>
                                    <Description>{tab.subHeadline}</Description>
                                </TabListItem>
                            ))
                        }

                    </TabList>
                    <Divider />
                    <TabListFooter>
                        <Headline>{footerHeadline}</Headline>
                        <TextButton to={footerButtonLink}>
                            {footerButtonText}
                            <Arrow />
                        </TextButton>
                    </TabListFooter>
                </TabWrapper>
                <ImageList ref={imageListRef}>
                    <div style={{
                        display: "inline-flex",
                        transform: `translateX(-${translateXPosition}px)`,
                        transition: `all .3s`,
                    }}>
                        {
                            tabs.map((tab: IImageSwitchingTab, index: number) => (
                                <ImageListItem key={index} style={{width: imageListItemWidth}}>
                                    <Image
                                        src={tab.image}
                                        alt={tab.headline}
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            objectPosition: 'center'
                                        }}
                                    />
                                </ImageListItem>
                            ))
                        }
                    </div>
                    <DotsList>
                        {
                            tabs.map((tab: IImageSwitchingTab, index: number) => (
                                <Dot className={active === index ? 'active' : ''} key={index} onClick={() => setActive(index)} />
                            ))
                        }
                    </DotsList>
                </ImageList>
            </Container>
        </Wrapper>
    )
}

export default Desktop;
