import React from 'react'
import styled from 'styled-components'
import { color } from 'themes'
import { Icons, LocalizedLink } from 'components'
const { RightArrow } = Icons

export const Wrapper = styled((props : any) => <LocalizedLink {...props}/>)`
    display: block;
`

export const CardBody = styled.div`
    position: relative;
    padding: 12px;
    background: ${color.white};
    border-radius: 8px;
    display: flex;
    align-items: center;
    transition: all .2s;
    cursor: pointer;
    :hover {
        background: ${color.lightGrey};
    }
`

export const CardIcon = styled.div`
    width: 64px;
    height: 64px;
    background: ${color.brightBlue};
    border-radius: 4px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 64px;
    margin-right: 20px;
`

export const CardName = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${color.fontBlue};
`

export const CardDescription = styled.div`
    font-size: 14px;
    line-height: 18px;
    color: ${color.fontBlue};
`

export const CardPrice = styled.span`
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: ${color.mainBlue};
`

export const CardArrow = styled((props : any) => <RightArrow {...props}/>)`
    width: 8px;
    height: 16px;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(-50%, -50%);
`
