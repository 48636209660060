import React, {
    createContext,
    useContext,
    useEffect,
    useState,
} from 'react'
import { useLocation } from '@reach/router'
import qs from 'query-string'

const QueryStringContext = createContext({
    queryStringObj: {},
})

export const QueryStringProvider = (props: any) => {
    const location = useLocation()
    const [
        queryString,
        setQueryString
    ] = useState<any>(qs.parse(location.search))

    useEffect(() => {
        setQueryString(qs.parse(location.search))
    }, [location.search])

    return (
        <QueryStringContext.Provider
            value={{
                queryStringObj: queryString,
            }}
        >
            {props.children}
        </QueryStringContext.Provider>
    )
}

export const useQueryString = () => useContext(QueryStringContext)
