import axios from 'axios'

export const hubspotApiUrl =
  'https://api.hsforms.com/submissions/v3/integration/submit/:portalId/:formGuid'

export const postHubspotForm = async (
  portalId: string,
  formGuid: string,
  data: any,
) => {
  const url = hubspotApiUrl
    .replace(':portalId', portalId)
    .replace(':formGuid', formGuid)
  const {status} = await axios.post(url, data)
  return status === 200
}
