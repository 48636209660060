import React from "react";
import { Image } from 'components'
import {
    Wrapper,
    CardBody,
    CardContent,
    CardIcon,
    CardName,
    CardDescription,
    CardArrow
} from './style';

const Desktop = ({icon, headline, subHeadline, link} : IProductCard) => {
    return(
        <Wrapper to={link}>
            <CardBody>
                <CardIcon>
                { icon && (
                    <Image src={icon}
                        alt={headline}
                        style={{
                            width: 64,
                            height: 64,
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: 4
                        }}
                    />
                )}
                </CardIcon>
                <CardContent>
                    <CardName>{headline}</CardName>
                    <CardDescription>{subHeadline}</CardDescription>
                </CardContent>
                <CardArrow />
            </CardBody>
        </Wrapper>
    )
}

export default Desktop
