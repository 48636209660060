import React from 'react';
import styled from 'styled-components';
import { color } from 'themes';
import { OutboundLink } from 'components';

export const Wrapper = styled.section`
    background: #F1F1F5;
`

export const Container = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 40px 0;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: ${color.mainBlue};
    text-align: center;
    margin-bottom: 0;
`

export const SubHeadline = styled.p`
    font-weight: bold;
    font-size: 16px;
    color: ${color.mainBlue};
    text-align: center;
`

export const FilesList = styled.div`
    display: flex;
    max-width: 728px;
    margin: 0 auto;
    flex-wrap: wrap;
    justify-content: center;
`

export const FileListItem = styled((props: any) => <OutboundLink target='_blank' {...props} />)`
    display: flex;
    justify-content: space-between;
    max-width: 324px;
    width: 100%;
    background: ${color.backgroundWhite};
    padding: 12px 24px;
    border: 1px solid ${color.darkGrey};
    box-sizing: border-box;
    border-radius: 16px;
    margin: 12px 16px;
    cursor: pointer;
`

export const FileName = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: ${color.fontBlue};
`

export const FileDownload = styled.div`
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: ${color.mediumBlue};
`

export const FileDownloadIcon = styled.div`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FileIcon = styled.div`
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
`