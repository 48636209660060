import React from 'react';
import styled from 'styled-components';
import { LocalizedLink } from 'components';
import { color } from 'themes';

export const CardWrapper = styled((props : any) => <LocalizedLink {...props}/>)`
    display: block;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0784314);
    border-radius: 16px;
    background: ${color.white};
    height: 100%;
    width: 100%;
`

export const CardBody = styled.div`
    padding: 16px;
`

export const CardMedia = styled.div`
    width: 248px;
    height: 160px;
    background: ${color.brightBlue};
    border-radius: 8px;
`

export const CardHeadline = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 8px;
    color: ${color.fontBlue};
    min-height: 48px;
`

export const CardDescription = styled.p`
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 0;
    margin-top: 4px;
    color: ${color.fontBlue};
    min-height: 40px;
`
