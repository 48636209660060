import * as React from "react"

function Minus(props: any) {
  return (
    <svg width={props.width || 20} height={props.height || 20} viewBox="0 0 20 20" fill="none">
      <path
        d="M18 10.001H2"
        stroke="#1E3056"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Minus
