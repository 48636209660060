import * as React from "react"
import styled from 'styled-components'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {

    const Svg = styled.svg<any>`
        &:hover {
            .cls-1 {
                fill: #4267B2;
            }
        }
    `
    return (
        <Svg width={props.width || "24px" } height={props.height || "24px" } viewBox="0 0 32 32" fill="none" {...props}>
        <path
            className="cls-1"
            d="M28 0H4a4 4 0 00-4 4v24a4 4 0 004 4h24a4 4 0 004-4V4a4 4 0 00-4-4z"
            fill={props.color || "#4267B2"}
        />
        <path
            className="cls-2"
            d="M22.24 20.64l.72-4.64h-4.4v-3.04c0-1.28.64-2.48 2.64-2.48h2V6.56c-1.2-.16-2.4-.32-3.6-.32-3.68 0-6.08 2.24-6.08 6.24V16H9.44v4.64h4.08V32h4.96V20.64h3.76z"
            fill="#fff"
        />
        </Svg>
    )
}

export default SvgComponent
