export const INFO : IContactForm = {
    hubspotPortalId: '',
    hubspotFormGUID: '',
    backgroundImage: '',
    backgroundMobileImage: '',
    successMessage: '',
    failedMessage: '',
    formGroup: [
        {
            formItems: [
                {
                    fieldName: '',
                    label: '',
                    pattern: '',
                    placeholder: '',
                    required: false,
                    options: [],
                    type: 'Input'
                }
            ]
        }
    ],
    companyLogo: '',
    emergencyContacts: [],
    socialMediaNetworks: [],
    chatWithUs: '',
    chatWithUsLink: '',
    companyAddress: '',
    companyDescription: '',
    companyEmail: '',
    companyName: '',
    companyPhoneNumber: '',
    emergencyContactsHeadline: '',
    formHeadline: '',
    needHelpText: '',
    socialMediaNetworksHeadline: '',
    submitText: '',
    tooltip: '',
}