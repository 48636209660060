import React from 'react';
import { Image } from 'components';
import {
    Wrapper,
    Container,
    TabList,
    TabListItem,
    TabListFooter,
    Headline,
    Description,
    TextButton,
    ImageListItem,
    Divider,
    Arrow
} from './style';

const Desktop = ({ tabs, footerHeadline, footerButtonText, footerButtonLink, footerButtonExternalLink }: IImageSwitchingTabs) => {
    return(
        <Wrapper>
            <Container>
                <TabList>
                    {tabs.map((tab: IImageSwitchingTab, index: number) => (
                        <TabListItem key={index}>
                            <Headline>{tab.headline}</Headline>
                            <Description>{tab.subHeadline}</Description>
                            <ImageListItem>
                                <Image
                                    src={tab.image}
                                    alt={tab.headline}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </ImageListItem>
                        </TabListItem>
                    ))}
                    <Divider />
                    <TabListFooter>
                        <Headline>{footerHeadline}</Headline>
                        <TextButton to={footerButtonLink}>
                            {footerButtonText}
                            <Arrow />
                        </TextButton>
                    </TabListFooter>
                </TabList>
            </Container>
        </Wrapper>
    )
}

export default Desktop;
