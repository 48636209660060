import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <path
        d="M19.2 18H4.8c-.5 0-.8-.4-.8-.8V7.8c0-.4.3-.8.8-.8h14.5c.4 0 .7.4.7.8v9.4c0 .4-.3.8-.8.8z"
        fill={props.color || "#0d507a" }
      />
      <path
        d="M5.5 16.4l2.7-3.5M18.5 16.4l-2.7-3.5M18.5 8.6L13 13.2c-.6.5-1.4.5-2 0L5.5 8.6"
        fill={props.color || "#0d507a" }
        stroke="#fff"
        strokeWidth={0.8}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
