import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={props.width || 20}
      height={props.height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm-2.29-2.333A17.9 17.9 0 016.027 11H2.062a8.008 8.008 0 005.648 6.667zM8.03 11c.151 2.439.848 4.73 1.97 6.752A15.906 15.906 0 0011.97 11H8.03zm9.908 0h-3.965a17.9 17.9 0 01-1.683 6.667A8.008 8.008 0 0017.938 11zM2.062 9h3.965A17.9 17.9 0 017.71 2.333 8.008 8.008 0 002.062 9zm5.969 0h3.938A15.904 15.904 0 0010 2.248 15.905 15.905 0 008.03 9h.001zm4.259-6.667A17.9 17.9 0 0113.973 9h3.965a8.008 8.008 0 00-5.648-6.667z"
        fill={props.color || "#0D507A"}
      />
    </svg>
  )
}

export default SvgComponent
