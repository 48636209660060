import React from 'react'
import {color as colors} from 'themes'
import {IconWrapper} from './style'

const LeftArrow = ({color = colors.fontBlue, ...rest}: any) => (
  <IconWrapper viewBox='0 0 9.914 17.828' {...rest}>
    <path
      id='Pfad_133'
      data-name='Pfad 133'
      d='M0,0,7.5,7.5,15,0'
      transform='translate(8.5 1.414) rotate(90)'
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </IconWrapper>
)

export default LeftArrow
