import React from 'react'
import { Breadcrumb } from 'antd'
import { LocalizedLink } from 'components'
import { CustomBreadcrumb, CustomLink } from './style'
import { HOME } from 'paths'

const BreadCrumb = ({ breadcrumbs }: any) => {
    return (
        <CustomBreadcrumb separator='>'>
            <Breadcrumb.Item>
                <CustomLink to={HOME}>Home</CustomLink>
            </Breadcrumb.Item>
            {
                breadcrumbs.map(({ name, url = '' }: any) =>
                    <Breadcrumb.Item>
                        {
                            url ? (
                                <LocalizedLink to={url}>
                                    {name}
                                </LocalizedLink>
                            ) : name
                        }
                    </Breadcrumb.Item>
                )
            }
        </CustomBreadcrumb>
    )
}

export default BreadCrumb
