import {
    defaultLang,
    getImage,
} from 'helpers'

import { Helmet } from 'react-helmet'
import React from 'react'
import { useLanguage } from 'hooks'
import { useLocation } from '@reach/router'

const LANGS = {
    en: 'en',
    vi: 'vi',
    // de: 'de',
    // fr: 'fr',
}

const SEO = (props: any) => {
    const location = useLocation();
    const { language } = useLanguage();
    const url = `${process.env.GATSBY_URL}${location.pathname}`

    const { title, description, keywords = [], image = '' } = props
    const fullImageUrl: string = image
        && image.includes('http') ? image : getImage(image)

    return (
        <Helmet
            title={title}
            defer={false}
        >
            <html lang={language} />
            <title>{title}</title>
            <link
                rel='canonical'
                href={url}
            />
            <link
                key={language}
                rel='alternate'
                // @ts-ignore
                hreflang={language === defaultLang ? 'x-default' : language}
                href={url}
            />
            <meta
                name='title'
                content={title}
            />
            <meta
                name='description'
                content={description}
            />
            <meta
                name='keywords'
                content={keywords.join(', ')}
            />
            <meta property='og:site_name' content={url} />
            <meta property='og:locale' content={LANGS[language] || 'en'} />
            <meta property='og:url' content={url} />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={title} />
            <meta property='og:description' content={description} />
            <meta property='og:image' content={`${fullImageUrl}?width=200&height=200`} />
            <meta property='og:image:url' content={`${fullImageUrl}?width=200&height=200`} />
            <meta property='og:image:secure_url' content={`${fullImageUrl}?width=200&height=200`} />
            <meta property='og:image:alt' content={title} />
            <meta property='og:imagnge:alt' content={title} />
            <meta property='og:image:width' content='200' />
            <meta property='og:image:height' content={'200'} />
            <meta property='fb:app_id'
                content={process.env.FACEBOOK_ID || ''} />
            <script type="application/ld+json">
                {
                    JSON.stringify(
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "EDUBAO",
                            "potentialAction": "Study abroad",
                            "legalName": "EDUBAO GmbH",
                            "additionalType": "http://www.productontology.org/doc/International_student",
                            "numberOfEmployees": 50,
                            "url": "https://www.edubao.org",
                            "logo": "https://edubao.org/logo.png",
                            "description": "EDUBAO is the worldwide leader in finance- and insurance solutions for students and professionals coming to Germany. We provide fully digital solutions for the leaders of tomorrow.",
                            "foundingDate": "2018-07-13",
                            "foundingLocation": "Berlin",
                            "founders": [
                                "Nga Le",
                            ],
                        }
                    )
                }
            </script>
        </Helmet>
    )
}

export default SEO
