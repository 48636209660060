import styled from 'styled-components'
import { color as themeColor } from 'themes'
import { ThreeDots } from 'utils'

export const Container = styled.div<{ background?: string }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 75%;

  border-radius: 16px;
  padding: 16px;
  background: ${({ background }) => background || themeColor.white};
  box-shadow: 0px 0px 16px #0000000b;
`

export const SmallTitle = styled.div`
  text-align: left;
  font-weight: bold;
  font-size: 8px;
  line-height: 11px;
  text-transform: capitalize;
  color: ${themeColor.mediumSpringGreen};

  margin-bottom: 8px;
`

export const HeadLine = styled.h1`
    ${ThreeDots}
  font-weight: bold;
  font-size: 21px;
  line-height: 32px;
  color: ${({ color }) => color || themeColor.mainBlue};
`

export const Description = styled.div`
  font-weight: normal;
  font-size: 1em;
  line-height: 1.5em;
  color: ${({ color }) => color || themeColor.fontBlue};
  flex: 1;
`
