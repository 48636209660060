import styled from 'styled-components'
import { color } from 'themes'

export const Wrapper = styled.section`
    margin: 80px 0;
`

export const Container = styled.div`
    max-width: 1008px;
    margin: 0 auto;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    color: ${color.mainBlue};
    margin-bottom: 40px;
    text-align: center;
`

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const CardItem = styled.div`
    width: 336px;
    padding: 12px;
`