import React from 'react'
import { ResponsiveLayout } from 'components'
import Desktop from './Desktop'
import Mobile from './Mobile'

const PartnersList = (info: IPartnersList) => (
    <ResponsiveLayout
        mobileView={<Mobile {...info} />}
        desktopView={<Desktop {...info} />}
    />
)

export default PartnersList
