import React, { useState } from 'react';
import { Image, Markdown } from 'components';
import {
    Wrapper,
    Container,
    CategoryWrapper,
    CategoryList,
    CategoryItem,
    CategoryName,
    CategorySubList,
    CategorySub,
    SocialNetwork,
    SocialNetworkHeadline,
    SocialNetworkList,
    SocialNetworkItem,
    Policy,
    PolicyList,
    PolicyItem,
    HelpCenter,
    HelpCenterHeadline,
    HelpCenterButton,
    Copyright,
    CopyrightLogo,
    CopyrightContent,
    CopyrightHeadline,
    CopyrightSubHeadline,
    ArrowIcon,
} from './style';

const Desktop = ({ 
    categories, 
    policies, 
    socialNetworksHeadline, 
    socialNetworks, 
    helpCenterHeadline, 
    helpCenterButtonText, 
    helpCenterButtonLink, 
    copyrightLogo, 
    copyrightHeadline, 
    copyrightSubHeadline
}: IFooter) => {
    const [collapse, setCollapse] = useState(-1);

    return(
        <Wrapper>
            <Container>
                <CategoryWrapper>
                    <SocialNetwork>
                        <SocialNetworkHeadline>{socialNetworksHeadline}</SocialNetworkHeadline>
                        <SocialNetworkList>
                            {
                                socialNetworks.map(({icon, name, link}: ISocialNetwork, key: number) => (
                                    <SocialNetworkItem to={link} key={key}>
                                        <Image src={icon} alt={name} style={{width: '100%'}} />
                                    </SocialNetworkItem>
                                ))
                            }
                        </SocialNetworkList>
                    </SocialNetwork>
                    <HelpCenter>
                        <HelpCenterHeadline>{helpCenterHeadline}</HelpCenterHeadline>
                        <HelpCenterButton to={helpCenterButtonLink}>{helpCenterButtonText}</HelpCenterButton>
                    </HelpCenter>
                    <CategoryList>
                        {
                            categories.map(({category, subCategories}: ICategory, index: number) => (
                                <CategoryItem key={index} show={collapse === index}>
                                    <CategoryName onClick={() => setCollapse((prevState) => prevState === index ? -1 : index)}>
                                        {category}
                                        <ArrowIcon />
                                    </CategoryName>
                                    <CategorySubList>
                                        {
                                            subCategories.map(({subCategoryName, subCategoryLink}: ISubCategory, key: number) => (
                                                <CategorySub to={subCategoryLink} key={key}>{subCategoryName}</CategorySub>
                                            ))
                                        }
                                    </CategorySubList>
                                </CategoryItem>
                            ))
                        }
                    </CategoryList>
                </CategoryWrapper>
                <Policy>
                    <PolicyList>
                        {
                            policies.map(({name, link}: IPolicy, key: number) => (
                                <PolicyItem to={link} key={key}>{name}</PolicyItem>
                            ))
                        }
                    </PolicyList>
                </Policy>
                <Copyright>
                    <CopyrightLogo to={'/'}>
                        <Image src={copyrightLogo} alt="Edubao" style={{width: 124}} />
                    </CopyrightLogo>
                    <CopyrightContent>
                        <CopyrightHeadline>{copyrightHeadline}</CopyrightHeadline>
                        <CopyrightSubHeadline>
                            <Markdown source={copyrightSubHeadline} />
                        </CopyrightSubHeadline>
                    </CopyrightContent>
                </Copyright>
            </Container>
        </Wrapper>
    )
}

export default Desktop;