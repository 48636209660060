import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section``;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 578px;
    margin: 0 auto;
`;

export const Headline = styled.div`
    margin: 40px 0;
    font-weight: bold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: ${color.mainBlue};
`;

export const SubHeadline = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${color.fontBlue};
    max-width: 448px;
    margin: 0 auto;
`;
