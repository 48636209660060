import React from 'react'
import styled from 'styled-components'
import { color } from 'themes'
import { LocalizedLink, OutboundLink } from 'components'

export const Wrapper = styled.section`
    background: ${color.iceBlue};
    width: 100%;
    justify-content: center;
    display: flex;
`
export const Container = styled.div`
    max-width: 1200px;
    justify-content: space-between;
    display: flex;
`

export const ContentWrapper = styled.div`
    padding: 54px 0;
`

export const Content = styled.div`
    width: 592px;
`
export const Headline = styled.div`
    color: ${color.mainBlue};
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
`

export const SubHeadline = styled.div`
    color: ${color.mainBlue};
    line-height: 26px;
    width: 532px;
    margin-top: 24px;
    font-size: 16px;
`

export const MediaWrapper = styled.div`
    width: 520px;
    display: flex;
    align-items: center;
`

const ButtonStyles = `
    display: inline-flex;
    padding: 9px 52px;
    border: 2px solid ${color.mainBlue};
    border-radius: 16px;
    color: ${color.mainBlue};
    font-weight: bold;
    font-size: 20px;
    line-height: 30px;
    background: ${color.white};
    cursor: pointer;
    outline: none;
    margin-top: 24px;
    transition: all .3s;
    &:hover {
        background: ${color.mainBlue};
        color: ${color.white};
    }
`

export const Button = styled((props: any) => <LocalizedLink {...props} />)`
    ${ButtonStyles}
`

export const ButtonOutboundLink = styled((props: any) => <OutboundLink {...props} />)`
    ${ButtonStyles}
`
