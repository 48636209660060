import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <g fill="#0d507a" stroke="#0d507a">
          <path d="M16 28a12 12 0 1112-12 12.013 12.013 0 01-12 12zm0-22.5A10.5 10.5 0 1026.5 16 10.512 10.512 0 0016 5.5z" />
          <path d="M20.245 21.45a.619.619 0 01-.441-.183l-4.37-4.37a.625.625 0 01-.183-.441v-5.832a.625.625 0 011.249 0v5.568l4.187 4.187a.625.625 0 01-.441 1.066z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
