import styled from 'styled-components'
import { color } from 'themes'

export const Wrapper = styled.section`
    width: 100%;
`

export const Container = styled.div`
    padding: 40px 16px;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: ${color.mainBlue};
    margin-bottom: 20px;
    text-align: center;
`

export const CardList = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const CardItem = styled.div`
    width: 100%;
    margin: 12px 0;
`