export const defaultLang = 'en'
export const langCodes = [
    'vi',
    // 'de',
    // 'fr',
]

export const langMap = {
    'vi': 'vi',
    // 'de': 'de',
    // 'fr': 'fr',
}

export function isDefaultLang(locale: string) {
    return locale === defaultLang
}

export function localizedPath(locale: string, path: string) {
    if (isDefaultLang(locale)) {
        return path
    }

    if(!path){
        return '/'
    }

    const [, base] = path.split(`/`)
    if (base === locale) {
        return path
    }

    // prefix with the locale
    return `/${locale}${path}`
}

export function getLocaleAndBasePath(path: string, codes = langCodes) {
    try {
        const [, code, ...rest] = path.split('/')
        if (codes.includes(code)) {
            return { locale: code, basePath: `/${rest.join('/')}` }
        }

        return { locale: defaultLang, basePath: path }
    } catch (error) {
        return { locale: defaultLang, basePath: path }
    }
}
