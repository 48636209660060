import React from 'react';
import styled from 'styled-components';
import { color } from 'themes';
import { LocalizedLink, OutboundLink } from 'components';

export const Wrapper = styled.section`
    background: ${color.mainBlue};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.160784);
`

export const Container = styled.div`
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 0;
`

export const CategoryWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
`

export const CategoryList = styled.div`
    display: flex;
    max-width: 520px;
    width: 100%;
    justify-content: space-between;
`

export const CategoryItem = styled.div``

export const CategoryName = styled.div`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
    color: ${color.white};
`

export const CategorySubList = styled.div`
    display: flex;
    flex-direction: column;
`

export const CategorySub = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    font-weight: normal;
    font-size: 11.5px;
    line-height: 18px;
    color: ${color.white};
`

export const SocialNetwork = styled.div`
    max-width: 200px;
    width: 100%;
`

export const SocialNetworkHeadline = styled.h3`
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 16px;
    color: ${color.white};
    text-transform: uppercase;
`

export const SocialNetworkList = styled.div`
    display: flex;
    margin: -12px;
`

export const SocialNetworkItem = styled((props: any) => <OutboundLink target='_blank' {...props} />)`
    display: block;
    width: 32px;
    height: 32px;
    margin: 12px;
    border-radius: 4px;
    cursor: pointer;
`

export const Policy = styled.div`
    display: flex;
    align-items: center;
    padding: 18px 0;
    border-top: 0.5px solid ${color.white};
    border-bottom: 0.5px solid ${color.white};
    justify-content: space-between;
    margin-bottom: 24px;
`

export const PolicyList = styled.div`
    display: flex;
    align-items: center;
    height: 20px;
`

export const PolicyItem = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    color: ${color.white};
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border-right: 1px solid ${color.white};
    padding: 0 16px;
    &:first-child {
        padding-right: 16px;
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: none;
    }
`

export const HelpCenter = styled.div`
    display: flex;
    align-items: center;
`

export const HelpCenterHeadline = styled.h3`
    margin-bottom: 0;
    color: ${color.white};
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-right: 24px;
`

export const HelpCenterButton = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    border: 1px solid ${color.white};
    color: ${color.white};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    padding: 8px 12px;
    &:hover {
        background: ${color.white};
        color: ${color.mainBlue};
    }
`

export const Copyright = styled.div`
    display: flex;
`

export const CopyrightLogo = styled((props: any) => <LocalizedLink {...props} />)`
    display: block;
    width: 125px;
    margin-right: 55px;
`

export const CopyrightContent = styled.div``

export const CopyrightHeadline = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${color.white};
    margin-bottom: 12px;
`

export const CopyrightSubHeadline = styled.div`
    color: ${color.white};
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    p {
        margin: 0;
    }
`

