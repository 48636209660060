import React from 'react'
import styled from 'styled-components'
import { color as themeColor } from 'themes'
import Select from 'react-select'
// tslint:disable-next-line: no-submodule-imports
import makeAnimated from 'react-select/animated'

const animatedComponents = makeAnimated()

const formatOptionLabelWithIcon = ({ label }: any) => (
    <StyledOption>
        <IconContainer />
        <LabelContainer>
            <span>{label}</span>
        </LabelContainer>
    </StyledOption>
)

const formatOptionLabel = ({ label }: any) => (
    <StyledOption>
        <LabelContainer>
            <span>{label}</span>
        </LabelContainer>
    </StyledOption>
)

const dot = (color = themeColor.mainBlue) => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
        backgroundColor: color,
        borderRadius: '50%',
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 24,
        width: 24,
    },
})

const customStyles = ({ withPrefixIcon = false }: any) => ({
    menu: (provided: any, state: any) => ({
        ...provided,
        flexGrow: 1,
        borderBottom: 'none',
        color: themeColor.background,
        padding: 0,
        borderRadius: 8,
        marginTop: 12,
        boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.12)`,
        // border: `1px ridge ${themeColor.mainBlue}`,
        zIndex: 9999
    }),
    menuList: (provided: any, state: any) => ({
        ...provided,
        borderRadius: 8,
        paddingBottom: 0,
        paddingTop: 0,
    }),
    option: (styles: any, {
        data,
        isDisabled,
        isFocused,
        isSelected
    }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        color: themeColor.mainBlue,
        cursor: isDisabled ? 'not-allowed' : 'default',
        opacity: 0.5,
        ':hover': {
            backgroundColor: themeColor.lightGrey,
            opacity: 1,
        }
    }),
    control: (styles: React.CSSProperties) => ({
        ...styles,
        borderRadius: 16,
        display: 'flex',
        flexGrow: 1,
        border: 'none',
        ':focus': {
            border: 'none',
            boxShadow: 'none'
        },
        ':hover': {
            border: 'none',
            boxShadow: 'none'
        }
    }),
    input: (styles: React.CSSProperties, state: any) => {
        return withPrefixIcon ? ({ ...styles, ...dot() }) : ({ ...styles })
    },
    placeholder: (styles: React.CSSProperties, state: any) => {
        let customPlaceholderStyles = { ...styles }
        if (withPrefixIcon) {
            customPlaceholderStyles = { ...customPlaceholderStyles, ...dot() }
        }
        return ({
            ...customPlaceholderStyles,
            fontSize: '16px'
        })
    },
    singleValue: (styles: any) => ({
        ...styles,
        fontWeight: 'bold'
    }),
    // multiValue: (styles: any) => ({ ...styles }),
    multiValue: (styles: any, { data }: any) => ({
        ...styles,
        backgroundColor: `transparent`,
        borderRadius: 8,
        border: `1px ridge ${themeColor.mainBlue}`,
        fontWeight: 'bold'
    }),
    multiValueLabel: (styles: any, { data }: any) => ({
        ...styles,
        color: themeColor.fontBlue
    }),
    // The remove button
    multiValueRemove: (styles: any, { data }: any) => ({
        ...styles,
        color: themeColor.mainBlue,
        ':hover': {
            // backgroundColor: data.color,
            // color: 'white'
        },
        paddingRight: 0,
        backgroundColor: 'transparent',
        margin: '4px 4px 4px 0',
        // padding: '0'
        // width: 8,
        // height: 8
        '> svg': {
            width: 20,
            height: 20
        }
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        transition: 'all .2s',
        '> svg': {
            color: themeColor.mainBlue,
            width: 30,
            height: 30
        }
    }),
    indicatorSeparator: () => null
})

export const StyledDropdown = styled((props: any) => {
    return (
        <Select
            components={animatedComponents}
            {...props}
            styles={customStyles(props)}
            formatOptionLabel={
                props.withPrefixIcon
                    ? formatOptionLabelWithIcon
                    : formatOptionLabel
            }
        />
    )
})`
  && {
    display: flex;
    flex-grow: 1;
    border-radius: 16px;
    // border: 1px ridge transparent;
    box-sizing: border-box;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.12);
    min-height: 48px;
    background: ${themeColor.white};

    &:hover {
        // border-color: ${themeColor.mainBlue};
    }

    &:focus {
        outline: none;
    }
  }
`

export const StyledOption = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
`

export const IconContainer = styled.div`
  display: flex;
  height: 24px;
  width: 24px;
  background: ${themeColor.mainBlue};
  border-radius: 50%;

  margin-right: 16px;
`

export const LabelContainer = styled.div`
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: ${themeColor.fontBlue};
  font-weight: normal;
`
