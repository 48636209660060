import React from 'react';
import { Wrapper, Container, Headline, SubHeadline } from './style';
import { Markdown } from 'components';

const Component = ({ headline, subHeadline, backgroundImage }: IJumbotron) => {
    return (
        <Wrapper
            style={{
                background: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 624,
            }}
        >
            <Container>
                <Headline>{headline}</Headline>
                <SubHeadline>
                    <Markdown source={subHeadline} />
                </SubHeadline>
            </Container>
        </Wrapper>
    );
};

export default Component;
