import React from 'react'
import {color as colors} from 'themes'
import {IconWrapper} from './style'

const Checkmark = ({color = colors.fontBlue, ...rest}: any) => (
  <IconWrapper viewBox='0 0 14.828 10.414' {...rest}>
    <path
      id='Pfad_336'
      data-name='Pfad 336'
      d='M5623,15396l4,4,8-8'
      transform='translate(-5621.586 -15390.586)'
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </IconWrapper>
)

export default Checkmark
