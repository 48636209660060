import React from 'react'
import {
  Wrapper,
  Button,
  ButtonOutboundLink,
  Content,
  ContentWrapper,
  Headline, MediaWrapper,
  SubHeadline,
} from './style'
import { Image } from 'components'

const Mobile = (props: IBanner) => {
    const {headline, subHeadline, image, buttonText, buttonLink, buttonExternalLink} = props;
  return (
        <Wrapper>
            <ContentWrapper>
                <Content>
                    <Headline>{headline}</Headline>
                    <SubHeadline>{subHeadline}</SubHeadline>
                    {
                        buttonText && buttonExternalLink ? <ButtonOutboundLink to={buttonLink} target='_blank'>{buttonText}</ButtonOutboundLink>
                        : <Button to={buttonLink}>{buttonText}</Button>
                    }
                </Content>
            </ContentWrapper>
            <MediaWrapper>
                <Image alt={headline}
                    src={image}
                    style={{
                        width: "100%",
                        height: 224
                    }}
                />
            </MediaWrapper>
        </Wrapper>
  )
}
export default Mobile
