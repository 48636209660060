import styled from 'styled-components';
import { color } from 'themes';

export const Wrapper = styled.section``;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
`;

export const Headline = styled.div`
    margin: 20px 0;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: ${color.mainBlue};
`;

export const SubHeadline = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${color.fontBlue};
`;
