import React from 'react'
import {LocalizedLink, Icons} from 'components'
import {Link} from 'gatsby'
import styled, {css} from 'styled-components'
import {color, spacing, boxShadow} from 'themes'

const { DownArrow, Language } = Icons

export const Wrapper = styled.header``

export const Header = styled.div`
  background-color: ${color.mainBlue};
  padding: 0 calc(${spacing.mobile.bodyPadding} - 8px);
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &&::-webkit-scrollbar {
    display: none;
  }
`

export const HeaderItem = styled((props: any) => <LocalizedLink {...props} />)`
  color: ${color.white};
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  line-height: 14px;
  margin: 0 8px;
`

export const Bar = styled.div`
  padding: ${spacing.mobile.bodyPadding};
  position: relative;
  &.menuHidden {
    overflow: hidden;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled((props: any) => <LocalizedLink {...props} />)`
  width: 94px;
  height: 24px;
  background: transparent url('${props => props.src}') 0% 0% no-repeat padding-box;
`

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${color.white};
  margin-top: 16px;
  right: 0;
  padding: 16px;
  transition: all .3s;
  box-shadow: ${boxShadow.card};
  &.hidden {
    right: -100%;
  }
`

export const Groups = styled.div``

export const Group = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const GroupTitle = styled.div`
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  color: ${color.mainBlue};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const Item = styled((props: any) => <LocalizedLink {...props} />)`
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
`

export const Languages = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  margin: 48px auto;
  color: ${color.mainBlue};
`

export const StyledLink = styled((props: any) => <Link {...props} />)`
    text-align: center;
    font-size: 12px;
    line-height: 26px;
    display: inline-flex;
    margin-top: 8px;
`

export const Country = styled.span`
  cursor: pointer;
  &&:hover {
    text-decoration: underline;
  }
`

export const Slash = styled.span`
  margin: 0 16px;
`

export const Underline = styled.span`
  text-decoration: underline;
`

export const TopNav = styled.div`
  background: ${color.mainBlue};
`

export const TopNavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const TopNavItem = styled.div`
  padding: 8px 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${color.white};
`

export const MenuIcon = styled.div`
  display: inline-block;
  cursor: pointer;
`
const BarStyles = `
  width: 16px;
  height: 2px;
  background-color: ${color.mainBlue};
  margin: 4px 0;
  transition: 0.4s;
`
export const MenuIconFirstBar = styled.div`
  ${BarStyles}
  &.change {
    transform: rotate(-45deg) translate(-4px, 4px);
  }
`

export const MenuIconSecondBar = styled.div`
  ${BarStyles}
  &.change {
    opacity: 0;
  }
`

export const MenuIconThirdBar = styled.div`
  ${BarStyles}
  &.change {
    transform: rotate(45deg) translate(-4px, -4px);
  }
`

export const Arrow = styled((props: any) => <DownArrow width={24} height={24} {...props} />)`
  margin-left: 5px;
  transition: all .3s;
  ${props => props.dataCollapse && css`
    transform: rotate(180deg);
  `
  }
`

export const LanguageIcon = styled((props: any) => <Language {...props} />)`
  margin-right: 10px;
`