import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 14.667A6.667 6.667 0 118 1.333a6.667 6.667 0 010 13.334zM7.333 10v1.333h1.333V10H7.333zm0-5.333v4h1.333v-4H7.333z"
        fill="#0976BB"
      />
    </svg>
  )
}

export default SvgComponent
