import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M7.267.4l6.6.943.942 6.6-6.128 6.128a.667.667 0 01-.942 0l-6.6-6.6a.667.667 0 010-.942L7.267.399zm.472 1.415L2.553 7l5.657 5.657 5.185-5.186-.707-4.95-4.95-.706zm1.413 4.242a1.334 1.334 0 111.886-1.886 1.334 1.334 0 01-1.886 1.886z"
        fill="#fff"
      />
    </svg>
  )
}

export default SvgComponent
