import React, { useState } from 'react';
import { Tooltip } from 'antd';
import {
    Wrapper,
    Container,
    Headline,
    TableWrapper,
    Table,
    TableRow,
    TableHeader,
    TableHeaderItem,
    TableBody,
    TableData,
    Arrow,
    WarningIcon,
    TooltipWrapper,
    IconTooltipsList,
    TextTooltipsList,
    IconTooltipsItem,
    IconTooltip,
    IconTooltipText,
    TextTooltipItem,
    ColGroup,
    Col,
    TableDataHeadline,
} from './style';
import { Image, Markdown } from 'components';

const Component = ({ headline, tableHeaders, tableContents, iconTooltips, tooltips}: ITable) => {
    const [collapse, setCollapse] = useState(-1);
    const totalWidth = tableHeaders.reduce((total, header) => total + parseInt(header.width), 0)

    return(
        <Wrapper>
            <Container>
                <Headline>{headline}</Headline>
                <TableWrapper>
                <Table>
                    <ColGroup>
                        {tableHeaders.map(({width}, key: number) => (
                            <Col key={key} span={1} style={{width: `${parseInt(width)/totalWidth * 100}%`}} />
                        ))}
                    </ColGroup>
                    <TableHeader>
                        <TableRow>
                            {tableHeaders.map(({name}, key: number) => (
                                <TableHeaderItem key={key}>{name}</TableHeaderItem>
                            ))}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {tableContents.map(({headline, icon, tooltip, rows, plans}: any, i: number) => (
                            <React.Fragment key={i}>
                                <TableRow>
                                    <TableData headline 
                                        onClick={() => collapse === i ? setCollapse(-1) : setCollapse(i)}
                                    >
                                        <TableDataHeadline>
                                            <Image 
                                                src={icon} 
                                                alt={headline} 
                                                style={{width: 24, height: 24}} 
                                            /> 
                                            <div style={{
                                                height: '100%', 
                                                display: 'inline-flex', 
                                                alignItems: 'center', 
                                                marginLeft: 12, 
                                                flex: 1
                                            }}>
                                                {headline} {tooltip && <Tooltip placement="bottom" title={tooltip}><WarningIcon /></Tooltip>}
                                            </div>
                                            <Arrow collapse={collapse === i}/>
                                        </TableDataHeadline>
                                    </TableData>
                                    {plans.map(({content, colSpan}: any, key: number) => (
                                        <TableData colSpan={colSpan} key={key}>
                                            <Markdown source={content} />
                                        </TableData>
                                    ))}
                                </TableRow>
                                {collapse === i && rows.map(({columns}: any) => (
                                    <TableRow type={"content"} collapse={collapse === i}>
                                        {columns.map(({content, colSpan}: any, key: number) => (
                                            <TableData colSpan={colSpan} key={key}>
                                                <Markdown source={content} />
                                            </TableData>
                                        ))}
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
                </TableWrapper>
                <TooltipWrapper>
                    {
                        iconTooltips.length && 
                        <IconTooltipsList>
                            {iconTooltips.map(({icon, tooltip}: any) => (
                                <IconTooltipsItem>
                                    <IconTooltip>
                                        <Image src={icon} alt={tooltip} /> 
                                    </IconTooltip>
                                    <IconTooltipText>{tooltip}</IconTooltipText>
                                </IconTooltipsItem>
                            ))}
                        </IconTooltipsList>
                    }
                    {
                        tooltips.length && 
                        <TextTooltipsList>
                            {tooltips.map((tooltip: any) => (
                                <TextTooltipItem>
                                    <Markdown source={tooltip} />
                                </TextTooltipItem>
                            ))}
                        </TextTooltipsList>
                    }
                </TooltipWrapper>
            </Container>
        </Wrapper>
    )
}

export default Component;