import React from 'react'
import styled, { css } from 'styled-components'
import { color } from 'themes'
import { LocalizedLink, OutboundLink } from 'components'

export const Wrapper = styled.section`
  background-color: ${color.background};
  padding: 80px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 32px;
  line-height: 48px;
  font-weight: bold;
  color: ${color.mainBlue};
  margin-bottom: 16px;
`

export const Description = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${color.fontBlue};
  max-width: 592px;
  margin-bottom: 24px;
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1008px;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`

export const Text = styled.span`
  flex: 1 1;
`

export const Row = styled.div<{reverse?: boolean}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
  ${Text}{
    padding-right: 16px;
  }
  ${props => props.reverse && css`
    flex-direction: row-reverse;
    ${Text}{
      padding-left: 16px;
      padding-right: 0;
    }
  `}
`

export const ImageWrapper = styled.div`
  flex: 0 0 50%;
  display: flex;
  justify-content: flex-end;
`

const ButtonStyles = `
  display: inline-flex;
  background: ${color.mediumSpringGreen};
  padding: 12px 15px;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${color.white};
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0431373);
  border-radius: 16px;
  min-width: 200px;
  justify-content: center;
`

export const Button = styled((props: any) => <LocalizedLink {...props} />)`
  ${ButtonStyles}
`

export const ButtonOutboundLink = styled((props: any) => <OutboundLink {...props} />)`
  ${ButtonStyles}
`
