import * as React from "react"

function SvgComponent(props : any) {
  return (
    <svg width={36} height={36} viewBox="0 0 36 36" fill="none" {...props}>
      <path
        d="M35 18c0 9.389-7.611 17-17 17S1 27.389 1 18 8.611 1 18 1s17 7.611 17 17z"
        stroke="#0D507A"
        strokeWidth={2}
      />
      <path
        d="M8.54 22.066a.722.722 0 01-.517-.187c-.125-.132-.187-.308-.187-.528v-6.435c0-.213.059-.378.176-.495.125-.117.293-.176.506-.176h2.706c.829 0 1.474.209 1.936.627.462.41.693.99.693 1.738s-.231 1.331-.693 1.749c-.462.418-1.107.627-1.936.627H9.255v2.365c0 .22-.066.396-.198.528-.125.125-.297.187-.517.187zm2.508-4.169c.975 0 1.463-.425 1.463-1.276 0-.85-.488-1.276-1.463-1.276H9.255v2.552h1.793zM15.78 22c-.213 0-.382-.059-.506-.176-.118-.117-.176-.282-.176-.495v-6.413c0-.213.058-.378.176-.495.124-.117.293-.176.506-.176h2.178c1.268 0 2.251.341 2.948 1.023.704.675 1.056 1.624 1.056 2.849 0 1.232-.352 2.189-1.056 2.871-.697.675-1.68 1.012-2.948 1.012H15.78zm2.09-1.155c1.782 0 2.673-.91 2.673-2.728 0-1.811-.891-2.717-2.673-2.717h-1.353v5.445h1.353zm6.225 1.221a.7.7 0 01-.517-.198.722.722 0 01-.187-.517v-6.435c0-.213.058-.378.176-.495.124-.117.293-.176.506-.176h3.86c.448 0 .672.19.672.572 0 .374-.224.561-.671.561h-3.146v2.112h2.926c.447 0 .67.19.67.572 0 .374-.223.561-.67.561h-2.926v2.728a.722.722 0 01-.187.517.663.663 0 01-.506.198z"
        fill="#0D507A"
      />
    </svg>
  )
}

export default SvgComponent
