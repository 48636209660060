export const hasWindow = typeof window !== 'undefined' && window

export const isUnsupported =
    hasWindow &&
    navigator !== undefined &&
    (/MSIE 9/i.test(navigator.userAgent) ||
        /MSIE 10/i.test(navigator.userAgent) ||
        /Trident/i.test(navigator.userAgent))

export const isDebug = hasWindow && process.env.NODE_ENV === 'development'
