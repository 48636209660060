import { MARKDOWNTYPES } from 'variables'

// Check markdown string is special or not
// Ex: image(3102-en.png Application procedure to Studienkolleg)
export const isSpecialMarkdown = (markdownString: string): boolean => {
    const parsedMD: string[] =
        markdownString.split(new RegExp('[\u0028\u0029]')) || []

    // Get markdown type
    // Ex: "image"
    const markdownType: string = parsedMD.shift() || ''
    if (MARKDOWNTYPES[markdownType.toLowerCase()]) {
        return true
    }

    return false
}

export const getMarkdownType = (markdown: string) => {
    // splitting at ( and )
    // Ex: image(3102-en.png Application procedure to Studienkolleg) =>
    // ["image", "3102-en.png Application procedure to Studienkolleg", ""]
    const parsedMD: string[] =
        markdown.split(new RegExp('[\u0028\u0029]')) || []

    // Get markdown type
    // Ex: "image"
    const markdownType: string = parsedMD.shift() || ''

    return { markdownType, parsedMD }
}

export const getMarkdownQueryInfo = (parsedMD: string[]) => {
    return parsedMD
        .filter((e) => e !== '') // Remove empty string in the array
        .map((e) => { // 3102-en.png Application procedure to Studienkolleg
            if (e.includes(' ')) {
                const eSplit: string[] = e.split(' ')
                const path: string = eSplit.shift() || ''
                const name = eSplit.join(' ')
                return { path, name }
            } else {
                return { path: e, name: '' }
            }
        })
}
