import React from 'react'
import PropTypes from 'prop-types'

const OutboundLink = ({ to, target, children, ...rest }: any) => (
    <a href={to} target={target} {...rest}>
        {children}
    </a>
)

OutboundLink.propTypes = {
    to: PropTypes.string.isRequired,
    target: PropTypes.string,
    children: PropTypes.any.isRequired,
}

OutboundLink.defaultProps = {
    target: '_blank',
}

export default OutboundLink
