import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

import { boxShadow, typography, color, size, spacing, radius } from 'themes'
import { dimension } from 'utils'

const { fontSize, lineHeight } = typography

const setStyle = (props: any): string => {
    const { width } = props
    const platform = dimension.getName(width)

    return `
        box-shadow: ${boxShadow.input};
        font-size: ${fontSize[platform].actionText};
        line-height: ${lineHeight[platform].actionText};
        height: ${size[platform].input};
        padding: ${spacing[platform].inputPadding};
        border-radius: ${radius.border};
    `
}

export const Wrapper = styled((props: any) => <Input {...props} />)`
    border-color: transparent;
    && {
        ${setStyle}
            &&::placeholder {
            color: ${color.darkGrey};
        }

        &:hover {
            border-color: ${color.mainBlue};
        }

        border: ${(props: any) => props.noborder ? 'none' : 'solid'}
    }
`
