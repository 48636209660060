import * as React from "react"

function DownArrow(props: any) {
    return (
        <svg width={ props.width || 32} height={ props.height || 32} viewBox="0 0 32 32" {...props}>
            <switch>
                <g>
                    <path
                        fill="none"
                        stroke={props.color || '#0D507A'}
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        d="M8 12.2l7.9 7.8 8.1-8"
                    />
                </g>
            </switch>
        </svg>
    )
}

export default DownArrow
