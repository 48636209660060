import React, {
    createContext,
    useContext,
    useState,
    useLayoutEffect
} from 'react'
import {
    defaultLang,
} from 'helpers'

// tslint:disable-next-line: max-line-length
// https://medium.com/better-programming/react-context-hooks-part-2-ui-language-switch-f4610a21379b
export const LanguageContext = createContext({
    language: 'en',
    // tslint:disable-next-line: no-empty
    switchLang: (newLang: string) => { },
})

export const LanguageProvider = (props: any) => {
    const [language, setLanguage] = useState<string>(props.locale)
    const switchLang = (newLanguage: string) => {
        if (newLanguage !== language) {
            setLanguage(newLanguage)
            window.localStorage.setItem('language', newLanguage)
        }
    }

    const languageInitialize = (lang: string = defaultLang) => {
        // upload web's language
        setLanguage(lang)

        // update preferred user's language
        window.localStorage.setItem('language', lang)
    }

    useLayoutEffect(() => {
        const preferedLang = props.locale
            || window.localStorage.getItem('language')
            || window.navigator.language.slice(0, 2)

        languageInitialize(preferedLang)
    }, [props.locale])

    // Make sure we detected user's language already
    if (!language) {
        return <div />
    }

    return (
        <LanguageContext.Provider
            value={{
                language,
                switchLang,
            }}
        >
            {props.children}
        </LanguageContext.Provider>
    )
}

// used by: const {language, switchLang, languageData} = useLanguage()
export const useLanguage = () => useContext(LanguageContext)
