import React from 'react';
import { graphql, useStaticQuery } from 'gatsby'
import { Footer } from 'components';
import { get } from 'lodash';
import { getLocaleAndBasePath, getImage } from 'helpers';
import { useLocation } from '@reach/router'

const PageContainer = (props: any) => {
    const location = useLocation();
    const { locale } = getLocaleAndBasePath(get(location, 'pathname'))
    const { cms }: any = useStaticQuery(graphql`
        query {
          cms {
            queryFooterContents {
              data {
                categories {
                  iv {
                    data {
                      category {
                        de
                        en
                        fr
                        vi
                      }
                      subCategories {
                        iv {
                          data {
                            subCategoryName {
                              de
                              en
                              fr
                              vi
                            }
                            subCategoryLink {
                              iv
                            }
                          }
                        }
                      }
                    }
                  }
                }
                socialNetworksHeadline {
                  de
                  en
                  fr
                  vi
                }
                socialNetworks {
                  iv {
                    name
                    icon {
                      id
                      fileName
                    }
                    link
                  }
                }
                policies {
                  de {
                    link
                    name
                  }
                  en {
                    link
                    name
                  }
                  fr {
                    link
                    name
                  }
                  vi {
                    link
                    name
                  }
                }
                helpCenterHeadline {
                  de
                  en
                  fr
                  vi
                }
                helpCenterButtonText {
                  de
                  en
                  fr
                  vi
                }
                helpCenterButtonLink {
                  iv
                }
                copyrightLogo {
                  iv {
                    id
                    fileName
                  }
                }
                copyrightHeadline {
                  de
                  en
                  fr
                  vi
                }
                copyrightSubHeadline {
                  de
                  en
                  fr
                  vi
                }
              }
            }
          }
        }  
    `)

    const queryFooterContents = get(cms, 'queryFooterContents[0].data', {})
    // const queryMenuPrimaryContents = get(cms, 'queryMenuPrimaryContents[0].data', {})
    // const queryMenuSecondaryContents = get(cms, 'queryMenuSecondaryContents[0].data', {})

    // const menuProps: IMenu = {
    //   logo: getImage(get(queryMenuPrimaryContents, `logo.iv[0].id`, '')
    //   ? `${get(queryMenuPrimaryContents, `logo.iv[0].id`)}/${get(queryMenuPrimaryContents, `logo.iv[0].fileName`)}`
    //   : ''),
    //   leftMenuList: get(queryMenuPrimaryContents, 'leftMenuList.iv', []).map(({data}: any) => ({
    //     type: get(data, 'type.iv', 'Text'),
    //     data: {
    //       text: get(data, `text[${locale}]`, ''),
    //       url: get(data, 'url.iv', '/'),
    //       externalLink: get(data, 'externalLink.iv', false),
    //       children: get(data, 'children.iv[0].childrenItem', []).map(({data}: any) => ({
    //         type: get(data, 'type.iv', 'Text'),
    //         data: {
    //           text: get(data, `text[${locale}]`, ''),
    //           url: get(data, 'url.iv', '/'),
    //           externalLink: get(data, 'externalLink.iv', false),
    //         }
    //       }))
    //     }
    //   })),
    //   rightMenuList: get(queryMenuPrimaryContents, 'rightMenuList.iv', []).map(({data}: any) => ({
    //     type: get(data, 'type.iv', 'Text'),
    //     data: {
    //       text: get(data, `text[${locale}]`, ''),
    //       url: get(data, 'url.iv', '/'),
    //       externalLink: get(data, 'externalLink.iv', false),
    //       children: get(data, 'children.iv[0].childrenItem', []).map(({data}: any) => ({
    //         type: get(data, 'type.iv', 'Text'),
    //         data: {
    //           text: get(data, `text[${locale}]`, ''),
    //           url: get(data, 'url.iv', '/'),
    //           externalLink: get(data, 'externalLink.iv', false),
    //         }
    //       }))
    //     }
    //   })),
    //   menuSecondaryList: get(queryMenuSecondaryContents, 'menuSecondaryList.iv', []).map(({data}: any) => ({
    //     type: get(data, 'type.iv', 'Text'),
    //     data: {
    //       text: get(data, `text[${locale}]`, ''),
    //       url: get(data, 'url.iv', '/'),
    //       externalLink: get(data, 'externalLink.iv', false),
    //       children: get(data, 'children.iv[0].childrenItem', []).map(({data}: any) => ({
    //         type: get(data, 'type.iv', 'Text'),
    //         data: {
    //           text: get(data, `text[${locale}]`, ''),
    //           url: get(data, 'url.iv', '/'),
    //           externalLink: get(data, 'externalLink.iv', false),
    //         }
    //       }))
    //     }
    //   }))
    // }
    
    const footerProps: IFooter = {
        categories: get(queryFooterContents, 'categories.iv', []).map(({data}: any) => ({
            category: get(data, `category[${locale}]`, ''),
            subCategories: get(data, 'subCategories.iv', []).map(({data}: any) => ({
                subCategoryName: get(data, `subCategoryName[${locale}]`, ''),
                subCategoryLink: get(data, 'subCategoryLink.iv', '/')
            }))
        })),
        socialNetworksHeadline: get(queryFooterContents, `socialNetworksHeadline[${locale}]`, ''),
        socialNetworks: get(queryFooterContents, 'socialNetworks.iv', []).map((data: any) => ({
            name: get(data, 'name', ''),
            link: get(data, 'link', ''),
            icon: getImage(get(data, `icon[0].id`, '')
            ? `${get(data, `icon[0].id`)}/${get(data, `icon[0].fileName`)}`
            : ''),
        })),
        policies: get(queryFooterContents, `policies[${locale}]`, []).map((policy: any) => ({
            name: get(policy, 'name', ''),
            link: get(policy, 'link', '/')
        })),
        helpCenterHeadline: get(queryFooterContents, `helpCenterHeadline[${locale}]`, ''),
        helpCenterButtonText: get(queryFooterContents, `helpCenterButtonText[${locale}]`, ''),
        helpCenterButtonLink: get(queryFooterContents, `helpCenterButtonLink.iv`, ''),
        copyrightLogo: getImage(get(queryFooterContents, `copyrightLogo.iv[0].id`, '')
        ? `${get(queryFooterContents, `copyrightLogo.iv[0].id`)}/${get(queryFooterContents, `copyrightLogo.iv[0].fileName`)}`
        : ''),
        copyrightHeadline: get(queryFooterContents, `copyrightHeadline[${locale}]`, ''),
        copyrightSubHeadline: get(queryFooterContents, `copyrightSubHeadline[${locale}]`, ''),
    }

    return(
        <>
              {props.children}
            <Footer {...footerProps} />
        </>
    )
}

export default PageContainer;