import styled from 'styled-components';

export const Wrapper = styled.section``

export const Container = styled.div`
    max-width: 800px;
    margin: 80px auto;
`

export const Content = styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`