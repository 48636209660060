import React from 'react'

import ResponsiveLayout from '../ResponsiveLayout'
import Desktop from './Desktop'
import Mobile from './Mobile'
import { INFO } from './const'

const TextWithPictureGroup = (props: ITextWithPictureGroup) => (
  <ResponsiveLayout
    mobileView={<Mobile {...props} />}
    desktopView={<Desktop {...props} />}
  />
)

TextWithPictureGroup.defaultProps = INFO

export default TextWithPictureGroup
