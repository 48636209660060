import * as React from "react"

function SmileFace(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M26.0002 9.41045C29.0967 14.1015 28.5972 20.3895 24.5019 24.4817C19.8072 29.1728 12.2157 29.1728 7.52103 24.4817C2.82632 19.7907 2.82632 12.2051 7.52103 7.51407C11.6164 3.42187 17.9093 2.92283 22.604 5.91711" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M11.4166 21.0882C12.9149 21.687 14.5131 22.0863 16.1113 22.0863C17.7095 22.0863 19.4076 21.687 20.806 21.0882" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.5165 15.6985C10.8173 15.6985 10.3179 15.1994 10.3179 14.5007V12.3049C10.3179 11.6063 10.8173 11.1072 11.5165 11.1072C12.2157 11.1072 12.7152 11.6063 12.7152 12.3049V14.5007C12.7152 15.1994 12.2157 15.6985 11.5165 15.6985Z" stroke="white" strokeMiterlimit="10"/>
    <path d="M20.7062 15.6985C20.0069 15.6985 19.5075 15.1994 19.5075 14.5007V12.3049C19.5075 11.6063 20.0069 11.1072 20.7062 11.1072C21.4054 11.1072 21.9048 11.6063 21.9048 12.3049V14.5007C21.9048 15.1994 21.4054 15.6985 20.7062 15.6985Z" stroke="white" strokeMiterlimit="10"/>
    <path d="M24.3021 8.31253C24.6331 8.31253 24.9014 8.04441 24.9014 7.71367C24.9014 7.38293 24.6331 7.11481 24.3021 7.11481C23.9711 7.11481 23.7028 7.38293 23.7028 7.71367C23.7028 8.04441 23.9711 8.31253 24.3021 8.31253Z" fill="white"/>
    </svg>
  )
}

export default SmileFace
