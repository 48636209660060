import React, { useEffect } from 'react'
import { ConfigProvider } from 'antd'
import { ModalProvider } from 'styled-react-modal'
import 'lazysizes'
// tslint:disable-next-line: no-submodule-imports
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import {
    WindowDimensionsProvider,
    GlobalStyle,
    BackToTop,
} from 'components'
import {
    LanguageProvider,
    TranslationProvider,
    LocationProvider,
    QueryStringProvider,
} from 'hooks'
import { ThemeProvider, themes } from '../themes/theming'
import { get } from 'lodash'
import { getLocaleAndBasePath, defaultLang } from 'helpers'
import { I18nextProvider } from 'react-i18next'
import { PageContainer } from 'containers'
import i18next from 'i18next'
import common_vi from "../translations/vi/common.json"
import common_en from "../translations/en/common.json"
import common_zh from "../translations/zh/common.json"
import common_es from "../translations/es/common.json"
import common_fr from "../translations/fr/common.json"
import common_ru from "../translations/ru/common.json"
import './index.css'
import './datepicker.css'
// tslint:disable-next-line: no-submodule-imports
import 'mapbox-gl/dist/mapbox-gl.css'

const addScript = (url: string) => {
    const script = document.createElement("script")
    script.src = url
    // script.async = true
    // https://stackoverflow.com/questions/10808109/script-tag-async-defer
    // let's say we want to execute the script
    // after html parsed and script downloaded
    script.defer = true
    document.body.appendChild(script)
}

const setCookie = (name: string, value: string, days: number) => {
    let expires = ""
    if (days) {
        const date = new Date()
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000))
        expires = " expires=" + date.toUTCString()
    }

    document.cookie = name + "=" + (value || "") + expires + " path=/"
}

const Containers = ({ children }: any) => {
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            addScript(`${process.env.GATSBY_URL}/config.js`)
            addScript(`${process.env.GATSBY_URL}/klaro.js`)

            // we set a tracking cookie as an example
            setCookie('uni project', `Hi!, it's seem you accepted our terms and conditions.`, 120)
        }
    }, [])

    return (
        <ConfigProvider>
            <PageContainer>
                {children}
            </PageContainer>
        </ConfigProvider>
    )
}

const DefaultLayout = (props: any) => {
    const { locale, basePath = '' } = getLocaleAndBasePath(get(props, 'location.pathname'))
    i18next.init({
        interpolation: { escapeValue: false },  // React already does escaping
        lng: locale,                              // language to use
        resources: {
            en: {
                common: common_en               // 'common' is our custom namespace
            },
            vi: {
                common: common_vi
            },
            es: {
                common: common_es
            },
            zh: {
                common: common_zh
            },
            fr: {
                common: common_fr
            },
            ru: {
                common: common_ru
            }
        },
    })

    return (
        <>
            <BackToTop />
            <QueryStringProvider>
                <LocationProvider
                    basePath={basePath}
                >
                    {/* <MetadataProvider> */}
                        <LanguageProvider
                            locale={locale}
                        >
                            <I18nextProvider i18n={i18next}>
                                <TranslationProvider
                                    fallbackData={
                                        get(
                                            props,
                                            `pageContext.languageData[${defaultLang}]`,
                                            []
                                        )
                                    }
                                    data={
                                        get(
                                            props,
                                            `pageContext.languageData[${locale}]`,
                                            []
                                        )
                                    }
                                >
                                    <ThemeProvider theme={themes.default}>
                                        <GlobalStyle />
                                        <WindowDimensionsProvider>
                                            <ModalProvider>
                                                <Containers {...props} />
                                            </ModalProvider>
                                        </WindowDimensionsProvider>
                                    </ThemeProvider>
                                </TranslationProvider>
                            </I18nextProvider>
                        </LanguageProvider>
                    {/* </MetadataProvider> */}
                </LocationProvider>
            </QueryStringProvider>
        </>
    )
}

export default DefaultLayout