const radius: ISpacing = {
    mobile: {
        bodyPadding: '16px',
        buttonPadding: '0 16px',
        inputPadding: '4px 16px',
        cardPadding: '16px',
        star: '8px',
        carouselArrow: '24px',
        borderRadius: '8px',
    },
    desktop: {
        bodyPadding: '32px',
        buttonPadding: '0 32px',
        inputPadding: '4px 24px',
        cardPadding: '24px',
        star: '16px',
        carouselArrow: '64px',
        borderRadius: '16px',
    },
}

export default radius
