import React from 'react';
import { Icons } from 'components';
import {
    Wrapper,
    Container,
    Headline,
    SubHeadline,
    FilesList,
    FileListItem,
    FileName,
    FileIcon,
    FileDownload,
    FileDownloadIcon
} from './style';
const { File, Download } = Icons;
const Component = ({ headline, subHeadline, documents }: IDownloadAndDocuments) => {
    return(
        <Wrapper>
            <Container>
                <Headline>{headline}</Headline>
                <SubHeadline>{subHeadline}</SubHeadline>
                <FilesList>
                    {
                        documents.map(({name, file}: IDocument, index: number) => (
                            <FileListItem key={index} to={file}>
                                <FileName>
                                    <FileIcon>
                                        <File />
                                    </FileIcon>
                                    {name}
                                </FileName>
                                <FileDownload>
                                    {"Download"}
                                    <FileDownloadIcon>
                                        <Download />
                                    </FileDownloadIcon>
                                </FileDownload>
                            </FileListItem>
                        ))
                    }
                </FilesList>
            </Container>
        </Wrapper>
    )
}

export default Component