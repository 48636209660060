import React from 'react'
import {color as colors} from 'themes'
import {IconWrapper} from './style'

const RightArrow = ({color = colors.fontBlue, ...rest}: any) => (
  <IconWrapper viewBox='0 0 9.914 17.828' {...rest}>
    <path
      id='Pfad_134'
      data-name='Pfad 134'
      d='M0,7.5,7.5,0,15,7.5'
      transform='translate(8.914 1.414) rotate(90)'
      fill='none'
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
    />
  </IconWrapper>
)

export default RightArrow
