import * as React from "react"

function SvgComponent(props : any) {
  return (
    <svg width={48} height={48} viewBox="0 0 48 48" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 6.003c-7.288-.164-13.331 5.61-13.498 12.897-.002.081-.002.162-.002.243 0 7.515 12.044 21.827 12.555 22.427a1.251 1.251 0 001.887 0c.514-.6 12.557-14.912 12.557-22.427-.11-7.353-6.149-13.23-13.5-13.14zm0 21.503a8.365 8.365 0 01-8.136-8.59 8.365 8.365 0 0116.726.23c-.07 4.677-3.913 8.419-8.59 8.36zm.103-3.865a4.5 4.5 0 114.621-4.498 4.563 4.563 0 01-4.62 4.498z"
        fill="#17A9B5"
      />
    </svg>
  )
}

export default SvgComponent
