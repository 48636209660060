import React from 'react'
import { BackTop } from 'antd'

const BackToTop = () => {
    return (
        <BackTop
            style={{
                // bottom: 100,
                right: 20,
            }}
        />
    )
}

export default BackToTop
