import React from 'react'
import {
    Container,
    USPItemWrapper,
    USPItemIcon,
    USPItemRightContent,
    USPItemHeadline,
    USPItemSubHeadline,
    Wrapper,
    Link,
    ExternalLink,
    LinkArrow
} from './style'
import { Image } from 'components'

const Desktop = ({ uSPsList = [], type = "normal" }: IUSPs) => {
    const renderUSPsListByType = (type = "normal") => {
        switch(type){
            case "normal":
                return(
                    uSPsList.map(({ icon, headline, subHeadline } : IUSPItem) => {
                        return (
                            <USPItemWrapper key={headline}>
                                <USPItemIcon>
                                    <Image src={icon} alt={headline} />
                                </USPItemIcon>
                                <USPItemRightContent>
                                    <USPItemHeadline>
                                        {headline}
                                    </USPItemHeadline>
                                    <USPItemSubHeadline>
                                        {subHeadline}
                                    </USPItemSubHeadline>
                                </USPItemRightContent>
                            </USPItemWrapper>
                        )
                    })
                );
            case "with-link":
                return(
                    uSPsList.map(({ icon, headline, subHeadline, link, linkText, externalLink } : IUSPWithLinkItem) => {
                        return (
                            <USPItemWrapper key={headline}>
                                <USPItemIcon>
                                    <Image src={icon} alt={headline} />
                                </USPItemIcon>
                                <USPItemRightContent>
                                    <USPItemHeadline>
                                        {headline}
                                    </USPItemHeadline>
                                    <USPItemSubHeadline>
                                        {subHeadline}
                                    </USPItemSubHeadline>
                                    {
                                        externalLink ? <ExternalLink href={link} target='_blank'>{linkText}<LinkArrow /></ExternalLink>
                                        : <Link to={link}>{linkText}<LinkArrow /></Link>
                                    }
                                </USPItemRightContent>
                            </USPItemWrapper>
                        )
                    })
                );
        }
    }
    return (
        <Container type={type}>
            <Wrapper>
                {renderUSPsListByType(type)}
            </Wrapper>
        </Container>
    )
}

export default Desktop
