import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 32 32`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <g transform="translate(-158.37 -173.6)" fill={props.color || "#0d507a"}>
          <ellipse
            cx={3.409}
            cy={3.982}
            rx={3.409}
            ry={3.982}
            transform="translate(170.961 182.378)"
          />
          <path d="M174.37 177.6a8.8 8.8 0 00-9 8.759c0 5.009 8.03 14.551 8.371 14.951a.835.835 0 001.258 0c.342-.4 8.371-9.942 8.371-14.951a8.892 8.892 0 00-9-8.759zm0 14.334a5.577 5.577 0 115.728-5.574 5.659 5.659 0 01-5.728 5.574z" />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent
