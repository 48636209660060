import { MARKDOWNTYPE_IMAGE, MARKDOWN_PREFIX } from 'variables'
import { get, isEmpty } from 'lodash'
import { getMarkdownQueryInfo, getMarkdownType, isSpecialMarkdown } from 'helpers'

import { Image } from 'components'
import MarkdownImage from './MarkdownImage'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface IRemarkParseOptions {
    gfm: boolean
    commonmark: boolean
    footnotes: boolean
    blocks: string[]
    pedantic: boolean
}

interface IMarkdown {
    source: string
    escapeHtml?: boolean
    parserOptions?: Partial<IRemarkParseOptions>
}

const Markdown = ({ source, ...rest }: IMarkdown) => {
    if (isEmpty(source)) {
        return null
    }

    const markdownSections: string[] = source.split(new RegExp(MARKDOWN_PREFIX))
    if (isEmpty(markdownSections)) {
        return null
    }

    return (
        <>
            {
                markdownSections.map((markdownSection: string) => {
                    if (isSpecialMarkdown(markdownSection)) {
                        const {
                            markdownType,
                            parsedMD
                        } = getMarkdownType(markdownSection)
                        const args = getMarkdownQueryInfo(parsedMD)
                        if (isEmpty(args)) {
                            return null
                        }

                        switch (markdownType) {
                            case MARKDOWNTYPE_IMAGE:
                                return (
                                    <MarkdownImage
                                        path={get(args, '[0].path')}
                                        name={get(args, '[0].name')}
                                    />
                                )
                            default:
                                return null;
                        }
                    }

                    return (
                        <ReactMarkdown
                            key={markdownSection.slice(0, 10)}
                            source={markdownSection}
                            // This option allows to create quick links
                            escapeHtml={false}
                            parserOptions={{ pedantic: true }}
                            // lazyload images in blog article
                            renderers={{ image: Image }}
                            {...rest}
                        />
                    )
                })
            }
        </>
    )
}

export default Markdown
