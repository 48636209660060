import React from 'react'
import ResponsiveLayout from '../ResponsiveLayout'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Component = (props: ITableDropdownList) => (
  <ResponsiveLayout
    mobileView={<Mobile {...props} />}
    desktopView={<Desktop {...props} />}
  />
)

Component.defaultProps = {
    headline: 'April',
    subHeadline: 'We believe that there is no one-size-fits-all insurance and that each individual requires a unique set of solutions. This is why we go to the great lengths to provide the options that best match your needs.',
    tableHeaders: [
        {
            name: 'Area',
            width: 1
        },
        {
            name: 'Provider Name',
            width: 2
        },
        {
            name: 'Address',
            width: 2
        },
        {
            name: 'Tel/Fax',
            width: 2
        },
        {
            name: 'Email/Website',
            width: 2
        }
    ],
    tableContents: [
        {
            name: 'Mainland China',
            contents: [
                {
                    data: [
                        'Kuala Lumpur', 
                        'Columbia Asia Hospital - Setapak',
                        'No. 1, Jalan Danau Saujana Off Jalan Genting Klang, 53300 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
                        'T: +603 4145 9999',
                        'customercare.setapak@columbiaasia.com',
                    ]
                },
                {
                    data: [
                        'Kuala Lumpur', 
                        'Columbia Asia Hospital - Setapak',
                        'No. 1, Jalan Danau Saujana Off Jalan Genting Klang, 53300 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
                        'T: +603 4145 9999',
                        'customercare.setapak@columbiaasia.com',
                    ]
                }
            ]
        },
        {
            name: 'Mainland China',
            contents: [
                {
                    data: [
                        'Kuala Lumpur', 
                        'Columbia Asia Hospital - Setapak',
                        'No. 1, Jalan Danau Saujana Off Jalan Genting Klang, 53300 Kuala Lumpur, Wilayah Persekutuan Kuala Lumpur',
                        'T: +603 4145 9999',
                        'customercare.setapak@columbiaasia.com',
                    ]
                }
            ]
        },
    ]
}

export default Component
