import React from 'react';
import { LocalizedLink, OutboundLink, Icons } from 'components';
import styled from 'styled-components';
import { color } from 'themes';
const { Checkmark } = Icons;

export const Wrapper = styled.section`
    background: #F1F1F5;
    padding: 16px;
`

export const Container = styled.div`
    margin: 0 auto;
    padding: 24px 0;
    display: flex;
    flex-direction: column;
`

export const Content = styled.div`
    flex: 1;
`

export const Headline = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
    color: ${color.mainBlue};
`

export const SubHeadline = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${color.fontBlue};
    margin-bottom: 16px;
`

const ButtonStyles = `
    display: inline-flex;
    background: ${color.mediumSpringGreen};
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.121569);
    border-radius: 8px;
    padding: 6px 30px;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${color.white};
    &:hover {
        color: ${color.white};
    }
`

export const Button = styled((props : any) => <LocalizedLink {...props}/>)`
    ${ButtonStyles}
`

export const ExternalButton = styled((props : any) => <OutboundLink {...props}/>)`
    ${ButtonStyles}
`

export const TextListWrapper = styled.div`
    background: ${color.backgroundWhite};
    border: 1px solid ${color.darkGrey};
    border-radius: 16px;
    padding: 24px;
    flex: 1;
    margin-top: 22px;
`

export const TextListHeadline = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
    color: ${color.mainBlue};
`

export const TextListIcon = styled((props: any) => <Checkmark color={color.white} {...props}/>)`
    width: 14px;
    height: 14px;
    background: ${color.mediumSpringGreen};
    border-radius: 50%;
    padding: 2px;
    margin-right: 8px;
`

export const TextListItem = styled.div`
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    display: flex;
    align-items: start;
`

export const TextListItemText = styled.p`
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
`
