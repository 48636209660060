import React from 'react';
import { Wrapper, Container, Headline, SubHeadline } from './style';
import { Markdown } from 'components';

const Component = ({
    headline,
    subHeadline,
    backgroundImageMobile,
}: IJumbotron) => {
    return (
        <Wrapper
            style={{
                background: `url(${backgroundImageMobile})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                minHeight: 324,
            }}
        >
            <Container>
                <Headline>{headline}</Headline>
                <SubHeadline>
                    <Markdown source={subHeadline} />
                </SubHeadline>
            </Container>
        </Wrapper>
    );
};

export default Component;
