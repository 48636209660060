import React from 'react'
import { Image, Markdown } from 'components'
import {
    Wrapper,
    Container,
    Headline,
    Description,
    ListWrapper,
    Company,
    CompanyDescription,
    LogoWrapper,
    CompanyName,
    Logo,
    ExternalLink,
} from './style'

const Desktop = ({ headline, subHeadline, partners }: IPartnersList) => {
    return(
        <Wrapper>
            <Container>
                {headline && <Headline>{headline}</Headline>}
                {subHeadline && <Description>{subHeadline}</Description>}
                <ListWrapper>
                    {
                        partners.map(({ logo, description, name, link } : any, index: number) => {
                            return(
                                <Company key={index}>
                                     <Logo>
                                        <LogoWrapper>
                                            {
                                                <ExternalLink target="_blank" href={link}>
                                                    <Image alt='logo' src={logo}
                                                        style={{width: 176, padding: 12}}
                                                    />
                                                </ExternalLink>
                                            }
                                        </LogoWrapper>
                                    </Logo>
                                    <CompanyDescription>
                                    <CompanyName>
                                        <ExternalLink target="_blank" href={link}>{name}</ExternalLink>
                                    </CompanyName>
                                    <Markdown source={description}/>
                                    </CompanyDescription>
                                </Company>
                            )
                        })
                    }
                </ListWrapper>
            </Container>
        </Wrapper>
    )
}
export default Desktop
