import React from 'react';
import { Image } from 'components';
import {
    CardWrapper,
    CardBody,
    CardHeadline,
    CardDescription,
    CardMedia,
} from './style';

const Component = ({ headline, subHeadline, icon, link } : IPackageTeaserCard) => {
    return(
        <CardWrapper to={link}>
            <CardBody>
                <CardMedia>
                    <Image
                        alt={headline}
                        src={icon}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            objectPosition: 'center'
                        }}
                    />
                </CardMedia>
                <CardHeadline>{headline}</CardHeadline>
                <CardDescription>{subHeadline}</CardDescription>
            </CardBody>
        </CardWrapper>
    )
}

export default Component;
