import React from 'react';
import {
    Wrapper,
    Container,
    Content,
    Headline,
    SubHeadline,
    Button,
    ExternalButton,
    TextListHeadline,
    TextListWrapper,
    TextListItem,
    TextListIcon,
    TextListItemText
} from './style';

const Component = ({headline, subHeadline, textListHeadline, buttonLink, buttonText, textList, buttonLinkExternal}: ITariffAndBenefits) => {
    return(
        <Wrapper>
            <Container>
                <Content>
                    <Headline>{headline}</Headline>
                    <SubHeadline>{subHeadline}</SubHeadline>
                    {buttonLinkExternal ? <ExternalButton to={buttonLink}>{buttonText}</ExternalButton> : <Button to={buttonLink}>{buttonText}</Button>}
                </Content>
                <TextListWrapper>
                    <TextListHeadline>{textListHeadline}</TextListHeadline>
                    {
                        textList.map((text: string, index: number) => (
                            <TextListItem key={index}>
                                <TextListIcon />
                                <TextListItemText>{text}</TextListItemText>
                            </TextListItem>
                        ))
                    }
                </TextListWrapper>
            </Container>
        </Wrapper>
    )
}

export default Component
