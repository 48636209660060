/**
 * MARKDOWN TYPES
 */

export const MARKDOWNTYPE_SLIDER: string = 'slider'
export const MARKDOWNTYPE_IMAGE: string = 'image'
export const MARKDOWNTYPE_VIDEO: string = 'video'
export const MARKDOWNTYPE_ARTICLE_LINK: string = 'articlelink'

export const MARKDOWNTYPES = {
    [MARKDOWNTYPE_SLIDER]: MARKDOWNTYPE_SLIDER,
    [MARKDOWNTYPE_IMAGE]: MARKDOWNTYPE_IMAGE,
    [MARKDOWNTYPE_VIDEO]: MARKDOWNTYPE_VIDEO,
    [MARKDOWNTYPE_ARTICLE_LINK]: MARKDOWNTYPE_ARTICLE_LINK,
}

export const MARKDOWN_PREFIX = '~[cC]ustom~'

export const PAGE_LIMIT_OPTIONS = [{
    label: '10 items per page',
    disabled: false,
    value: 10,
}, {
    label: '20 items per page',
    disabled: false,
    value: 20,
}, {
    label: '50 items per page',
    disabled: false,
    value: 50,
}]

// UNIVERSITIES AND STUDY PROGRAMS
export const DEGREES = [
    {
        value: 0,
        label: 'Select degree',
    },
    {
        value: 1,
        label: 'Bachelor',
    },
    {
        value: 2,
        label: 'Master',
    },
    {
        value: 3,
        label: 'PHD',
    },
]

export const LANGUAGES = [
    {
        value: '',
        label: 'Select language',
    },
    {
        value: 'German',
        label: 'German',
    },
    {
        value: 'English',
        label: 'English',
    },
]

export const START_FROM = [
    {
        value: 0,
        label: 'You want to study from?',
    },
    {
        value: 1,
        label: 'Winter Semester',
    },
    {
        value: 2,
        label: 'Summer Semester',
    },
]

export const FORM_OF_STUDY = [
    {
        value: 0,
        label: 'Select study form',
    },
    {
        value: 1,
        label: 'Part-time',
    },
    {
        value: 2,
        label: 'Full-time',
    },
]

export const COUNTRIES = [
    {
        value: '',
        label: 'Select a country',
    },
    {
        value: 'Germany',
        label: 'Germany',
    },
    {
        value: 'Swiss',
        label: 'Swiss',
    },
    {
        value: 'Sweden',
        label: 'Sweden',
    },
    {
        value: 'Netherland',
        label: 'Netherland',
    },
    {
        value: 'UK',
        label: 'UK',
    },
    {
        value: 'Denmark',
        label: 'Denmark',
    },
    {
        value: 'Canada',
        label: 'Canada',
    },
    {
        value: 'US',
        label: 'US',
    },
    {
        value: 'Austria',
        label: 'Austria',
    },
    {
        value: 'Italy',
        label: 'Italy',
    },
    {
        value: 'Spain',
        label: 'Spain',
    },
    {
        value: 'Russia',
        label: 'Russia',
    },
]

export const UNIVERSITY_TYPES = [
    {
        value: '',
        label: 'Select your university type',
    },
    {
        value: 'technical',
        label: 'Technical University',
    },
    {
        value: 'applied',
        label: 'University of Applied',
    },
    {
        value: 'business',
        label: 'Business School',
    },
]

export const PUBLIC_OR_PRIVATES = [
    {
        value: '',
        label: 'Public or private',
    },
    {
        value: 'public',
        label: 'Public',
    },
    {
        value: 'private',
        label: 'Private',
    },
]

export const NUMBER_OF_STUDENTS = [
    {
        value: 0,
        label: 'Any',
    },
    {
        value: 1,
        label: '< 5000',
    },
    {
        value: 2,
        label: '5000 - 10000',
    },
    {
        value: 3,
        label: '> 10000',
    }
]

export const TUITION_FEES = [
    {
        value: 0,
        label: 'Any',
    },
    {
        value: 1,
        label: 'Low',
    },
    {
        value: 2,
        label: 'Medium',
    },
    {
        value: 3,
        label: 'High',
    }
]

// const applicationOrganizationOptions = [
//     {
//         label: 'Yes',
//         value: '1',
//     }, {
//         label: 'Disable',
//         value: '-1',
//     }, {
//         label: 'No',
//         value: '0',
//     }
// ]
