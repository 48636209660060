export const INFO : ITariffAndBenefits = {
    headline: 'Our Tariff',
    subHeadline: 'Donec ullamcorper nulla non metus auctor fringilla. Maecenas sed diam eget risus varius blandit sit amet non magna. Nulla vitae elit libero, a pharetra augue.',
    buttonLink: '/',
    buttonLinkExternal: false,
    buttonText: 'Purchase Now',
    textListHeadline: 'Your Benefits',
    textList: [
        'Doned ullamcorper nulla non',
        'Metus auctor fringilla',
        'Risus variuss blandit sit amer none',
        'Doned ullamcorper nulla non metus auctor',
        'Metus auctor fringilla risus variuss blandit sit',
        'Risus variuss blandit sit amer none metus auctor fringilla'
    ]
}
