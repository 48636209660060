import React from 'react'
import { get } from 'lodash'
import { HeaderBox } from 'components'
import {
  Container,
  MarginContainer,
  HeaderContainer,
} from './style'

const Mobile = (props: IHeroSection) => (
  <Container
    style={{
      background: `url(${get(props, 'backgroundImageMobile')})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }}
  >
    <MarginContainer>
      <HeaderContainer>
        <HeaderBox
          headline={props.headline}
          description={props.subHeadline}
        />
      </HeaderContainer>
    </MarginContainer>
  </Container>
)

export default Mobile
