import * as React from "react"

function SadFace(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M26.0002 9.41045C29.0967 14.1015 28.5972 20.3895 24.5019 24.4817C19.8072 29.1728 12.2157 29.1728 7.52103 24.4817C2.82632 19.7907 2.82632 12.2051 7.52103 7.51407C11.6164 3.42187 17.9093 2.92283 22.5041 5.91711" stroke="white" strokeMiterlimit="10" strokeLinecap="round"/>
    <path d="M19.8071 20.4895C18.7084 20.0902 17.4098 19.8906 16.2112 19.8906C14.9126 19.8906 13.714 20.0902 12.6152 20.4895" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.6164 15.5984C10.9171 15.5984 10.4177 15.0993 10.4177 14.4007V12.2049C10.3178 11.5062 10.9171 11.0071 11.5165 11.0071C12.2157 11.0071 12.7151 11.5062 12.7151 12.2049V14.4007C12.7151 14.9995 12.2157 15.5984 11.6164 15.5984Z" stroke="white" strokeMiterlimit="10"/>
    <path d="M20.806 15.5984C20.1068 15.5984 19.6074 15.0993 19.6074 14.4007V12.2049C19.6074 11.5062 20.1068 11.0071 20.806 11.0071C21.5052 11.0071 22.0047 11.5062 22.0047 12.2049V14.4007C22.0047 14.9995 21.5052 15.5984 20.806 15.5984Z" stroke="white" strokeMiterlimit="10"/>
    <path d="M24.4018 8.21247C24.7328 8.21247 25.0011 7.94435 25.0011 7.61361C25.0011 7.28287 24.7328 7.01476 24.4018 7.01476C24.0708 7.01476 23.8025 7.28287 23.8025 7.61361C23.8025 7.94435 24.0708 8.21247 24.4018 8.21247Z" fill="white"/>
    </svg>
  )
}

export default SadFace
