export const getImage = (id: string) => {
  try {
      if (id && id.includes('http')) {
          return `${id.replace(/ /g, encodeURIComponent('_'))}` // replace whitespace by encoded %20
      }
  
      return `${process.env.GATSBY_STATIC_URI}${id.replace(/ /g, encodeURIComponent('_'))}`    
  } catch (error) {
      console.error('Error when build image url for ', id)
      return id
  }
}