import React from 'react';
import ResponsiveLayout from '../ResponsiveLayout';
import Desktop from './Desktop';
import Mobile from './Mobile';
import { INFO } from './const';

const ImageSwitchingTabs = (props : IImageSwitchingTabs) => (
    <ResponsiveLayout
        mobileView={<Mobile {...props}/>}
        desktopView={<Desktop {...props}/>}
    />
)

ImageSwitchingTabs.defaultProps = INFO

export default ImageSwitchingTabs
