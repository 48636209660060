import React from 'react'
import styled, {css} from 'styled-components'
import {Button, LocalizedLink, Icons} from 'components'
import {Link} from 'gatsby'
import {color, spacing} from 'themes'
import {media} from 'utils'
import {Popover} from 'antd'

const { DownArrow, Language } = Icons;

export const Wrapper = styled.header`
  width: 100%;
  background: ${color.white};
`

export const Header = styled.div`
  background-color: ${color.mainBlue};
  //   padding: 0 calc(50% - 610px);
  max-width: 1280px;
  margin: 0 auto;
  ${media.large} {
    padding: 0 ${spacing.desktop.bodyPadding};
  }
  height: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: auto;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &&::-webkit-scrollbar {
    display: none;
  }
`

export const HeaderItem = styled((props: any) => <LocalizedLink {...props} />)`
  color: ${color.white};
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  margin-left: 48px;
`

export const Bar = styled.div`
//   padding: 0 calc(50% - 610px);
    max-width: 1280px;
    margin: 0 auto;
  ${media.large} {
    padding: 0 ${spacing.desktop.bodyPadding};
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Logo = styled((props: any) => <LocalizedLink {...props} />)`
width: 130px;
height: 32px;
background: transparent url('${props => props.src}') 0% 0% no-repeat padding-box;
background-size: cover;
`

export const Menu = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  margin-left: 60px;
`

export const Arrow = styled((props: any) => <DownArrow width={24} height={24} {...props} />)`
  margin-left: 5px;
  transition: all .3s;
  ${props => props.collapse && css`
    transform: rotate(180deg);
  `}
`

export const MenuPopover = styled((props: any) => <Popover {...props} />)`
`

export const MenuItem = styled.span`
  color: ${color.mainBlue};
  display: inline-flex;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  padding: 20px 24px;
  height: 100%;
  text-transform: uppercase;
  position: relative;
  &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        left: 0;
        transition: width .3s;
      }
    
    &:hover {
      text-decoration: none;
      cursor: pointer;
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 48px);
        background: ${color.mainBlue};
        height: 4px;
        bottom: 0;
        left: 24px;
        transition: width .3s;
      }
    }
`

export const Item = styled((props: any) => <LocalizedLink {...props} />)`
    display: inline-flex;
    align-items: center;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    height: 100%;
    padding: 20px 24px;
    transition: all .2s;
    position: relative;
    text-transform: uppercase;
    &::before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0;
        left: 0;
        transition: width .3s;
      }
    ${props => props.active && css`
        text-decoration: none;
        &::before {
          content: '';
          position: absolute;
          width: calc(100% - 48px);
          background: ${color.mainBlue};
          height: 4px;
          bottom: 0;
          left: 24px;
        }
    `}
    &:hover {
      text-decoration: none;
      &::before {
        content: '';
        position: absolute;
        width: calc(100% - 48px);
        background: ${color.mainBlue};
        height: 4px;
        bottom: 0;
        left: 24px;
      }
    }
`
export const SubMenu = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
`

export const SubItem = styled.div`
  padding: 4px 0;
`

export const StyledLink = styled((props: any) => <Link {...props} />)`
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
  padding: 0 12px;
  width: fit-content;
  &&:hover {
    text-decoration: underline;
  }
`

export const StyledLocalizedLink =
    styled((props: any) => <LocalizedLink {...props} />)`
  text-align: right;
  font-size: 12px;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
  padding: 0 12px;
  width: fit-content;
  &&:hover {
    text-decoration: underline;
  }
`

export const ButtonItem = styled((props: any) => 
  <Button 
  style={{
    borderRadius: 8, 
    height: 'auto', 
    padding: '8px 20px',
    textTransform: 'uppercase',
    fontSize: 12
  }} {...props} />
)`
  margin: 0 24px;
`

export const Languages = styled.div`
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: ${color.mainBlue};
  margin-left: 24px;
`

export const Country = styled.span`
  cursor: pointer;
  &&:hover {
    text-decoration: underline;
  }
`

export const Slash = styled.span`
  margin: 0 5px;
`

export const Underline = styled.span`
  text-decoration: underline;
`

export const TopNav = styled.div`
  background: ${color.mainBlue};
`

export const TopNavContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 32px;
  display: flex;
  justify-content: flex-end;
`

export const TopNavItem = styled.div`
  padding: 8px 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${color.white};
`

export const LeftMenu = styled.div`
  display: flex;
  align-items: center;
`

export const RightMenu = styled.div`
  display: flex;
  align-items: center;
`

export const LanguageIcon = styled((props: any) => <Language {...props} />)`
  margin-right: 10px;
`
