import {
    Banner,
    BannerLarge,
    CardList,
    ContactForm,
    DownloadAndDocuments,
    FAQs,
    HeroSection,
    ImageSwitchingTabs,
    Jumbotron,
    MarkdownSection,
    Menu,
    PackageTeaserCardList,
    Partners,
    PartnersList,
    ProductCardListWithText,
    SEO,
    Table,
    TableDropdownList,
    TariffAndBenefits,
    TextWithPictureGroup,
    USPs,
} from 'components';
import { get, isEmpty } from 'lodash';

import React from 'react';
import { getImage } from 'helpers';

export const generateInvisibleComponents = (components: any) => {
    return (components.map((c: any, key: number) => {
        switch (c.type) {
            case "MetaTags": {
                const props: IMetaTags = {
                    title: get(c.data, 'title', ''),
                    description: get(c.data, 'description', ''),
                    keywords: get(c.data, 'keywords', []) || [],
                    image: get(c.data, `image[0].id`, '')
                        ? `${get(c.data, `image[0].id`)}/${get(c.data, `image[0].fileName`)}`
                        : ''
                }
                return <SEO key={key} {...props} />
            }
        }
    }))
}

export const generateLayout = (components: any, breadcrumbs?: any[]) => {
    return (components.map((c: any, key: number) => {
        switch (c.type) {
            case "Heros": {
                const { data } = c;
                const _breadcrumbs: any[] = []
                if (!isEmpty(breadcrumbs)) {
                    if (!isEmpty(get(breadcrumbs, '[0].parent', []))) {
                        _breadcrumbs.push({
                            name: get(breadcrumbs, '[0].parent[0].flatData.location', ''),
                            url: get(breadcrumbs, '[0].parent[0].flatData.slug', ''),
                        })
                    }

                    _breadcrumbs.push({
                        name: get(breadcrumbs, '[0].title', ''),
                    })
                }

                const backgroundImage = get(data, `backgroundImage[0].id`, '')
                    ? `${get(data, `backgroundImage[0].id`)}/${get(data, `backgroundImage[0].fileName`)}`
                    : ''

                const props: IHeroSection = {
                    headline: data.headline,
                    subHeadline: data.subHeadline,
                    backgroundImage: getImage(backgroundImage),
                    backgroundImageMobile: getImage(get(data, `backgroundImageMobile[0].id`, '')
                        ? `${get(data, `backgroundImageMobile[0].id`)}/${get(data, `backgroundImageMobile[0].fileName`)}`
                        : ''),
                    breadcrumbs: _breadcrumbs
                }
                return (<HeroSection key={key} {...props} />)
            }
            case "UspGroups": {
                const props: IUSPs = {
                    type: get(c.data, 'type', 'normal'),
                    uSPsList: c.data.uSPsList.map((usp: any) => ({
                        icon: getImage(get(usp, `icon[0].id`, '')
                            ? `${get(usp, `icon[0].id`)}/${get(usp, `icon[0].fileName`)}`
                            : ''),
                        headline: get(usp, 'headline', ''),
                        subHeadline: get(usp, 'subHeadline', ''),
                        linkText: get(usp, 'linkText', ''),
                        link: get(usp, 'link', '/'),
                        externalLink: get(usp, 'externalLink', false)
                    }))
                }
                return (<USPs key={key} {...props} />)
            }
            case "TariffAndBenefits": {
                const props: ITariffAndBenefits = {
                    headline: get(c.data, 'headline', ''),
                    subHeadline: get(c.data, 'subHeadline', ''),
                    buttonText: get(c.data, 'buttonText', ''),
                    buttonLink: get(c.data, 'buttonLink', ''),
                    buttonLinkExternal: get(c.data, 'buttonLinkExternal', false),
                    textListHeadline: get(c.data, 'textListHeadline', ''),
                    textList: get(c.data, 'textList', []).map(({ text }: any) => text),
                }
                return <TariffAndBenefits key={key} {...props} />
            }
            case "TextWithPictureGroups": {
                const props: ITextWithPictureGroup = {
                    items: get(c.data, 'textWithPictureArray', []).map((item: any) => ({
                        ...item,
                        picture: getImage(get(item, `picture[0].id`, '')
                            ? `${get(item, `picture[0].id`)}/${get(item, `picture[0].fileName`)}`
                            : ''),
                    }))
                }
                return <TextWithPictureGroup key={key} {...props} />
            }
            case "FaqsGroup": {
                const props: IFaqsGroup = {
                    headline: get(c.data, 'headline', ''),
                    items: get(c.data, 'fAQs', []).map((faq: any) => faq)
                }
                return <FAQs key={key} {...props} />
            }
            case "PartnersAndReferences": {
                const props: IPartners = {
                    headline: get(c.data, 'headline', ''),
                    subHeadline: get(c.data, 'subHeadline', ''),
                    partners: get(c.data, 'partners', []).map((item: any) => ({
                        ...item,
                        logo: getImage(get(item, `logo[0].id`, '')
                            ? `${get(item, `logo[0].id`)}/${get(item, `logo[0].fileName`)}`
                            : ''),
                    })),
                }
                return <Partners key={key} {...props} />
            }
            case "Banner": {
                const props: IBanner = {
                    ...c.data,
                    image: getImage(get(c.data, `image[0].id`, '')
                        ? `${get(c.data, `image[0].id`)}/${get(c.data, `image[0].fileName`)}`
                        : ''),
                }
                return <Banner key={key} {...props} />
            }
            case "ProductCardListWithText": {
                const props: IProductCardListWithText = {
                    ...c.data,
                    productCardList: get(c.data, 'productCardList', []).map((card: any) => ({
                        ...card,
                        icon: getImage(get(card, `icon[0].id`, '')
                            ? `${get(card, `icon[0].id`)}/${get(card, `icon[0].fileName`)}`
                            : ''),
                    }))
                }
                return <ProductCardListWithText key={key} {...props} />
            }
            case "PackageTeaserCardList": {
                const props: IPackageTeaserCardList = {
                    ...c.data,
                    cards: get(c.data, 'cards', []).map((card: any) => ({
                        ...card,
                        icon: getImage(get(card, `icon[0].id`, '')
                            ? `${get(card, `icon[0].id`)}/${get(card, `icon[0].fileName`)}`
                            : ''),
                    }))
                }
                return <PackageTeaserCardList key={key} {...props} />
            }
            case "ImageSwitchingTabs": {
                const props: IImageSwitchingTabs = {
                    ...c.data,
                    tabs: get(c.data, 'imageSwitchingTabItems', []).map((tab: any) => ({
                        ...tab,
                        image: getImage(get(tab, `image[0].id`, '')
                            ? `${get(tab, `image[0].id`)}/${get(tab, `image[0].fileName`)}`
                            : ''),
                    })),
                }
                return <ImageSwitchingTabs key={key} {...props} />
            }
            case "CardItemList": {
                const props: ICardList = {
                    headline: get(c.data, 'headline', ''),
                    cards: get(c.data, 'cards', []).map((card: any) => ({
                        ...card,
                        data: {
                            ...card.data,
                            icon: getImage(get(card.data, `icon[0].id`, '')
                                ? `${get(card.data, `icon[0].id`)}/${get(card.data, `icon[0].fileName`)}`
                                : ''),
                        }
                    }))
                }
                return <CardList key={key} {...props} />
            }
            case "BannerLarge": {
                const props: IBannerLarge = {
                    headline: get(c.data, 'headline', ''),
                    subHeadline: get(c.data, 'subHeadline', ''),
                    backgroundImage: getImage(get(c.data, `backgroundImage[0].id`, '')
                        ? `${get(c.data, `backgroundImage[0].id`)}/${get(c.data, `backgroundImage[0].fileName`)}`
                        : ''),
                    backgroundImageMobile: getImage(get(c.data, `backgroundImageMobile[0].id`, '')
                        ? `${get(c.data, `backgroundImageMobile[0].id`)}/${get(c.data, `backgroundImageMobile[0].fileName`)}`
                        : ''),
                }
                return <BannerLarge key={key} {...props} />
            }
            case "MdContent": {
                const props: IMarkdownSection = {
                    content: get(c.data, 'content', '')
                }
                return <MarkdownSection key={key} {...props} />
            }
            case "DownloadAndDocuments": {
                const props: IDownloadAndDocuments = {
                    ...c.data,
                    documents: get(c.data, 'documents', []).map((doc: any) => ({
                        ...doc,
                        file: getImage(get(doc, `file[0].id`, '')
                            ? `${get(doc, `file[0].id`)}/${get(doc, `file[0].fileName`)}`
                            : ''),
                    }))
                }
                return <DownloadAndDocuments key={key} {...props} />
            }
            case "PartnersList": {
                const props: IPartnersList = {
                    ...c.data,
                    partners: get(c.data, 'partners', []).map((partner: any) => ({
                        ...partner,
                        logo: getImage(get(partner, `logo[0].id`, '')
                            ? `${get(partner, `logo[0].id`)}/${get(partner, `logo[0].fileName`)}`
                            : ''),
                    }))
                }
                return <PartnersList key={key} {...props} />
            }
            case "TableDropdownList": {
                const props: ITableDropdownList = {
                    headline: get(c.data, 'headline', ''),
                    subHeadline: get(c.data, 'subHeadline', ''),
                    tableHeaders: get(c.data, 'tableHeaders', []),
                    tableContents: get(c.data, 'tableContents', [])
                }
                return <TableDropdownList key={key} {...props} />
            }
            case "Table": {
                const props: ITable = {
                    headline: get(c.data, 'headline', ''),
                    tableHeaders: get(c.data, 'tableHeaders', []),
                    tableContents: get(c.data, 'tableContents', []).map((data: any) => ({
                        headline: get(data, 'headline', ''),
                        icon: getImage(get(data, `icon[0].id`, '')
                            ? `${get(data, `icon[0].id`)}/${get(data, `icon[0].fileName`)}`
                            : ''),
                        plans: get(data, 'plans', []),
                        tooltip: get(data, 'tooltip', ''),
                        rows: get(data, 'rows', [])
                    })),
                    tooltips: get(c.data, 'tooltips', []),
                    iconTooltips: get(c.data, 'iconTooltips', []).map((data: any) => ({
                        tooltip: get(data, 'tooltip', ''),
                        icon: getImage(get(data, `icon[0].id`, '')
                            ? `${get(data, `icon[0].id`)}/${get(data, `icon[0].fileName`)}`
                            : ''),
                    }))
                }
                return <Table key={key} {...props} />
            }
            case "ContactForm": {
                const props: IContactForm = {
                    hubspotFormGUID: get(c.data, 'hubspotFormGUID', ''),
                    hubspotPortalId: get(c.data, 'hubspotPortalId', ''),
                    chatWithUs: get(c.data, 'chatWithUs', ''),
                    chatWithUsLink: get(c.data, 'chatWithUsLink', ''),
                    companyAddress: get(c.data, 'companyAddress', ''),
                    companyDescription: get(c.data, 'companyDescription', ''),
                    companyEmail: get(c.data, 'companyEmail', ''),
                    companyName: get(c.data, 'companyName', ''),
                    companyPhoneNumber: get(c.data, 'companyPhoneNumber', ''),
                    emergencyContactsHeadline: get(c.data, 'emergencyContactsHeadline', ''),
                    formHeadline: get(c.data, 'formHeadline', ''),
                    needHelpText: get(c.data, 'needHelpText', ''),
                    socialMediaNetworksHeadline: get(c.data, 'socialMediaNetworksHeadline', ''),
                    submitText: get(c.data, 'submitText', ''),
                    tooltip: get(c.data, 'tooltip', ''),
                    backgroundImage: getImage(get(c.data, `backgroundImage[0].id`, '')
                        ? `${get(c.data, `backgroundImage[0].id`)}/${get(c.data, `backgroundImage[0].fileName`)}`
                        : ''),
                    backgroundMobileImage: getImage(get(c.data, `backgroundMobileImage[0].id`, '')
                        ? `${get(c.data, `backgroundMobileImage[0].id`)}/${get(c.data, `backgroundMobileImage[0].fileName`)}`
                        : ''),
                    formGroup: get(c.data, 'formGroup', []),
                    companyLogo: getImage(get(c.data, `companyLogo[0].id`, '')
                        ? `${get(c.data, `companyLogo[0].id`)}/${get(c.data, `companyLogo[0].fileName`)}`
                        : ''),
                    emergencyContacts: get(c.data, 'emergencyContacts', []),
                    socialMediaNetworks: get(c.data, 'socialMediaNetworks', []).map((data: any) => ({
                        ...data,
                        logo: getImage(get(data, `logo[0].id`, '')
                            ? `${get(data, `logo[0].id`)}/${get(data, `logo[0].fileName`)}`
                            : ''),
                    })),
                    successMessage: get(c.data, 'successMessage', ''),
                    failedMessage: get(c.data, 'failedMessage', ''),
                }
                return <ContactForm key={key} {...props} />
            }
            case "MenuPrimary": {
                const props: IMenu = {
                    logo: getImage(get(c.data, `logo[0].id`, '')
                        ? `${get(c.data, `logo[0].id`)}/${get(c.data, `logo[0].fileName`)}`
                        : ''),
                    leftMenuList: get(c.data, 'leftMenuList', []).map((data: any) => ({
                        type: get(data, 'type', 'Text'),
                        data: {
                            text: get(data, `text`, ''),
                            url: get(data, 'url', '/'),
                            externalLink: get(data, 'externalLink', false),
                            children: get(data, 'children', []).map((data: any) => ({
                                type: get(data, 'type', 'Text'),
                                data: {
                                    text: get(data, `text`, ''),
                                    url: get(data, 'url', '/'),
                                    externalLink: get(data, 'externalLink', false),
                                }
                            }))
                        }
                    })),
                    rightMenuList: get(c.data, 'rightMenuList', []).map((data: any) => ({
                        type: get(data, 'type', 'Text'),
                        data: {
                            text: get(data, `text`, ''),
                            url: get(data, 'url', '/'),
                            externalLink: get(data, 'externalLink', false),
                            children: get(data, 'children', []).map((data: any) => ({
                                type: get(data, 'type', 'Text'),
                                data: {
                                    text: get(data, `text`, ''),
                                    url: get(data, 'url', '/'),
                                    externalLink: get(data, 'externalLink', false),
                                }
                            }))
                        }
                    })),
                    menuSecondaryList: get(c.data, 'menuSecondary', []),
                }
                return <Menu key={key} {...props} />
            }
            case 'Jumbotron': {
                const props: IJumbotron = {
                    headline: get(c.data, 'headline', ''),
                    subHeadline: get(c.data, 'subHeadline', ''),
                    backgroundImage: getImage(
                        get(c.data, `backgroundImage[0].id`, '')
                            ? `${get(c.data, `backgroundImage[0].id`)}/${get(
                                c.data,
                                `backgroundImage[0].fileName`,
                            )}`
                            : '',
                    ),
                    backgroundImageMobile: getImage(
                        get(c.data, `backgroundImageMobile[0].id`, '')
                            ? `${get(
                                c.data,
                                `backgroundImageMobile[0].id`,
                            )}/${get(
                                c.data,
                                `backgroundImageMobile[0].fileName`,
                            )}`
                            : '',
                    ),
                };
                return <Jumbotron {...props} />;
            }
            default:
                return (
                    <div key={key} style={{
                        textAlign: 'center',
                        background: '#ff0000',
                        padding: 40,
                        color: '#fff',
                        fontSize: '1.5em'
                    }}>
                        A component with type <strong>{c.type}</strong> is not implemented yet!
                    </div>
                );
        }
    }))
}