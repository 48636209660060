import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} fill="none" {...props}>
      <path
        d="M3 5.5h3M3 18.999h5M21 12h-3M9 5.5h12M11 18.999h10M15 12H3M7.5 7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM9.5 20.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM16.5 10.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SvgComponent
