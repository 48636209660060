import styled from 'styled-components'
import {color} from 'themes'

interface ILogo {
  readonly logo?: string
}

export const Wrapper = styled.section`
  padding: 32px 16px;
  background-color: ${color.background};
`

export const Title = styled.h3`
  font-size: 18px;
  line-height: 24px;
  font-weight: bold;
  color: ${color.mainBlue};
  margin-bottom: 40px;
  text-align: center;
  margin-bottom: 16px;
`

export const SubHeadline = styled.p`
  margin-bottom: 16px;
  color: ${color.fontBlue};
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
`

export const Partners = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const Partner = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 12px;
  width: 50%;
`

export const LogoWrapper = styled.a`
  max-width: 176px;
  min-height: 120px;
  padding: 20px;
  background-color: ${color.white};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 16px;
  border-radius: 16px;
`

const setImage = ({logo}: ILogo): string => {
  return `
    background: ${color.white} url("${logo}");
  `
}

export const Logo = styled.div<ILogo>`
  ${setImage}
  height: 50%;
  width: calc(100% - 16px);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
`

export const Label = styled.label`
  font-size: 12px;
  line-height: 18px;
  font-weight: bold;
  color: ${color.mainBlue};
  margin-top: 16px;
`
