import styled from 'styled-components'
import {color} from 'themes'

const BorderBottom = `
    padding-top: 12px;
    margin-bottom: 12px;
    border-bottom: 0.5px solid ${color.grey};
`

export const Container = styled.section`
    padding: 16px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
`

export const Section = styled.div`
    background: ${color.white};
    border-radius: 8px;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.0784314);

    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    flex-direction: column;

    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
    }
`

export const Headline = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
    color: ${color.mainBlue};
    margin-bottom: 16px;
    text-align: center;
`

export const Title = styled.div`
    width: 100%;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;

    color: ${color.mainBlue};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
`

export const Divider = styled.div`
    ${BorderBottom}
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    > p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 0;

        color: ${color.fontBlue};

        a {
            color: ${color.warning};
            &:hover {
                text-decoration: underline;
            }
        }
    }

    > img {
        max-width: 198px;
        max-height: 198px;
        object-fit: contain;
        margin: 0 auto;
    }
`

export const ImageContainer = styled.div`
    width: 32px;
    height: 32px;
`
