import React from 'react'
import {LocalizedLink} from 'components'
import styled from 'styled-components'
import {color, boxShadow} from 'themes'

export const Wrapper = styled.div`
    width: 100%;
`
export const Container = styled.div`
    padding: 24px 16px;
`
export const Headline = styled.h3`
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: ${color.mainBlue}
`
export const Description = styled.p`
    color: ${color.fontBlue};
    font-size: 16px;
    line-height: 26px;
`

export const ListWrapper = styled.div`
    margin-top: 20px;
`

export const Company = styled.div`
    padding: 24px 0;
    border-bottom: 1px solid ${color.lightGrey};
    &:first-child {
        padding-top: 0;
    }
    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
`
export const LogoWrapper = styled.div`
    margin-bottom: 24px;
    border-radius: 16px;
    box-shadow: ${boxShadow.input};
    width: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const CompanyDescription = styled.div`
    font-size: 16px;
    line-height: 26px;
    color: ${color.fontBlue};
`

export const CompanyName = styled.h3`
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: ${color.mainBlue};
`

export const Logo = styled.div`
`
export const ExternalLink = styled.a`
`

export const InternalLink = styled((props : any) => <LocalizedLink {...props}/>)`
`
