export const HOME = '/'
export const CAREER = '/career/'
export const NOT_FOUND = '/404/'
export const APP = '/app/'
export const ARRIVAL = '/arrival/'
export const STUDY = '/study/'
export const STUDY_GUIDE = '/studyguide/'
export const STUDY_UPGRADE = '/studyUpgrade/'
export const BUY_PACKAGE = '/buypackage/'
export const IMPRINT = '/imprint/'
export const PRIVACY_POLICY = '/privacyPolicy/'
export const PACKAGE = '/package/'
export const HOUSING = '/housing/'
export const SIGNUP = '/signup/'
export const BLOG = '/blog/'
export const ABOUT = '/about/'
export const PROFILE = '/profile/'
export const PORTAL = '/portal/'
export const UNIVERSITY = '/university/'
export const SEARCH_UNIVERSITIES = '/search/universities'
export const SEARCH_STUDY_PROGRAMS = '/search/studyprograms'
export const STUDY_PROGRAMS = `${UNIVERSITY}study-programs/`
export const UNIVERSITY_PATH = '/university/{{name}}'
export const UNIVERSITY_STUDY_PROGRAMS_PATH = `${UNIVERSITY_PATH}/study-programs/{{study_programs}}`
export const STUDY_PROGRAMS_PATH = `/study-programs/{{name}}`
export const UNIVERSITY_REVIEW_FORM = '/university-review-form/'

export default {
    [HOME]: HOME,
    [CAREER]: CAREER,
    [NOT_FOUND]: NOT_FOUND,
    [APP]: APP,
    [ARRIVAL]: ARRIVAL,
    [STUDY]: STUDY,
    [STUDY_GUIDE]: STUDY_GUIDE,
    [STUDY_UPGRADE]: STUDY_UPGRADE,
    [BUY_PACKAGE]: BUY_PACKAGE,
    [IMPRINT]: IMPRINT,
    [PRIVACY_POLICY]: PRIVACY_POLICY,
    [PACKAGE]: PACKAGE,
    [HOUSING]: HOUSING,
    [SIGNUP]: SIGNUP,
    [BLOG]: BLOG,
    [ABOUT]: ABOUT,
    [PROFILE]: PROFILE,
    [PORTAL]: PORTAL,
    [UNIVERSITY]: UNIVERSITY,
    [STUDY_PROGRAMS]: STUDY_PROGRAMS,
    [UNIVERSITY_PATH]: UNIVERSITY_PATH,
    [UNIVERSITY_STUDY_PROGRAMS_PATH]: UNIVERSITY_STUDY_PROGRAMS_PATH,
    [STUDY_PROGRAMS_PATH]: STUDY_PROGRAMS_PATH,
    [UNIVERSITY_REVIEW_FORM]: UNIVERSITY_REVIEW_FORM,
    [SEARCH_UNIVERSITIES]: SEARCH_UNIVERSITIES,
    [SEARCH_STUDY_PROGRAMS]: SEARCH_STUDY_PROGRAMS
}
