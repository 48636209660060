import * as React from "react"
import styled from 'styled-components'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {

    const Svg = styled.svg<any>`
        &:hover {
            .cls-1 {
                fill: #DC3175;
            }
        }
    `

    return (
        <Svg width={props.width || "24px" } height={props.height || "24px" } viewBox="0 0 32 32" fill="none" {...props}>
        <path
            className="cls-1"
            d="M28 0H4a4 4 0 00-4 4v24a4 4 0 004 4h24a4 4 0 004-4V4a4 4 0 00-4-4z"
            fill={props.color || "#B1B1B1"}
        />
        <path
            d="M20.967 4.292h-9.936a6.78 6.78 0 00-6.78 6.78V21a6.78 6.78 0 006.78 6.78h9.93A6.78 6.78 0 0027.74 21v-9.93a6.77 6.77 0 00-6.774-6.779zm4.614 16.886a4.501 4.501 0 01-4.502 4.502H10.913a4.501 4.501 0 01-4.502-4.502V11.012a4.501 4.501 0 014.502-4.502H21.08a4.501 4.501 0 014.502 4.502v10.166z"
            fill="#fff"
        />
        <path
            d="M16 10.002a6.097 6.097 0 100 12.194 6.097 6.097 0 000-12.195zm0 10.047a3.95 3.95 0 11-.001-7.9 3.95 3.95 0 010 7.9zM22.3 11.183a1.418 1.418 0 100-2.835 1.418 1.418 0 000 2.835z"
            fill="#fff"
        />
        </Svg>
    )
}

export default SvgComponent
