import React from 'react'

function Icon() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
        >
            <g data-name='Gruppe 137494' transform='translate(-14602 7575)'>
                <path
                    fill='none'
                    d='M0 0H16V16H0z'
                    data-name='Rechteck 1570'
                    transform='translate(14602 -7575)'
                />
                <g
                    fill='#0d507a'
                    stroke='#0d507a'
                    strokeWidth='1.5'
                    data-name='Ellipse 541'
                    transform='translate(14608 -7569)'
                >
                    <circle cx='6' cy='6' r='6' stroke='none' />
                    <circle cx='6' cy='6' r='5.25' fill='none' />
                </g>
                <g
                    fill='none'
                    stroke='#0d507a'
                    strokeWidth='1.5'
                    data-name='Ellipse 542'
                    transform='translate(14602 -7575)'
                >
                    <circle cx='12' cy='12' r='12' stroke='none' />
                    <circle cx='12' cy='12' r='11.25' />
                </g>
            </g>
        </svg>
    )
}

export default Icon
