import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <path
          d="M25.514 8.059L16 4.002 6.485 8.059H3.999v2.676h24V8.059zM26.575 25.324H4V28h24v-2.676zM26.538 14.318v-2.082H5.462v2.082h.712v7.422h-.712v2.082h21.076V21.74h-.713v-7.422zM11.375 21.74h-1.139v-7.422h1.139zm5.2 0h-1.139v-7.422h1.139zm5.2 0h-1.138v-7.422h1.138v7.422z"
          fill="#0d507a"
        />
      </g>
    </svg>
  )
}

export default SvgComponent
