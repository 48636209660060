import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 24} ${props.height || 24}`} {...props}>
      <path
        d="M14.3 19.4c.1.1.2.1.3.1h.1c1.7-.6 3.1-1.8 4-3.3.1-.1.1-.3 0-.4-.1-.1-.2-.2-.3-.2h-2.8c-.2 0-.4.1-.4.3-.2 1.2-.6 2.2-1 3 0 .2 0 .4.1.5zM9.7 8.3c.1 0 .2.1.3.1h4c.1 0 .2-.1.3-.1.1-.1.1-.2.1-.3-.5-2.5-1.5-4-2.4-4-.9 0-1.9 1.5-2.4 3.9 0 .1 0 .3.1.4zM14.3 14.8c.2 0 .4-.2.4-.4.1-.8.1-1.6.1-2.4 0-.8 0-1.7-.1-2.4 0-.2-.2-.4-.4-.4H9.7c-.2 0-.4.2-.4.4-.1.7-.1 1.6-.1 2.4 0 .8 0 1.7.1 2.4 0 .2.2.4.4.4h4.6zM8.4 14.7c.1-.1.1-.2.1-.3-.1-.8-.1-1.6-.1-2.4 0-.8 0-1.6.1-2.4 0-.1 0-.2-.1-.3 0-.1-.2-.1-.3-.1H4.8c-.2 0-.3.1-.4.3-.3.8-.4 1.6-.4 2.5 0 .9.1 1.7.4 2.5.1.2.2.3.4.3h3.3c.1 0 .3 0 .3-.1zM5.5 8.4h2.8c.2 0 .4-.1.4-.3.2-1.2.6-2.2 1-3 .1-.1.1-.3-.1-.4 0-.2-.2-.3-.4-.2-1.7.6-3.1 1.8-4 3.3-.1.1-.1.3 0 .4.1.1.2.2.3.2zM19.6 9.5c-.1-.2-.2-.3-.4-.3h-3.3c-.1 0-.2 0-.3.1-.1.1-.1.2-.1.3.1.8.1 1.6.1 2.4 0 .8 0 1.6-.1 2.4 0 .1 0 .2.1.3.1.1.2.1.3.1h3.3c.2 0 .3-.1.4-.3.3-.8.4-1.6.4-2.5 0-.9-.1-1.7-.4-2.5zM8.4 15.6H5.5c-.1 0-.3.1-.3.2-.1.1-.1.3 0 .4.9 1.5 2.4 2.7 4 3.3h.1c.1 0 .2 0 .3-.1.1-.1.1-.3.1-.4-.4-.8-.7-1.8-1-3 0-.3-.1-.4-.3-.4zM15.2 8.1c0 .2.2.3.4.3h2.8c.1 0 .3-.1.3-.2.1-.1.1-.3 0-.4-.9-1.5-2.4-2.7-4-3.3-.2-.1-.3 0-.4.1-.1.1-.1.3-.1.4.5.9.8 1.9 1 3.1zM14.3 15.7c-.1-.1-.2-.1-.3-.1h-4c-.1 0-.2.1-.3.1-.1.1-.1.2-.1.3.5 2.5 1.5 4 2.4 4 .9 0 1.9-1.5 2.4-3.9 0-.1 0-.3-.1-.4z"
        fill={props.color || "#0d507a" }
      />
    </svg>
  )
}

export default SvgComponent
