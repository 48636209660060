import * as React from "react"

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={16}
      height={18}
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5.67V.666h9.165c.46 0 .835.379.835.826v15.014a.827.827 0 01-.828.826H1.327a.834.834 0 01-.827-.839V5.667l5-4.998zM2.858 5.666H5.5V3.025L2.858 5.667zm4.309-3.334V6.5a.833.833 0 01-.834.833H2.167v8.334h11.666V2.333H7.167z"
        fill="#1E3056"
      />
    </svg>
  )
}

export default SvgComponent
