import React from 'react'
import ResponsiveLayout from '../ResponsiveLayout'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Menu = (props: IMenu) => (
  <ResponsiveLayout mobileView={<Mobile {...props} />} desktopView={<Desktop {...props} />} />
)

export default Menu
