import * as React from "react"
import styled from 'styled-components'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {

    const Svg = styled.svg<any>`
        &:hover {
            .cls-1 {
                fill: #27a1f2;
            }
        }
    `

    return (
        <Svg width={props.width || "24px" } height={props.height || "24px" } viewBox="0 0 32 32" fill="none" {...props}>
        <path
            className="cls-1"
            d="M28 0H4a4 4 0 00-4 4v24a4 4 0 004 4h24a4 4 0 004-4V4a4 4 0 00-4-4z"
            fill={props.color || "#27a1f2"}
        />
        <path
            d="M12.288 24.128c7.544 0 11.672-6.256 11.672-11.672 0-.176 0-.352-.008-.528A8.594 8.594 0 0026 9.808a8.342 8.342 0 01-2.36.648 4.1 4.1 0 001.8-2.272 8.072 8.072 0 01-2.608.992A4.101 4.101 0 0017.024 9a4.095 4.095 0 00-1.184 3.92 11.642 11.642 0 01-8.456-4.288 4.108 4.108 0 001.272 5.48 4.021 4.021 0 01-1.864-.512v.048a4.11 4.11 0 003.296 4.024 4.046 4.046 0 01-1.856.072 4.105 4.105 0 003.832 2.848 8.239 8.239 0 01-5.096 1.76c-.328 0-.656-.024-.976-.056a11.785 11.785 0 006.296 1.832z"
            fill="#fff"
        />
        </Svg>
    )
}

export default SvgComponent
