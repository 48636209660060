export const INFO: ITextWithPictureGroup = {
  items: [
    {
      headline: '',
      subHeadline: '',
      buttonText: '',
      buttonLink: '/',
      buttonLinkExternal: false,
      picture: '',
      reverse: false
    }
  ]
}
