import Checkmark from './Checkmark'
import LeftArrow from './LeftArrow'
import RightArrow from './RightArrow'
import RadioCheckboxActive from './RadioCheckboxActive'
import RadioCheckboxInactive from './RadioCheckboxInactive'
import DownArrow from './DownArrow'
import Plus from './Plus'
import Minus from './Minus'
import Search from './Search'
import Calendar from './Calendar'
import Clock from './Clock'
import Email from './Email'
import Degree from './Degree'
import Location from './Location'
import People from './People'
import Phone from './Phone'
import PostalCode from './PostalCode'
import Ranking from './Ranking'
import Student from './Student'
import Uni from './Uni'
import Website from './Website'
import World from './World'
import Street from './Street'
import Filter from './Filter'
import ExternalLink from './ExternalLink'
import SmileFace from './SmileFace'
import SadFace from './SadFace'
import DefaultIcon from './Default'
import Tag from './Tag'
import PDF from './PDF'
import Twitter from './Twitter'
import Facebook from './Facebook'
import Instagram from './Instagram'
import LinkedIn from './LinkedIn'
import MapPin from './MapPin'
import File from './File'
import Download from './Download'
import Warning from './Warning'
import Language from './Language'

export default {
    Checkmark,
    LeftArrow,
    RightArrow,
    DownArrow,
    RadioCheckboxActive,
    RadioCheckboxInactive,
    Plus,
    Minus,
    Search,
    Calendar,
    Clock,
    Email,
    Degree,
    Location,
    People,
    Phone,
    PostalCode,
    Ranking,
    Student,
    Uni,
    Website,
    World,
    Street,
    Filter,
    ExternalLink,
    SmileFace,
    SadFace,
    DefaultIcon,
    Tag,
    PDF,
    Twitter,
    Facebook,
    Instagram,
    LinkedIn,
    MapPin,
    File,
    Download,
    Warning,
    Language,
}
