import * as React from "react"

function SvgComponent(props: any) {
  return (
    <svg width={props.width || 24} height={props.height || 24} viewBox={`0 0 ${props.width || 32} ${props.height || 32}`} {...props}>
      <defs>
        <clipPath id="prefix__a">
          <path d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#prefix__a)">
        <path fill="none" d="M0 0h32v32H0z" />
        <path
          d="M16 4a12 12 0 1012 12A12 12 0 0016 4zm10.378 16.2l-3.412.589a17.969 17.969 0 00.628-4.393h3.6a11.117 11.117 0 01-.816 3.804zM4.81 16.4h3.6a17.969 17.969 0 00.628 4.393L5.622 20.2a11.117 11.117 0 01-.812-3.8zm.812-4.6l3.412-.589a17.969 17.969 0 00-.628 4.389H4.81a11.117 11.117 0 01.812-3.8zM16.4 10.422a27.645 27.645 0 014.288.392l1.412.244a17.17 17.17 0 01.694 4.542H16.4zm4.424-.4a28.4 28.4 0 00-4.424-.4v-4.8c2.293.221 4.285 2.32 5.414 5.376zm-5.224-.4a28.4 28.4 0 00-4.424.4l-.99.171C11.315 7.14 13.307 5.04 15.6 4.821zm-4.288 1.192a27.645 27.645 0 014.288-.392V15.6H9.206a17.17 17.17 0 01.694-4.542zM9.206 16.4H15.6v5.178a27.645 27.645 0 01-4.288-.392L9.9 20.942a17.17 17.17 0 01-.694-4.542zm1.97 5.574a28.4 28.4 0 004.424.406v4.8c-2.293-.221-4.285-2.32-5.414-5.376zm5.224.406a28.406 28.406 0 004.424-.406l.99-.171c-1.129 3.057-3.121 5.157-5.414 5.376zm4.288-1.194a27.645 27.645 0 01-4.288.392V16.4h6.394a17.17 17.17 0 01-.694 4.542zm2.906-5.586a17.969 17.969 0 00-.628-4.393l3.412.589a11.117 11.117 0 01.812 3.8zm2.38-4.686L22.7 10.35a11.029 11.029 0 00-3.18-4.98 11.254 11.254 0 016.454 5.544zM12.48 5.37a11.029 11.029 0 00-3.18 4.98l-3.272.564A11.254 11.254 0 0112.48 5.37zM6.026 21.086l3.274.564a11.029 11.029 0 003.182 4.98 11.254 11.254 0 01-6.456-5.544zM19.52 26.63a11.029 11.029 0 003.18-4.98l3.272-.564a11.254 11.254 0 01-6.452 5.544z"
          fill={props.color || "#0d507a"}
          stroke={props.color || "#0d507a"}
        />
      </g>
    </svg>
  )
}

export default SvgComponent
